<template>
  <div data-cy="bnpl-terms-cards" :class="$style['rate-cards']">
    <h4>
      {{ $t('paymentsPage.selectOption') }}
    </h4>
    <div
      v-for="({ index, item }, arrayIndex) of getSortedChoices"
      :key="arrayIndex"
      :class="$style['card']"
    >
      <span
        v-if="isRecommended(index) && this.isOptionEnabled(index)"
        :class="$style['popular']"
        :style="[
          isRecommendedChoice
            ? 'background-color: #23AA71;'
            : 'background-color: #000;',
        ]"
      >
        {{ $t('paymentsPage.mostPopular') }}
      </span>
      <label
        :for="index"
        :class="$style['label-table']"
        :style="getLabelStyle(index)"
        :disabled="!this.isOptionEnabled(index)"
        @click="getStepValue(index)"
      >
        <div
          :class="
            this.isOptionEnabled(index)
              ? $style['emptyRow']
              : $style['labelRow']
          "
        >
          <span
            v-if="!this.isOptionEnabled(index)"
            :class="$style['disabledText']"
            colspan="3"
          >
            To select,
            <a @click="updateAmount(index)">
              reduce invoice amount to
              {{
                `${this.$filters.currency(
                  this.getMaxCapacityForTerm(index),
                )} ${defaultCurrency}`
              }}
            </a>
          </span>
        </div>
        <div :class="$style['labelRow']">
          <span :class="$style['radio']">
            <input
              :key="`${index}_${termChosen}`"
              v-model="termChosen"
              :disabled="!this.isOptionEnabled(index)"
              :checked="this.isChecked(index)"
              :value="index"
              type="radio"
              :class="this.isChecked(index) ? 'hideRadio' : 'showRadio'"
            />
            <svg
              :class="this.isChecked(index) ? 'showCheck' : 'hideCheck'"
              width="20"
              height="20"
              v-html="require(`@/assets/icons/radio-checked.svg`)"
            ></svg>
          </span>
          <span :class="$style['term']">
            {{ index }} {{ $t('paymentsPage.monthExtension') }}
          </span>
          <span :class="$style['weekly']">
            {{ moneyDisplayValue(item.paymentPerTermCents)
            }}{{ $t('paymentsPage.perWeek') }}*
          </span>
        </div>
        <div :class="$style['emptyRow']"></div>
        <div :class="$style['labelRow']">
          <span :class="$style['fee']">
            {{ $t('paymentsPage.fee') }} ({{
              !hasActiveSalesAccounts ? `as low as ` : ''
            }}{{ displayFee(item.feeRate) }})
            <span :class="$style['black']">{{
              moneyDisplayValue(item.feeAmountCents)
            }}</span>
          </span>
          <span :class="$style['total']">
            {{ $t('paymentsPage.total') }}
            <span :class="$style['black']">{{
              moneyDisplayValue(item.totalAmountCents)
            }}</span>
          </span>
        </div>
      </label>
    </div>
    <div>
      <DSButton
        v-if="this.areMoreOptionsAvailable()"
        class="p-button-text"
        :class="$style['more-options']"
        @click="viewMorePaymentOptions"
      >
        {{ $t('paymentsPage.viewMoreOptions') }}
      </DSButton>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatMoney } from '@/utils/currency';
import { getPercent } from '@/utils/numbers';
import { DEFAULT_CURRENCY } from '@/data/payments';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: { DSButton },
  props: {
    choices: {
      type: Object,
    },
    capacityResponse: {
      type: Object,
    },
    position: {
      type: String,
    },
    billAmount: {
      type: [String, Number],
    },
  },
  data() {
    return {
      viewMore: false,
      defaultTerm: '4',
      termChosen: this.position,
      amountUpdated: false,
    };
  },
  computed: {
    ...mapGetters(['hasActiveSalesAccounts']),
    availableTerms() {
      const availableTerms = [];
      if (this.capacityResponse?.capacity) {
        Object.values(this.capacityResponse.capacity).forEach((termData) => {
          availableTerms.push(termData.monthLabel.match(/\d/g).join(''));
        });
      }
      return availableTerms;
    },
    isRecommendedChoice() {
      return this.termChosen === this.defaultTerm;
    },
    getEnabledOptions() {
      if (
        this.capacityResponse?.capacity &&
        this.capacityResponse?.remainingCapacityCents > 1000
      ) {
        const { capacity } = this.capacityResponse;
        const popularIndices = [];
        for (const item in capacity) {
          if (Number(capacity[item]?.amountCents) >= Number(this.billAmount)) {
            const termLengthInMonths = capacity[item].monthLabel
              .match(/\d/g)
              .join('');
            popularIndices.push(termLengthInMonths);
          }
        }
        return popularIndices;
      }
      // Default show all options if user does not have capacity
      return ['1', '2', '3', '4', '5', '6'];
    },
    getSortedChoices() {
      const enabledOptions = this.getEnabledOptions;
      const sortedChoices = [];
      if (!this.amountUpdated) {
        this.updateChosenTerm(this.termChosen);
      }
      for (const index of enabledOptions) {
        if (this.choices[index]) {
          sortedChoices.push({
            index,
            item: { ...this.choices[index], months: index },
          });
        }
      }
      for (const index of Object.keys(this.choices)) {
        if (
          !enabledOptions.includes(index) &&
          this.availableTerms.includes(index)
        ) {
          sortedChoices.push({
            index,
            item: { ...this.choices[index], months: index },
          });
        }
      }
      return sortedChoices;
    },
    defaultCurrency() {
      return DEFAULT_CURRENCY;
    },
  },
  watch: {
    getEnabledOptions(newVal) {
      if (!newVal || newVal.length === 0) {
        this.viewMore = true;
      }
      this.isNextDisabled();
    },
    termChosen() {
      this.isNextDisabled();
    },
  },
  methods: {
    getStepValue(index) {
      this.termChosen = index;
      this.$emit('getStepValue', parseInt(this.termChosen));
    },
    updateAmount(index) {
      this.amountUpdated = true;
      this.termChosen = index;
      this.viewMore = false;
      const val = this.getMaxCapacityForTerm(index);
      const convertedCentsToFloat = val / 100;
      this.$emit('updateAmount', { value: convertedCentsToFloat });
      this.$emit('getStepValue', parseInt(index));
    },
    updateChosenTerm(index) {
      const enabledOptions = this.getEnabledOptions;
      if (enabledOptions.includes(index)) {
        this.termChosen = index;
        this.$emit('getStepValue', parseInt(index));
      } else {
        this.termChosen = null;
        this.$emit('getStepValue', null);
      }
    },
    moneyDisplayValue(amount) {
      return `${formatMoney(amount, DEFAULT_CURRENCY)} ${DEFAULT_CURRENCY}`;
    },
    displayFee(fee) {
      return `${getPercent(fee)}`;
    },
    areMoreOptionsAvailable() {
      return (
        this.getEnabledOptions.length < this.availableTerms.length &&
        !this.viewMore
      );
    },
    viewMorePaymentOptions() {
      this.viewMore = true;
    },
    isHidden(index) {
      return !(this.viewMore || this.getEnabledOptions.includes(index));
    },
    isRecommended(index) {
      return index === this.defaultTerm;
    },
    isOptionEnabled(index) {
      return this.getEnabledOptions.includes(index);
    },
    getLabelStyle(index) {
      const labelStyle = [];
      labelStyle.push(
        `display : ${this.isHidden(index) ? 'none' : 'inline-block'}`,
      );
      labelStyle.push(`
        height: 120px;
        margin-top: 15px;
      `);
      if (index === this.termChosen && this.isOptionEnabled(index))
        labelStyle.push('border: 1px solid #23AA71;');
      else if (!this.isOptionEnabled(index)) {
        labelStyle.push(`
          background: #F8F8FA;
          border: 1px solid #CECED0;
          border-radius: 7.4px;
          cursor: default;
          `);
      }
      return labelStyle;
    },
    isChecked(index) {
      return index === this.termChosen && this.isOptionEnabled(index);
    },
    getMaxCapacityForTerm(index) {
      const capacity = this.capacityResponse?.capacity;
      for (const termData of Object.values(capacity)) {
        if (termData.monthLabel.match(/\d/g).join('') === index) {
          return termData.amountCents;
        }
      }
      return 0;
    },
    isNextDisabled() {
      if (
        !this.getEnabledOptions ||
        this.getEnabledOptions.length === 0 ||
        !this.termChosen
      ) {
        this.$emit('disableNext', true);
      } else {
        this.$emit('disableNext', false);
      }
    },
  },
};
</script>

<style lang="less" module>
.rate-cards {
  text-align: center;
  padding: 1rem 0 0 0;

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    margin-bottom: 7px;
    &.error {
      color: red;
    }
  }
  .card {
    max-height: 145px;
  }

  label {
    display: flex;
    box-sizing: border-box;
    min-width: 370px;
    max-width: 410px;
    height: 90px;
    max-height: 100px;
    background: #fff;
    text-align: center;
    box-shadow: 0 3px 10px -2px hsla(150, 5%, 65%, 0.5);
    position: relative;
    border: 1px solid #f2f2f2;
    border-radius: 7.4px;
    cursor: pointer;
    &.hideOption {
      display: none !important;
    }
  }
  input[type='radio']:checked {
    opacity: 0;
    height: 0;
    width: 0;
    + svg {
      display: inline;
      left: -10px;
    }
  }
  input[type='radio'] {
    opacity: 1;
    height: 20px;
    width: 20px;
    + svg {
      display: none;
    }
  }

  .label-table {
    min-width: 370px;
    max-width: 410px;
    min-height: 90px;
    max-height: 110px;
    padding-left: 3px;
    padding-right: 3px;
    div {
      font-style: normal;
      color: #000;
      span {
        margin: 5px 5px 2px 5px;
        line-height: 24px;
      }
      span.disabledText {
        font-weight: 300;
        font-size: 16px;
        text-align: left;
        a {
          cursor: pointer;
        }
      }
      span.radio {
        padding-left: 1px;
        padding-right: 2px;
        width: 10px;
        position: relative;
        top: 3px;
        align-content: left;
        margin-right: 0;
        margin-left: 2px;
      }
      span.term {
        padding-left: 3px;
        padding-right: 10px;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
      }
      span.weekly {
        font-weight: 500;
        font-size: 16px;
        text-align: right;
        height: 24px;
        width: 175px;
      }
      span.fee {
        font-weight: 300;
        font-size: 14px;
        color: #5f5f61;
        text-align: left;
        padding-left: 5px;
        padding-right: 12px;
        .black {
          color: #000;
        }
      }
      span.total {
        font-weight: 300;
        font-size: 14px;
        text-align: right;
        color: #5f5f61;
        span.black {
          color: #000;
        }
      }
    }
    .labelRow {
      min-width: 370px;
      max-width: 410px;
      height: 24px;
      padding: 5px 1px;
    }
    .emptyRow {
      min-width: 370px;
      max-width: 410px;
      height: 12px;
      padding: 1px 1px;
    }
  }
  p {
    font-weight: 900;
  }
  span {
    &.popular {
      display: flex;
      width: 105px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      position: relative;
      margin-top: -13px;
      top: 24px;
      left: 120px;
      z-index: 2;
      height: 24px;
      border-radius: 4px;
      padding: 1px 3px 1px 3px;
      text-align: center;
      @media only screen and (max-width: 500px) {
        left: 40px;
      }
    }
    &.chosen {
      background: #23aa71;
    }
  }

  .more-options {
    margin-top: 1rem;
  }
}
</style>
