// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_TFBNr img,.logo_TFBNr span,.logo_TFBNr svg{flex-shrink:0}.logo_TFBNr img,.logo_TFBNr span,.logo_TFBNr svg{height:40px;width:40px}.logo_TFBNr span{background:#e8e8ea;border-radius:8px;display:flex;align-items:center;justify-content:center;font-size:14px;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_TFBNr"
};
export default ___CSS_LOADER_EXPORT___;
