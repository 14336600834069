import { buildApiActions } from '@/utils/vuex-api-utils';
import { profilingApi } from '../../utils/diligence-service';

export default {
  ...buildApiActions({
    FINISH_PROFILING: {
      action: (ctx, payload) => ({
        requestFunc: async () => {
          return profilingApi.completeSession({
            sessionId: payload.sessionId,
            type: payload.type,
            subtype: payload.subtype,
          });
        },
      }),
    },
    FINISH_PROFILING_EXTERNAL: {
      action: (ctx, payload) => ({
        requestFunc: async () => {
          return profilingApi.completeUserInviteSession(payload.inviteId, {
            sessionId: payload.sessionId,
            type: payload.type,
            subtype: payload.subtype,
          });
        },
      }),
    },
  }),
};
