<template>
  <Modal
    v-model:visible="showModal"
    header="&nbsp;"
    :modal="true"
    :draggable="false"
    append-to="self"
    :class="$style.modal"
  >
    <div v-if="!isSubmitted">
      <h2>
        {{
          $t(
            'components.modalExternalAccountOnboarding.didNotFindPlatformOnList',
          )
        }}
      </h2>
      <p>
        {{
          $t('components.modalExternalAccountOnboarding.submitYourRequestSoon')
        }}
      </p>
      <VeeForm
        class="form"
        :class="$style.form"
        :validation-schema="schema"
        data-cy="form"
        @submit="onSubmit"
      >
        <div class="c-form__row">
          <InputTextGroup
            name="platformName"
            :label="
              $t('components.modalExternalAccountOnboarding.platformName')
            "
            @blur="$emit('platformEntered', $event.target.value)"
          />
        </div>
        <div class="c-form__controls">
          <DSButton
            :label="$t('common.buttons.submit')"
            class="m-auto"
            type="submit"
          />
        </div>
      </VeeForm>
    </div>
    <Message v-else severity="success" :closable="false">
      <p>
        {{
          $t(
            'components.modalExternalAccountOnboarding.yourRequestHasBeenSubmitted',
          )
        }}
      </p>
    </Message>
  </Modal>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import Modal from '@clearbanc/clear-components/dialog';
import Message from '@clearbanc/clear-components/message';

export default {
  components: {
    Modal,
    VeeForm,
    InputTextGroup,
    DSButton,
    Message,
  },
  data() {
    return {
      isSubmitted: false,
      showModal: false,
      schema: object({
        platformName: string()
          .required(
            this.$t(
              'components.modalExternalAccountOnboarding.pleaseEnterValidPlatformName',
            ),
          )
          .matches(
            /[a-z]/i,
            this.$t(
              'components.modalExternalAccountOnboarding.pleaseEnterValidPlatformName',
            ),
          ),
      }),
    };
  },
  methods: {
    open() {
      this.showModal = true;
    },
    onSubmit(values) {
      this.$emit('platformRequested', values.platformName);
      this.isSubmitted = true;

      window.setTimeout(() => {
        this.isSubmitted = false;
        this.showModal = false;
      }, 2000);
    },
  },
};
</script>

<style lang="less" module>
.modal {
  div[class='p-dialog-header'] {
    justify-content: space-between;
    padding: 0.5rem;

    div[class='p-dialog-header-icons'] {
      position: static;
    }
  }
}

.form {
  text-align: left;
}
</style>
