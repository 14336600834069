// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user_FwRET{position:relative;border:1px solid #5b00f0;text-align:left;padding:16px;display:flex;align-items:center;color:#303032;font-size:14px;line-height:1.4}.user--disabled_vWyqF{border-color:#e8e8ea}.user__tag_F1bzz{position:absolute;top:-16px;left:4px;padding:6px 8px;background:#000;color:#fff;border-radius:4px;font-size:12px;font-weight:500;line-height:1}.user__details_ccw9j{display:inline-block}.user__details_ccw9j .user__name_wlcjN{font-weight:700}.user__details_ccw9j .user__email_z94AU{margin-right:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": "user_FwRET",
	"user--disabled": "user--disabled_vWyqF",
	"user__tag": "user__tag_F1bzz",
	"user__details": "user__details_ccw9j",
	"user__name": "user__name_wlcjN",
	"user__email": "user__email_z94AU"
};
export default ___CSS_LOADER_EXPORT___;
