import store from '@/store';
import { NOT_FOUND, HOME } from '@/data/routes';
import NotFoundPage from '@/pages/page-not-found';

export default [
  {
    path: '/not-found',
    name: NOT_FOUND.INDEX,
    alias: '/:pathMatch(.*)*',
    component: NotFoundPage,
    meta: {
      displayNav: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.userIsLoggedIn) {
        return next();
      }
      return next({ name: HOME.INDEX });
    },
  },
];
