import { authGuard } from '@auth0/auth0-vue';
import { PROFILE } from '@/data/routes';

import VendorsPage from '@/pages/account/vendors/page-vendors';
import PaymentsBizProfileDashboard from '@/pages/payments/business-profile';

import businessRoutes from '@/routes/business.routes';
import ownersRoutes from '@/routes/owners.routes';
import userRoutes from '@/routes/user.routes';
import bankingRoutes from '@/routes/banking.routes';
import financialRoutes from '@/routes/financial.routes';

export default [
  {
    path: '/profile',
    component: VendorsPage,
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      displayNav: true,
    },
    children: [
      {
        path: '',
        name: PROFILE.INDEX,
        alias: '/pay-vendors/business-profile',
        component: PaymentsBizProfileDashboard,
        children: [
          ...businessRoutes,
          ...userRoutes,
          ...ownersRoutes,
          ...bankingRoutes,
          ...financialRoutes,
        ],
      },
    ],
  },
];
