import { authGuard } from '@auth0/auth0-vue';
import { ACCOUNT } from '@/data/routes';

import dataSecurityRoutes from '@/routes/data-security.routes';

import AccountPage from '@/pages/account/page-my-account';
import SettingsSubpage from '@/pages/account/subpage-settings';

export default [
  {
    path: '/account',
    name: ACCOUNT.INDEX,
    component: AccountPage,
    beforeEnter: authGuard,
    redirect: { name: ACCOUNT.SETTINGS },
    meta: {
      requiresAuth: true,
      displayNav: true,
      segment: [
        'core',
        'self_serve',
        'extend_runway',
        'mrr_factoring',
        'indiegogo',
        'bnpl',
      ],
    },
    children: [
      {
        path: 'settings',
        name: ACCOUNT.SETTINGS,
        component: SettingsSubpage,
      },
      ...dataSecurityRoutes,
    ],
  },
];
