// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_UFuTD{max-width:500px;margin:0}.users_XOFPP{margin-top:30px}.users__item_b1gkf+.users__item_b1gkf{margin-top:24px}.users__item--disabled_eGb_n{opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "description_UFuTD",
	"users": "users_XOFPP",
	"users__item": "users__item_b1gkf",
	"users__item--disabled": "users__item--disabled_eGb_n"
};
export default ___CSS_LOADER_EXPORT___;
