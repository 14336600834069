<template>
  <LayoutFormPage :show-error-message="showError">
    <FormRegisteredAddress
      ref="form"
      :show-mailing-address-fields="false"
      @validationSuccess="handleValidationSuccess"
      @validationFailure="showError = true"
      @goBack="goBack"
    />
  </LayoutFormPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { LayoutFormPage, FormRegisteredAddress } from '@/components';
import analytics from '@/utils/analytics';
import { CLEARPAY_SEGMENT_EVENTS, PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { useProfiling } from '@/composables/useProfiling';
import { EVENT_TYPES } from '@/data/lexisnexis-types';

export default {
  components: {
    FormRegisteredAddress,
    LayoutFormPage,
  },
  data() {
    return {
      showError: false,
    };
  },
  setup() {
    const profiling = useProfiling(PAYMENTS_ROUTE_NAMES.ADDRESS, {
      type: EVENT_TYPES.ACCOUNT_CREATION,
    });

    return {
      profiling,
    };
  },
  computed: {
    ...mapGetters(['paymentsOnboardingSteps']),
    currentRoute() {
      return this.$route.name;
    },
    previousRoute() {
      return this.paymentsOnboardingSteps[this.currentRoute].previousRoute.name;
    },
  },
  methods: {
    async nextStep() {
      this.$refs.form.validateForm();
    },
    async updateOnboardingStep() {
      this.$store.dispatch(
        'UPDATE_PAYMENTS_ONBOARDING_STEP',
        this.currentRoute,
      );
      analytics.track(CLEARPAY_SEGMENT_EVENTS.SUBMITTED_REGISTERED_ADDRESS);
      localStorage.setItem('new-user', 'true');
      this.$router.push({
        name: this.paymentsOnboardingSteps[this.currentRoute].nextRoute.name,
      });
    },
    async handleValidationSuccess() {
      this.profiling.finish();

      await this.updateOnboardingStep();
    },
    goBack() {
      this.$router.push({ name: this.previousRoute });
    },
  },
};
</script>
