import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { USER_INVITE } from '@/data/routes';

export const EVENT_TYPES = {
  LOGIN: 'login',
  ACCOUNT_CREATION: 'account_creation',
};

export const PAGE_IDS = {
  [PAYMENTS_ROUTE_NAMES.USER_PROFILE]: '10',
  [PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS]: '20',
  [PAYMENTS_ROUTE_NAMES.ADDRESS]: '30',
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS]: '40',
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS]: '50',
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE]: '60',
  [PAYMENTS_ROUTE_NAMES.PROFILE_NEW_OWNER]: '70',
  [USER_INVITE.VERIFY]: '80',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING_PLAID]: '90',
  [PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT]: '100',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_MARKETING]: '110',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING_BYPASS]: '120',
};

export const SUB_TYPES = {
  [PAYMENTS_ROUTE_NAMES.USER_PROFILE]: 'onboarding_profile_details',
  [PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS]: 'onboarding_business_details',
  [PAYMENTS_ROUTE_NAMES.ADDRESS]: 'onboarding_registered_address',
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS]: 'business_details',
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS]:
    'business_address',
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE]: 'user_details',
  [PAYMENTS_ROUTE_NAMES.PROFILE_NEW_OWNER]: 'user_add',
  [USER_INVITE.VERIFY]: 'user_invite',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING_PLAID]: 'bank_plaid',
  [PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT]: 'revenue_accounts',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_MARKETING]: 'marketing_accounts',
  [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING_BYPASS]: 'bank_manual',
};
