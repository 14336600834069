<template>
  <div v-if="files.length">
    <div v-for="file in files" :key="file.id" :class="fileClasses">
      <svg
        v-if="isClosable"
        :class="$style['file-button']"
        tabindex="0"
        @click="$emit('fileRemove', file.id)"
        @keyup.enter="$emit('fileRemove', file.id)"
        v-html="require('@/assets/icons/close-icon.svg?raw')"
      ></svg>
      <p
        :class="textClasses"
        :data-cy="kebabCase(computedName(file) + ' file')"
        tabindex="0"
        @click="handleDownloadFile(file.id)"
        @keyup.enter="handleDownloadFile(file.id)"
      >
        {{ computedName(file) }}
      </p>
    </div>
  </div>
</template>

<script>
import kebabCase from '@/utils/kebabcase';
import { downloadFileMixin } from '@/utils/vue-mixins';

export default {
  mixins: [downloadFileMixin],
  props: {
    files: { type: Array },
    isClosable: { type: Boolean, default: true },
    shortenFilenames: { type: Boolean, default: false },
  },
  computed: {
    fileClasses() {
      return {
        [this.$style.file]: this.isClosable,
      };
    },
    textClasses() {
      return {
        [this.$style['file-text']]: true,
        [this.$style['closable-list']]: this.isClosable,
        [this.$style['list-only']]: !this.isClosable,
        [this.$style['blue-link']]: true,
      };
    },
  },
  methods: {
    computedName(file) {
      if (this.shortenFilenames) {
        const onlyFileName = file.name.replace(`.${file.extension}`, '');
        if (onlyFileName.length > 10) {
          return onlyFileName.slice(0, 10).concat(`...${file.extension}`);
        }
      }
      return file.name;
    },
    kebabCase,
    async handleDownloadFile(id) {
      this.$emit('trackUploadedDocClicked');
      await this.downloadFile(id);
    },
  },
};
</script>

<style lang="less" module>
.file {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 auto;
}

.file-text {
  cursor: pointer;
  text-decoration: underline;
  word-break: break-all;

  &.blue-link {
    color: @link-blue;
  }
  &.closable-list {
    text-align: left;
  }
  &.list-only {
    position: relative;
    z-index: 1;
    margin: 0 0 10px;
    text-align: right;
    max-width: 290px;
    @media screen and (max-width: 500px) {
      text-align: left;
    }
  }
}

.file-button {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 20px;
  flex-shrink: 0;
}
</style>
