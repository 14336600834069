<template>
  <div>
    <div v-if="billCanBeCancelled" :class="$style['modal-footer-processing']">
      <DSButton
        :label="$t('account.pages.pageVendors.billDetails.cancelPayment')"
        class="p-button-link"
        @click="showCancelBillConfirmation()"
      />
      <p v-if="showRemainingDaysMessage">
        {{
          $t('account.pages.pageVendors.billDetails.daysRemainingCancel', {
            days: showRemainingDays,
          })
        }}
      </p>
    </div>
    <div v-else :class="$style['modal-footer-processing']">
      <p :class="$style['row-content']">
        {{
          $t('account.pages.pageVendors.billDetails.IfHaveQuestions', {
            type: uploadType.toLowerCase(),
          })
        }}
        <a
          :href="`mailto:support@clear.co?subject=%5BClearPay%5D%20Bill%20%23%7B%7B${selectedBill.id}%7D%7D`"
          >support@clear.co</a
        >
        {{ $t('account.pages.pageVendors.billDetails.asap') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as dateFns from 'date-fns';
import {
  BillStatus,
  CANCEL_WINDOW_DAYS,
  CANCELLATION_NOT_WITHIN_WINDOW,
} from '@/data/payments';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    DSButton,
  },
  props: {
    bill: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['selectedBill']),
    showRemainingDays() {
      return (
        Number(
          dateFns.differenceInDays(new Date(this.bill.dueDate), new Date()),
        ) - CANCEL_WINDOW_DAYS
      );
    },
    showRemainingDaysMessage() {
      return this.showRemainingDays > 0;
    },
    billCanBeCancelled() {
      return ![BillStatus.CANCELLED, BillStatus.COMPLETED].includes(
        this.bill?.status,
      );
    },
    uploadType() {
      return this.bill.isReceipt
        ? this.$t('account.pages.pageVendors.billDetails.receipt')
        : this.$t('account.pages.pageVendors.billDetails.invoice');
    },
  },
  methods: {
    async showCancelBillConfirmation() {
      const cancelWindow = dateFns.differenceInDays(
        new Date(this.bill.dueDate),
        new Date(),
      );
      if (cancelWindow <= CANCEL_WINDOW_DAYS && !this.bill.isReceipt) {
        const payload = {
          cancellationReason: CANCELLATION_NOT_WITHIN_WINDOW,
          billId: this.bill.id,
        };
        await this.$store.dispatchApiAction('CANCEL_INVOICE', payload);
      }
      this.$emit('next-tab');
    },
  },
};
</script>
<style lang="less" module>
.modal-footer-processing {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;

  p {
    font-size: 12px;
    margin: 0;
  }
}

.row-content {
  margin: 0;
  color: @dark-black;
  overflow-wrap: break-word;
  font-weight: 400;
  a {
    font-size: 14px;
  }
}
</style>
