<script setup>
import { ref, defineExpose } from 'vue';
import { useStore } from 'vuex';

import Modal from '@clearbanc/clear-components/dialog';
import ModalControls from '@/components/modals/shared/ModalControls';

// -----
// Setup
// -----

const store = useStore();

// ---------
// Variables
// ---------

const isModalVisible = ref(false);
const ownerIdToDelete = ref(null);
const isLoading = ref(false);

// ------------
// Misc Methods
// ------------

function toggleModal(ownerId) {
  const openingModal = !isModalVisible.value;
  const closingModal = isModalVisible.value;

  if (openingModal) {
    ownerIdToDelete.value = ownerId;
  }

  if (closingModal) {
    ownerIdToDelete.value = null;
  }

  isModalVisible.value = !isModalVisible.value;
}

async function handleDeleteOwner() {
  const ownerToDelete = store.getters.businessOwners.find(
    (owner) => owner.id === ownerIdToDelete.value,
  );

  isLoading.value = true;
  await store.dispatchApiAction('DELETE_NON_PRIMARY_USER', ownerToDelete);
  isLoading.value = false;

  toggleModal();
}

// -----------------
// Component Options
// -----------------

defineExpose({ toggleModal });
</script>

<template>
  <Modal
    v-model:visible="isModalVisible"
    header="Delete User"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    Are you sure you want to delete this owner? This action cannot be undone.

    <template #footer>
      <ModalControls
        :buttons="[
          {
            text: 'Cancel',
            action: toggleModal,
            classes: ['p-button-outlined'],
            attributes: {},
          },
          {
            text: 'Delete',
            action: handleDeleteOwner,
            classes: [],
            attributes: { loading: isLoading },
          },
        ]"
      />
    </template>
  </Modal>
</template>
