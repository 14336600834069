<template>
  <LayoutOnboarding :is-loading="isLoading">
    <OnboardingUiContainer
      :class="classes"
      :display-clearco-logo="true"
      :percent-complete="percentComplete"
    >
      <template #title>
        <div :class="$style['text']">{{ title }}</div>
      </template>
      <template v-if="subtitle" #subtitle>
        <div :class="$style['text']">{{ subtitle }}</div>
      </template>
      <RouterView />
    </OnboardingUiContainer>
  </LayoutOnboarding>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingUiContainer from '@/components/UiContainer';
import LayoutOnboarding from '@/components/layouts/LayoutOnboarding';
import sendUtmParamsToSegment from '@/composables/utm';
import { auth0 } from '@/utils/auth0';
import { fetchUserData } from '../../store/actions';

export default {
  components: {
    OnboardingUiContainer,
    LayoutOnboarding,
  },
  data() {
    return {
      isLoading: true,
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'paymentsOnboardingSteps',
      'userIsLoggedIn',
      'firstIncompletePaymentsOnboardingStep',
      'isEmailVerified',
      'isRoutesV2Enabled',
    ]),
    classes() {
      return {
        [this.$style['onboarding-wrapper']]: !this.authPage,
      };
    },
    currentRoute() {
      return this.$route.name;
    },
    authPage() {
      return this.$route.meta?.requiresAuth;
    },
    percentComplete() {
      return this.currentStep?.percentComplete;
    },
    currentStep() {
      return this.paymentsOnboardingSteps[this.currentRoute];
    },
    title() {
      return this.currentStep.title;
    },
    subtitle() {
      return this.verificationMessage ?? this.currentStep?.subtitle;
    },
    verificationMessage() {
      return this.isEmailVerified
        ? 'Your email has been verified. Please complete your business profile.'
        : undefined;
    },
  },
  setup() {
    return sendUtmParamsToSegment();
  },
  async beforeMount() {
    // TODO(sc-186486): clean up isRoutesV2Enabled. Redirect is done by router files
    if (this.userIsLoggedIn && !this.isRoutesV2Enabled) {
      await auth0.getAccessTokenSilently();
      const userId = auth0?.user?.value?.userId;

      await fetchUserData(this.$store, userId);
      if (
        this.firstIncompletePaymentsOnboardingStep &&
        this.currentRoute !== this.firstIncompletePaymentsOnboardingStep.name
      ) {
        this.$router
          .push({ name: this.firstIncompletePaymentsOnboardingStep.name })
          .catch(() => {});
      }
    }
    this.isLoading = false;
  },
};
</script>

<style lang="less" module>
.onboarding-wrapper {
  [class^='content-container'] {
    margin-top: unset;
  }
  @media only screen and (min-width: 961px) {
    max-width: 460px !important;
  }
}

.logo {
  width: 242px;
  margin: auto auto 20px;
  @media screen and (max-width: 500px) {
    margin-top: 30px;
  }
}

.text {
  text-align: center;
}

.unverified-warning {
  margin-top: 10px;
  margin-bottom: 0;
}
</style>
