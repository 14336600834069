<template>
  <button
    :class="[
      $style.tile,
      selected && $style['tile--selected'],
      highlight && $style['tile--highlight'],
    ]"
  >
    <span :class="$style.tile__logo">
      <BankAccountLogo :image="bankLogo" :bank-name="bankName" />
    </span>
    <span :class="$style.tile__name">{{ bankName }}</span>
  </button>
</template>

<script>
import BankAccountLogo from '@/components/BankAccountLogo';

export default {
  components: {
    BankAccountLogo,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    bankLogo: {
      type: String,
    },
    bankName: {
      type: String,
      required: true,
    },
    highlight: {
      type: Boolean,
    },
  },
};
</script>

<style lang="less" module>
.tile--highlight .tile__logo {
  border: 1px solid #dadada;
  background: #f8f8fa;
}

.tile--highlight svg {
  fill: #ffffff;
  path {
    stroke: #7f31ff;
    stroke-width: 1;
  }
}

.tile--highlight .tile__name {
  display: inline-block;
  border-bottom: 1px dotted #dadada;
}

.tile--selected .tile__logo {
  border: 1px solid #5b00f0;
}

.tile__logo {
  border: 1px solid #e8e8ea;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #fff;
}

.tile__logo img,
.tile__logo svg,
.tile__logo span {
  transition: transform 0.25s;
}

.tile__logo img {
  height: 50px;
  width: auto;
}

.tile__logo span {
  height: 40px;
  width: 40px;
  background: #e8e8ea;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.tile:hover .tile__logo img,
.tile:hover .tile__logo svg,
.tile:hover .tile__logo span {
  transform: scale(0.8);
}

.tile__name {
  display: block;
  color: #000;
  text-align: center;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 8px;
}
</style>
