import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import {
  getPostalCodeRegexAccordingToCountry,
  stateOptionsForCountry,
} from '@/utils/local';
import { isCountryWithPostalCode } from '@/data/country-code-with-names';
import { isRepetitive, isSequential } from '@/utils/numbers';

export const validateSSN = (schema) => {
  return schema
    .test(
      'endsIn0000',
      `Please enter a valid SSN. '0000' on the last 4 digits are not allowed.`,
      (value) => !value?.endsWith('0000'),
    )
    .test(
      'invalidStart',
      `Please enter a valid SSN. The first three digits are invalid.`,
      (value) => {
        const prefix = Number(value?.slice(0, 3));
        return (
          prefix !== 0 && prefix !== 666 && !(prefix >= 900 && prefix <= 999)
        );
      },
    )
    .test(
      'noRepetitiveOrSequential',
      `Please enter a valid SSN. Sequential or repetitive digits are not allowed.`,
      (value) => {
        const repetitivePattern = /^(\d)\1{8}$/;
        const sequentialPattern = '0123456789';
        return (
          !repetitivePattern.test(value) && !sequentialPattern.includes(value)
        );
      },
    )
    .test(
      'length',
      `Please enter a valid 9-digit SSN.`,
      (value) => value?.length === 9,
    )
    .required('Required');
};

export const validatePhone = (schema, countryCallingCode) => {
  // https://www.npmjs.com/package/libphonenumber-js
  return schema
    .test('is-valid-phone-number', 'Phone Number not valid', (value) => {
      return (
        isPossiblePhoneNumber(value || '', countryCallingCode?.value) &&
        isValidPhoneNumber(value || '', countryCallingCode?.value)
      );
    })
    .required('Required');
};

export const validatePostalCode = (schema, country) => {
  const postalRegex = getPostalCodeRegexAccordingToCountry(country?.value);

  if (country && postalRegex) {
    return schema
      .matches(postalRegex, 'Invalid postal/zip code')
      .required('Required');
  }

  if (isCountryWithPostalCode(country?.value)) {
    return schema.required('Required');
  }

  return schema;
};

export const validateState = (schema, country) => {
  if (country) {
    const countryHasStates = stateOptionsForCountry(country.value);
    if (countryHasStates) {
      return schema.required('Required');
    }
  }
  return schema;
};

export const validateEin = (schema) => {
  return schema
    .test(
      'no-repetitive-sequential-digits',
      'Please enter a valid EIN. Repetitive digits or all zeros are not allowed',
      (value) => !isRepetitive(value) && !isSequential(value),
    )
    .length(9, 'EIN number must be 9 digits')
    .required('Required');
};
