import { authGuard } from '@auth0/auth0-vue';
import { FINANCING } from '@/data/routes';
import FinancingHomePage from '@/pages/mca/financing';
import SpendPage from '@/pages/mca/financing/spend';
import AdvancesPage from '@/pages/mca/financing/advances';

export default [
  {
    path: 'financing', // MCA Route
    beforeEnter: authGuard,
    meta: {
      displayNav: true,
      isLegacy: true,
    },
    children: [
      {
        path: '', // MCA Route
        alias: '/financing',
        name: FINANCING.INDEX,
        component: FinancingHomePage,
      },
      {
        path: 'spend', // MCA Route
        alias: '/financing/spend',
        name: FINANCING.SPEND,
        component: SpendPage,
      },
      {
        path: 'advances', // MCA Route
        alias: '/financing/advances',
        name: FINANCING.ADVANCES,
        component: AdvancesPage,
      },
    ],
  },
];
