<template>
  <Modal
    v-model:visible="visible"
    header="Enter Routing Number to Verify Your Bank"
    :modal="true"
    :draggable="false"
    append-to="self"
    @hide="closeModal"
  >
    <p :class="$style.description">
      This verification will ensure we are connecting to the correct banking
      institution.
    </p>

    <div :class="$style.details">
      <VeeForm class="c-form" :validation-schema="schema">
        <div class="c-form__row">
          <InputTextGroup
            v-model="routingNumber"
            v-only-numbers
            name="routingNumber"
            label="Routing Number"
          />
          <a
            :class="$style['help-link']"
            href="https://help.clear.co/s/article/Finding-My-Bank-s-Routing-Number?language=en_US"
            target="_blank"
            >How to find routing number</a
          >
        </div>
        <div class="c-form__controls">
          <DSButton
            label="Cancel"
            class="p-button-outlined"
            @click="closeModal"
          />
          <DSButton
            label="Submit"
            type="submit"
            :disabled="!isRoutingNumberValid"
            @click="completedModal"
          />
        </div>
      </VeeForm>
    </div>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';
import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';

export default {
  emits: ['close', 'routingNumber'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Modal,
    InputTextGroup,
    DSButton,
    VeeForm,
  },
  data() {
    return {
      routingNumber: null,
      schema: object({
        routingNumber: string()
          .required(this.$t('common.required'))
          .length(9, 'Must be exactly 9 characters'),
      }),
    };
  },
  computed: {
    isRoutingNumberValid() {
      return this.schema.isValidSync({ routingNumber: this.routingNumber });
    },
  },
  methods: {
    closeModal() {
      this.routingNumber = null;
      this.$emit('close');
    },
    completedModal() {
      this.$emit('routingNumber', this.routingNumber);
      this.closeModal();
    },
  },
};
</script>

<style lang="less" module>
.description {
  margin: 8px 0 0 0;
}

.details {
  margin-top: 40px;
}

.help-link {
  text-decoration: underline;
  margin: 10px 0 0 0;
  display: inline-block;
}
</style>
