<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import ModalOwnerVerification from '@/components/modals/ModalOwnerVerification';
import OwnersList from '@/components/owners-list';

import enCopy from '@/lang/general/en-us.json';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();

// ---------
// Variables
// ---------

const modalOwnerVerificationRef = ref(null);

// ------------
// Misc Methods
// ------------

const errorMessage = computed(() => {
  if (!store.getters.hasSignatory) {
    return enCopy.components.tileGroupOwnersList.signatoryErrorMsg;
  }
  return undefined;
});

function goBack() {
  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
  });
}

function toggleOwnerVerificationModal() {
  modalOwnerVerificationRef.value?.toggleModal();
}
</script>

<template>
  <div>
    <Message
      v-if="errorMessage"
      severity="error"
      :closable="false"
      :class="$style['page-message']"
    >
      <p>{{ errorMessage }}</p>
    </Message>

    <Message
      v-if="store.getters.ownerHasPendingBillContractOrActiveAdvance"
      severity="info"
      :closable="false"
      :class="$style['page-message']"
    >
      <p>
        Signatory updates can't be made after funding or a contract is pending.
        Please contact our support team at +1 (415) 610-5166 (9AM-6PM ET) or
        <a href="mailto:support@clearco.com">support@clearco.com</a> for
        assistance.
      </p>
    </Message>

    <div :class="$style['owners-wrapper']">
      <OwnersList />
      <div :class="$style.actions">
        <DSButton
          label="Back"
          class="p-button-outlined"
          :class="$style.actions__button"
          @click="goBack"
        />
        <DSButton
          label="Next"
          :class="$style.actions__button"
          :disabled="!store.getters.hasSignatory"
          @click="toggleOwnerVerificationModal"
        />
      </div>
    </div>
  </div>

  <ModalOwnerVerification ref="modalOwnerVerificationRef" />
</template>

<style lang="less" module scoped>
.page-message {
  margin: 24px 0 40px;
}

.owners-wrapper {
  max-width: 556px;
  margin: 0 auto;
}

.actions {
  margin-top: 40px;
  display: flex;
  gap: 16px;
}

.actions__button {
  width: 100%;
}
</style>
