<template>
  <UiMsg
    v-if="showBanner"
    type="error"
    :is-small="!!accountTakenError"
    :class="$style['message-wrapper']"
  >
    <template v-if="customAccountingError">
      <div v-html="customAccountingError"></div>
    </template>
    <template v-else-if="isBankConnectError">
      {{ bankConnectError() }}
    </template>
    <template v-else-if="isBankStatementsError">
      <div v-html="bankStatementsError"></div>
    </template>
    <template v-else-if="accountTakenError || connectionError">
      <div v-html="accountTakenError || connectionError"></div>
    </template>
    <template v-else>
      {{ $t('components.bannerConnectAccountsError.reachUsByPhoneAt') }}
      <span :class="[$style.contact, $style.unlink]">{{
        this.contactDetails.number
      }}</span>
      <a
        :class="[$style.contact, $style.link]"
        :href="this.contactDetails.link"
        @click="$emit('errorBannerAction', 'phone_click')"
        >{{ this.contactDetails.number }}
      </a>
      {{ $t('components.bannerConnectAccountsError.orByEmailAt') }}
      <a
        :href="email.href"
        :class="$style.contact"
        @click="$emit('errorBannerAction', 'email_click')"
        >{{ email.display }}</a
      >.
    </template>
  </UiMsg>
</template>

<script>
import { ContactSupportMethods } from '@/data/contact-support';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapGetters } from 'vuex';
import UiMsg from '@/components/UiMsg';
import { COUNTRY_CODES } from '@/data/supported-country-codes';

export default {
  components: {
    UiMsg,
  },
  props: {
    showBanner: {
      type: Boolean,
      default: false,
    },
    isBankStatementsError: {
      type: Boolean,
      default: false,
    },
    isBankConnectError: {
      type: Boolean,
      default: false,
    },
    customAccountingError: {
      type: String,
      default: '',
    },
    customBankingError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: ContactSupportMethods.email,
    };
  },
  computed: {
    ...mapGetters(['contactDetails']),
    ...mapRequestStatuses({
      oauthConnectionRequest: 'OAUTH_CONNECT',
      addBankConnectionRequest: 'ADD_USER_BANK_CONNECTION',
    }),
    COUNTRY_CODES: () => COUNTRY_CODES,
    accountTakenError() {
      if (
        this.oauthConnectionRequest?.error?.type ===
        'ExternalAccountOwnedByOtherBusiness'
      ) {
        const { error } = this.oauthConnectionRequest;
        const regex = RegExp(/[<>/]/g);
        if (regex.test(error)) {
          return null;
        }
        const translatedError = error.errorCode
          ? this.$t(`backendError.${error.errorCode}`, error.details)
          : error.message;
        return translatedError;
      }
      return null;
    },
    bankStatementsError() {
      return this.$t(
        'components.bannerConnectAccountsError.bankStatementsError',
      );
    },
    connectionError() {
      const { error } = this.oauthConnectionRequest;
      const regex = RegExp(/[<>/]/g);
      if (regex.test(error)) {
        return '';
      }
      let translatedError;
      if (error && typeof error === 'object') {
        translatedError = error.errorCode
          ? this.$t(`backendError.${error.errorCode}`, error.details)
          : error.message;
      } else {
        translatedError = error;
      }

      return translatedError;
    },
    defaultError() {
      return this.$t('components.bannerConnectAccountsError.defaultError', {
        phoneDisplay: this.contactDetails.number,
        emailDisplay: this.email.display,
      });
    },
  },
  watch: {
    showBanner(newValue) {
      if (newValue) {
        this.$emit(
          'errorBannerAction',
          'visible',
          this.isBankStatementsError
            ? this.bankStatementsError
            : this.accountTakenError ||
                this.connectionError ||
                this.defaultError,
        );
      }
    },
  },
  methods: {
    bankConnectError() {
      if (this.customBankingError) {
        return this.customBankingError;
      } else if (this.addBankConnectionRequest?.error?.type === 'Conflict') {
        const translatedError = this.addBankConnectionRequest.error.errorCode
          ? this.$t(
              `backendError.${this.addBankConnectionRequest.error.errorCode}`,
              this.addBankConnectionRequest.error.details,
            )
          : `${this.addBankConnectionRequest.error.message}.`;
        return translatedError;
      }
      return this.$t('components.bannerConnectAccountsError.bankConnectError');
    },
  },
};
</script>

<style lang="less" module>
.message-wrapper {
  max-width: 683px;
  margin: 0 auto;
  .contact {
    color: @link-blue;
    text-decoration: underline;
    &.link {
      @media only screen and (min-width: 500px) {
        display: none;
      }
    }
    &.unlink {
      color: unset;
      text-decoration: none;
      @media only screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}
</style>
