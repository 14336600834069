<template>
  <span data-cy="ext-account-connector">
    <div
      :class="{
        [$style['ext-account']]: true,
        [$style.complete]: success,
      }"
      :data-cy="kebabCase(title)"
      @click="openModal"
    >
      <div v-if="!isMissingPlatformTile" :class="$style.wrapper">
        <div :class="$style.content">
          <img
            :src="logo"
            :class="{
              [$style.logo]: true,
              [$style.small]: smallLogo,
              [$style.large]: largeLogo,
            }"
            :alt="logoAlt"
          />
          <UiRoundIcon
            v-if="success"
            theme="teal"
            name="checkmark"
            size="tiny"
            :class="[$style.icon, $style.checkmark, 'checkmark']"
          />
          <UiRoundIcon
            v-else-if="warning || processing"
            theme="yellow"
            name="exclamation"
            size="tiny"
            :class="[$style.icon]"
          />
          <UiRoundIcon
            v-else-if="(showError || disconnected) && !success"
            theme="red"
            name="exclamation"
            size="tiny"
            :class="[$style.icon, $style.exclamation]"
          />
          <UiRoundIcon
            v-else
            theme="white"
            name="plus"
            size="tiny"
            :class="[$style.icon, $style.plus]"
          />
        </div>
        <div
          v-if="!success && showError"
          :class="$style['error-message-wrapper']"
        >
          <div
            :class="{
              [$style['error-message']]: true,
              [$style['timed']]: isTimed,
            }"
          >
            {{ $t('components.modalExternalAccountOnboarding.notConnected') }}
          </div>
        </div>
      </div>
      <div v-else :class="$style.text" data-cy="dont-see-my-platform">
        {{ $t('components.modalExternalAccountOnboarding.iDontSeeMyPlatform') }}
      </div>
    </div>
    <ModalNoPlatform
      v-if="isMissingPlatformTile"
      ref="noPlatformModal"
      @platformEntered="
        (platform) => $emit('noPlatformModalAction', 'entered', platform)
      "
      @platformRequested="
        (platform) => $emit('noPlatformModalAction', 'submitted', platform)
      "
    />
  </span>
</template>

<script>
import { inject } from 'vue';
import { UiRoundIcon } from 'ui-components';
import analytics from '@/utils/analytics';
import kebabCase from '@/utils/kebabcase';
import { validateChildren } from '@/composables/validation';
import { mapGetters } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import ModalNoPlatform from '@/components/modals/ModalNoPlatform';

export default {
  components: {
    UiRoundIcon,
    ModalNoPlatform,
  },
  mixins: [stateModuleAware],
  props: {
    title: String,
    logo: String,
    success: Boolean,
    disconnected: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
    error: Boolean,
    loading: Boolean,
    smallLogo: Boolean,
    largeLogo: { type: Boolean, default: false },
    logoAlt: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.logo');
      },
    },
    isMissingPlatformTile: { type: Boolean, default: false },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      timeoutId: null,
      isSubmitted: false,
      requestedPlatform: '',
      isTimed: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel']),
    modalClasses() {
      return {
        [this.$style['modal-content']]: true,
        [this.$style.submitted]: this.isSubmitted,
      };
    },
    showError() {
      return this.error;
    },
    timedError() {
      return this.success && this.showError;
    },
  },
  watch: {
    timedError(newTimedError) {
      this.isTimed = false;
      if (newTimedError) {
        setTimeout(() => {
          this.isTimed = true;
        }, 5000);
      }
    },
    showError(newVal) {
      if (newVal) {
        analytics.track('fe_tile_status_not_connected', {
          platform: this.title,
          productSegment: this.productSegmentLabel,
          experiment: this.defaultConfig?.experiments?.modals,
        });
      }
    },
  },
  methods: {
    openModal() {
      if (this.isMissingPlatformTile) {
        window.clearTimeout(this.timeoutId);
        this.isSubmitted = false;
        this.requestedPlatform = '';
        this.$emit('noPlatformModalAction', 'opened');
        this.$refs.noPlatformModal.open();
      } else {
        this.$emit('clickOpenModal');
      }
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.ext-account {
  align-items: center;
  background: @color-white;
  border-radius: 7.5px;
  border: 1px solid @color-lightgray-100;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  height: 90px;
  justify-content: center;
  margin: 7.5px 5px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 160px;
  cursor: pointer;
  @media screen and (max-width: 500px) and (min-width: 375px) {
    width: calc(50% - 7.5px);
  }
  &.complete {
    background-color: @whiter-gray;
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .content {
      width: 100%;
      padding: 0 13px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .error-message-wrapper {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: calc(100% + 2px);
    background: @error-bg-transparent;
    margin: 0 -1px -1px;
    border-bottom-right-radius: 7.5px;
    border-bottom-left-radius: 7.5px;
    .error-message {
      text-align: center;
      font-size: 14px;
      color: @medium-gray;
      &.timed {
        margin-bottom: -100px;
        transition: margin-bottom 1s cubic-bezier(0.5, 0, 0.75, 0);
      }
    }
  }

  .logo {
    max-width: 91px;
    max-height: 25px;

    &.small {
      width: 100%;
      max-width: 70px;
      max-height: 24px;
      padding-left: 5px;
    }

    &.large {
      max-height: 40px;
    }
    @media screen and (max-width: 500px) and (min-width: 375px) {
      margin: auto;
    }
  }

  .icon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.checkmark {
      line-height: 22px;
    }
    &.plus {
      color: @medium-gray;
      border-color: @medium-gray;
      border: 1px solid;
    }
    &.exclamation {
      border: 1px solid @red;
    }
  }

  .text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    padding: 0 5px;
  }
}
</style>
