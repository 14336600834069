import EmbedPage from '@/pages/embed-page';
import { EMBED } from '@/data/routes';
import Calculator from '@/components/how-it-works/Calculator';

export default [
  {
    path: '/embed',
    name: EMBED.INDEX,
    component: EmbedPage,
    meta: {
      displayNav: false,
    },
    children: [
      {
        path: 'calculator',
        name: EMBED.CALCULATOR,
        component: Calculator,
      },
    ],
  },
];
