<template>
  <div :class="classes">
    <span :class="$style.text"><slot /></span>
    <input
      type="checkbox"
      :checked="modelValue"
      :aria-label="name + ' checkbox'"
    />
    <span
      :data-cy="kebabCase(name + ' checkbox')"
      :required="required"
      :class="$style.checkbox"
      :tabindex="0"
      @click="onClick"
      @keyup.enter="onClick"
    >
      <svg
        v-if="modelValue"
        :class="$style.checkmark"
        data-cy="checked-box"
        v-html="require('@/assets/icons/checkmark-onboarding2.svg')"
      ></svg>
    </span>
    <div
      v-if="error || (isTouched && !isValid && required)"
      :class="$style['error-msg']"
    >
      {{ validationErrorMsg || errorMsg }}
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import kebabCase from '@/utils/kebabcase';
import { inputValidationSetup } from '@/composables/validation';

export default {
  emits: ['input', 'update:modelValue'],
  props: {
    name: String,
    required: Boolean,
    disabled: Boolean,
    error: Boolean,
    errorMsg: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.requiredField');
      },
    },
    modelValue: {},
    theme: String,
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      validationErrorMsg: '',
    };
  },
  computed: {
    classes() {
      return {
        [this.$style['checkbox-container']]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.error]:
          this.error || (this.isTouched && !this.isValid && this.required),
        [this.$style.active]: this.modelValue,
        [this.$style.transparent]: this?.theme === 'transparent',
      };
    },
  },
  watch: {
    isTouched() {
      this.validate(this.modelValue);
    },
    modelValue(val) {
      this.validate(val);
    },
  },
  mounted() {
    if (!this.required) {
      this.isValid = true;
    }
  },
  methods: {
    validate(val) {
      this.isValid = this.required ? !!val : true;
    },
    onClick(e) {
      if (this.disabled) {
        return;
      }
      this.$emit('input', !this.modelValue, this.name);
      this.$emit('update:modelValue', !this.modelValue);
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.checkbox-container {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: @medium-gray;
  min-height: 20px;
  user-select: none;
  padding: 2px 0 0 40px;
  text-align: left;
  clear: both;
  height: fit-content;

  input[type='checkbox'] {
    display: none;
  }

  &.error {
    min-height: 40px;
  }

  &.disabled {
    color: @color-gray;
    pointer-events: none;
  }

  .text {
    display: inline-block;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    box-shadow: inset 0 0 0 1px @onboarding-light-gray;
    background: @color-white;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: @color-black;
    line-height: 16px;
    padding-left: 1px;

    .transparent& {
      background: none;
    }

    &:focus {
      box-shadow: 0 0 0 2px @color-blue-focus;
    }

    .error& {
      box-shadow: inset 0 0 0 1px @color-red;
    }

    .checkmark {
      height: 15px;
      width: 15px;
      margin-top: 4px;
    }

    .active& {
      background: @self-serve-nav-bar-gray;
    }

    .disabled& {
      color: @color-gray;
      box-shadow: inset 0 0 0 1px @color-gray;
    }
  }

  .error-msg {
    position: absolute;
    margin-top: 2px;
    font-size: @font-size-12;
    line-height: @font-size-12;
    text-align: left;
    color: @color-red;
  }

  @media only screen and (max-width: 670px) {
    margin-bottom: 10px;
    padding-top: 3px;
  }
}
</style>
