// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tile--highlight_QF6AE .tile__logo_PK56R{border:1px solid #dadada;background:#f8f8fa}.tile--highlight_QF6AE svg{fill:#fff}.tile--highlight_QF6AE svg path{stroke:#7f31ff;stroke-width:1}.tile--highlight_QF6AE .tile__name_olmxz{display:inline-block;border-bottom:1px dotted #dadada}.tile--selected_LWUeE .tile__logo_PK56R{border:1px solid #5b00f0}.tile__logo_PK56R{border:1px solid #e8e8ea;border-radius:8px;padding:10px;display:flex;align-items:center;justify-content:center;height:80px;background:#fff}.tile__logo_PK56R img,.tile__logo_PK56R span,.tile__logo_PK56R svg{transition:transform .25s}.tile__logo_PK56R img{height:50px;width:auto}.tile__logo_PK56R span{height:40px;width:40px;background:#e8e8ea;border-radius:8px;display:flex;align-items:center;justify-content:center;font-size:14px;font-weight:500}.tile_Dbwme:hover .tile__logo_PK56R img,.tile_Dbwme:hover .tile__logo_PK56R span,.tile_Dbwme:hover .tile__logo_PK56R svg{transform:scale(.8)}.tile__name_olmxz{display:block;color:#000;text-align:center;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:16px;font-style:normal;font-weight:300;line-height:1.5;margin-top:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile--highlight": "tile--highlight_QF6AE",
	"tile__logo": "tile__logo_PK56R",
	"tile__name": "tile__name_olmxz",
	"tile--selected": "tile--selected_LWUeE",
	"tile": "tile_Dbwme"
};
export default ___CSS_LOADER_EXPORT___;
