<template>
  <ProgressSpinner v-if="isLoading" :class="$style['loader']" />
  <Panel v-else :header="$t('paymentsPage.status')" class="status-timeline">
    <Timeline :value="timelineData">
      <template #marker="slotProps">
        <BillStatusMarker :marker="getMarkerType(slotProps.item.status)" />
      </template>
      <template #content="slotProps">
        <div class="timeline-label-container">
          <span :class="milestoneHeadingClasses(slotProps.item.status)">{{
            slotProps.item.title
          }}</span>
          <br />
          <span :class="milestoneSubheadingClasses(slotProps.item.status)">
            <span v-html="slotProps.item.subtitle" />
            <template v-if="slotProps.item.link">
              -
              <a
                :href="slotProps.item.link.url"
                :target="slotProps.item.link.target"
                @click="slotProps.item.link.callback"
              >
                {{ slotProps.item.link.text }}
              </a>
            </template>
          </span>
          <span
            v-if="slotProps.item.showDeclinedMessage"
            :class="milestoneSubheadingClasses(slotProps.item.status)"
          >
            A member of our team will reach out to you or contact
            <a href="mailto:support@clear.co" target="_blank"
              >support@clear.co</a
            >.
          </span>
          <template v-if="slotProps.item.cta">
            <br />
            <DSButton
              class="m-y-10"
              :loading="redirecting"
              @click="handleCtaClick(slotProps.item)"
            >
              {{ slotProps.item.cta.text }}
            </DSButton>
            <Message
              v-if="primaryBankAccountNumberIsTokenized"
              severity="info"
              :closable="false"
              class="tokenizedBankAccountNumberWarning"
            >
              {{ this.$t('paymentsPage.tokenizedBankAccountNumberWarning') }}
            </Message>
          </template>
        </div>
      </template>
      <template #connector="slotProps">
        <BillStatusConnector
          :connector="getConnectorType(slotProps.item.status)"
        />
      </template>
    </Timeline>
    <a class="timeline__expand" @click="toggleExpanded">{{
      isExpanded ? '- View less statuses' : '+ View all statuses'
    }}</a>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Panel from '@clearbanc/clear-components/panel';
import Timeline from '@clearbanc/clear-components/timeline';
import ProgressSpinner from '@clearbanc/clear-components/progressspinner';
import {
  PAYMENTS_ROUTE_NAMES,
  CLEARPAY_SEGMENT_EVENTS,
  BillStatus,
  BillDeclinedDescription,
  TransactionPlatform,
  isVendorDenied,
} from '@/data/payments';
import { getFormattedDateString, getShortBillId } from '@/utils/payments';
import { MILESTONE_STATUSES, MILESTONES } from '@/data/bill-status-timeline';
import { API_FAILURE_CODES, PAYMENT_TYPES } from '@/data/currency-cloud';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import { getBrowserWidth } from '@/utils/browser';

import * as dateFns from 'date-fns';
import analytics from '@/utils/analytics';
import billComposables from '@/composables/bills';
import { useContextRoot } from '@/utils/context-root';
import BillStatusConnector from './BillStatusConnector';
import BillStatusMarker from './BillStatusMarker';

export default {
  components: {
    Timeline,
    BillStatusMarker,
    BillStatusConnector,
    Panel,
    DSButton,
    ProgressSpinner,
    Message,
  },
  props: {
    billContract: {
      type: Object || null,
      required: false,
    },
    loading: { type: Boolean, required: false, default: false },
    bill: Object,
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { billStatusData } = billComposables(context, ctxRoot);
    return { billStatusData };
  },
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
      MILESTONE_STATUSES,
      MILESTONES,
      redirecting: false,
      isExpanded: false,
      payments: null,
      loadingPayments: false,
      errorLoadingPayments: false,
      statusEventTracked: false,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthorizedSignatory',
      'business',
      'selectedBill',
      'authorizedSignatory',
      'primaryBankAccountNumberIsTokenized',
    ]),
    isLoading() {
      return this.loading || this.loadingPayments;
    },
    billMilestoneData() {
      return this.billStatusData(
        this.bill.id,
        this.billContract,
        this.payments,
      );
    },
    allTimelineData() {
      const data = [
        {
          milestone: MILESTONES.INVOICE_SUBMITTED,
          status: MILESTONE_STATUSES.COMPLETE,
          title: this.$t(
            this.billMilestoneData.milestoneStatuses[
              MILESTONES.INVOICE_SUBMITTED
            ].label,
          ),
          subtitle: `${this.$t('common.date')}: ${getFormattedDateString(
            this.bill.createdAt,
          )}`,
          cta: null,
          link: null,
          showDeclinedMessage: false,
        },
        ...this.diligenceMilestone,
        this.contractSignatureMilestone,
        this.errorLoadingPayments
          ? this.paymentsErrorMilestone
          : this.paidMilestone[0],
      ];

      if (this.isBillCancelled) {
        // insert 'cancelled' milestone either before or after the diligence milestone since
        // user cannot cancel bill after the contract has been signed
        data.splice(
          this.wasBillCancelledBeforeDiligenceMilestone ? 1 : 2,
          0,
          this.cancelledMilestone,
        );
      }

      if (!this.errorLoadingPayments && this.paidMilestone.length > 1) {
        data.push(this.paidMilestone[1]);
      }

      return data;
    },
    timelineData() {
      if (this.isExpanded) {
        return this.allTimelineData;
      }
      const milestoneInProgress = this.allTimelineData
        .slice()
        .reverse()
        .find(
          (milestone) => milestone.status !== MILESTONE_STATUSES.NOT_STARTED,
        );
      return [milestoneInProgress];
    },
    diligenceMilestone() {
      const { milestoneStatuses } = this.billMilestoneData;
      const { status } = milestoneStatuses[MILESTONES.DILIGENCE] ?? {};
      const extraMilestones = [];
      let subtitle = null;
      let cta = null;

      const declinedReason = this.bill?.bnplDetails?.diligenceDeclinedReason;

      switch (status) {
        case MILESTONE_STATUSES.FAILED: {
          const subtitleSufix =
            BillDeclinedDescription[declinedReason] ||
            'Unfortunately, your bill does not qualify for financing. If you have more questions, please contact us.';
          subtitle = `New vendor is approved. ${subtitleSufix}`;
          if (this.selectedBill.isReceipt) {
            extraMilestones.push({
              title: 'Receipt Declined',
              subtitle: isVendorDenied(declinedReason)
                ? 'Unfortunately, your receipt is declined because the vendor is not approved.'
                : subtitle,
              status: MILESTONE_STATUSES.FAILED,
            });
            const formattedDate = getFormattedDateString(
              this.selectedBill.bnplDetails.diligenceDeclinedAt,
            );
            subtitle = `Date: ${formattedDate}`;
          }
          break;
        }
        case MILESTONE_STATUSES.COMPLETE:
          subtitle = this.bill?.bnplDetails?.diligenceApprovedAt
            ? `${this.$t('common.date')}: ${getFormattedDateString(
                this.bill.bnplDetails.diligenceApprovedAt,
              )}`
            : '';
          break;
        case MILESTONE_STATUSES.ACTION_REQUIRED_VENDOR_DETAILS:
          subtitle = this.$t('paymentsPage.provideVendorDetails');
          cta = {
            text: this.$t(
              'account.pages.pageVendors.connectedAccountsStatus.confirmVendorDetails',
            ),
            route: null,
          };
          break;
        case MILESTONE_STATUSES.ACTION_REQUIRED_BIZ_PROFILE:
          subtitle = this.$t('paymentsPage.completeYourBusinessProfile');
          cta = {
            text: this.$t('paymentsPage.completeBusinessProfileCapitalized'),
            route: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
          };
          break;
        case MILESTONE_STATUSES.ACTION_REQUIRED_BANK_DOCUMENTS:
          subtitle =
            'To process payment, please upload additional financial documents';
          cta = {
            text: 'Upload financial documents',
            route: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS,
          };
          break;
        case MILESTONE_STATUSES.ACTION_REQUIRED_VERIFY_REVENUE:
          subtitle =
            'Verify revenue to complete bill submission and avoid funding delays';
          cta = {
            text: 'Verify revenue',
            route: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
          };
          break;
        case MILESTONE_STATUSES.ACTION_REQUIRED_INSUFFICIENT_CAPACITY:
          subtitle =
            'Connect additional sales accounts to increase Funding Capacity or cancel and resubmit bills based on your Preliminary Funding Capacity';
          break;
        default:
          subtitle = this.$t('paymentsPage.approvalCanTake48Hours');
          break;
      }

      const data = {
        subtitle,
        cta,
      };

      return [
        ...extraMilestones,
        {
          link: null,
          showDeclinedMessage: false,
          ...data,
          milestone: MILESTONES.DILIGENCE,
          status,
          title: this.$t(milestoneStatuses[MILESTONES.DILIGENCE].label),
        },
      ];
    },
    contractSignatureMilestone() {
      const { milestoneStatuses } = this.billMilestoneData;
      const status = milestoneStatuses[MILESTONES.SIGN_CONTRACT].status;
      let subtitle = null;
      let cta = null;

      if (
        status === MILESTONE_STATUSES.NOT_STARTED ||
        status === MILESTONE_STATUSES.PROCESSING
      ) {
        subtitle = this.$t('paymentsPage.dateTBD');
      } else if (status === MILESTONE_STATUSES.ACTION_REQUIRED_SIGN_CONTRACT) {
        if (this.isAuthorizedSignatory) {
          subtitle = this.$t('paymentsPage.pleaseReviewAndAcceptAgreement');
          cta = {
            text: this.$t('paymentsPage.reviewAndAccept'),
            route: null,
          };
        } else if (this.authorizedSignatoryInfoAvailable) {
          subtitle = this.$t(
            'paymentsPage.reviewAgreements.yourAuthorizedSignatoryHasBeenSentContract',
            {
              authorizedSignatoryFullName: `${this.authorizedSignatory.firstName} ${this.authorizedSignatory.lastName}`,
              authorizedSignatoryEmail: this.authorizedSignatory.email,
            },
          );
        } else {
          subtitle = this.$t(
            'paymentsPage.reviewAgreements.authorizedSignatoryHasBeenSentContract',
          );
        }
      } else {
        subtitle = this.billContract?.userSignedAt
          ? `${this.$t('paymentsPage.date')}: ${getFormattedDateString(
              this.billContract?.userSignedAt,
            )}`
          : '';
      }

      const data = {
        subtitle,
        cta,
      };

      return {
        link: null,
        showDeclinedMessage: false,
        ...data,
        milestone: MILESTONES.SIGN_CONTRACT,
        status,
        title: this.$t(milestoneStatuses[MILESTONES.SIGN_CONTRACT].label),
      };
    },
    getPaymentMilestone() {
      const {
        mostRecentPayment,
        mostRecentDeclinedPayment,
        milestoneStatuses,
      } = this.billMilestoneData;
      const status = milestoneStatuses[MILESTONES.PAID].status;
      let data;
      let declinedMilestone = {};

      if (status === MILESTONE_STATUSES.NOT_STARTED) {
        data = {
          subtitle: this.$t('paymentsPage.dateTBD'),
        };
      } else if (this.payments && this.payments.length) {
        const estimatedDateOffset =
          mostRecentPayment.paymentType === PAYMENT_TYPES.PRIORITY ? 1 : 2;
        const estimatedDate = getFormattedDateString(
          dateFns.addBusinessDays(
            new Date(mostRecentPayment.paymentDate),
            estimatedDateOffset,
          ),
        );
        const declinedDate = mostRecentDeclinedPayment
          ? `${this.$t('paymentsPage.date')}: ${getFormattedDateString(
              new Date(mostRecentDeclinedPayment.updatedAt),
            )}`
          : '';

        let subtitle = `${this.$t(
          'paymentsPage.estimatedDate',
        )}: ${estimatedDate}`;
        let link = null;
        let showDeclinedMessage = false;

        if (
          mostRecentDeclinedPayment &&
          mostRecentDeclinedPayment.id !== mostRecentPayment.id
        ) {
          declinedMilestone = {
            milestone: MILESTONES.PAID,
            status: MILESTONE_STATUSES.FAILED,
            title: this.$t('paymentsPage.declined'),
            subtitle: declinedDate,
            showDeclinedMessage: true,
            cta: null,
            link: null,
          };
        }

        if (status === MILESTONE_STATUSES.FAILED) {
          subtitle = declinedDate;
          showDeclinedMessage = true;
        } else if (status === MILESTONE_STATUSES.COMPLETE) {
          subtitle = `${this.$t('paymentsPage.date')}: ${getFormattedDateString(
            new Date(mostRecentPayment.paymentDate),
          )}`;
          link = mostRecentPayment.confirmationUrl
            ? {
                text: 'Download confirmation',
                target: '_blank',
                url: mostRecentPayment.confirmationUrl,
                callback: this.trackPaymentConfirmation,
              }
            : null;
        }

        data = {
          subtitle,
          link,
          showDeclinedMessage,
        };
      } else if (status === MILESTONE_STATUSES.PROCESSING) {
        data = {
          subtitle: this.$t('paymentsPage.estimatedDateTBD'),
        };
      } else if (status === MILESTONE_STATUSES.COMPLETE) {
        data = {
          subtitle: this.bill.internalPaymentDate
            ? `${this.$t('paymentsPage.date')}: ${getFormattedDateString(
                this.bill.internalPaymentDate,
              )}`
            : null,
        };
      }

      const paymentMilestone = [
        {
          cta: null,
          link: null,
          showDeclinedMessage: false,
          ...data,
          milestone: MILESTONES.PAID,
          status,
          title: this.$t(milestoneStatuses[MILESTONES.PAID].label),
        },
      ];

      if (Object.keys(declinedMilestone).length > 0) {
        paymentMilestone.unshift(declinedMilestone);
      }

      return paymentMilestone;
    },
    paidMilestone() {
      return this.getPaymentMilestone;
    },
    authorizedSignatoryInfoAvailable() {
      const { firstName, lastName, email } = this.authorizedSignatory ?? {};
      return !!firstName && !!lastName && !!email;
    },
    cancelledMilestone() {
      if (!this.isBillCancelled) {
        return null;
      }
      return {
        title: this.$t('data.payments.billStatus.cancelled'),
        subtitle: this.billCancelledAt
          ? `Date: ${getFormattedDateString(this.billCancelledAt)}`
          : null,
        status: MILESTONE_STATUSES.FAILED,
        cta: null,
        link: null,
        showDeclinedMessage: false,
      };
    },
    paymentsErrorMilestone() {
      if (!this.errorLoadingPayments) {
        return null;
      }
      const supportEmail = 'support@clear.co';
      return {
        title: 'Server Error',
        subtitle: `We cannot load data on your payment status at this moment. Please refresh or reach out to <a href="mailto:${supportEmail}" target="_blank">${supportEmail}</a> for support.`,
        status: MILESTONE_STATUSES.FAILED,
        cta: null,
        link: null,
        showDeclinedMessage: false,
      };
    },
    isBillCancelled() {
      return this.bill.status === BillStatus.CANCELLED;
    },
    billCancelledAt() {
      return this.billMilestoneData.billCancelledAt;
    },
    isDiligenceMilestoneInProgress() {
      return this.billMilestoneData.isDiligenceMilestoneInProgress;
    },
    wasBillCancelledBeforeDiligenceMilestone() {
      return this.billMilestoneData.wasBillCancelledBeforeDiligenceMilestone;
    },
    hasLegacyPaymentPlatform() {
      return (
        !!this.bill.payOutPlatform &&
        this.bill.payOutPlatform !== TransactionPlatform.CURRENCY_CLOUD
      );
    },
  },
  async created() {
    if (this.billMilestoneData?.milestoneInProgress.name === MILESTONES.PAID) {
      this.payments = await this.getPaymentTransactions();
    }
  },
  async mounted() {
    if (this.billMilestoneData?.milestoneInProgress.name !== MILESTONES.PAID) {
      this.trackTimelineStatus();
    }

    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');
  },
  methods: {
    async handleCtaClick(milestoneData) {
      if (milestoneData.milestone === MILESTONES.DILIGENCE) {
        if (milestoneData.cta.route) {
          this.$router.push({ name: milestoneData.cta.route });
        } else {
          this.$emit('goToVendorTab');
        }
      } else if (milestoneData.milestone === MILESTONES.SIGN_CONTRACT) {
        await this.signBnplContract();
      }
    },
    milestoneHeadingClasses(status) {
      return {
        heading: true,
        'text-active': status !== MILESTONE_STATUSES.NOT_STARTED,
        'text-inactive': status === MILESTONE_STATUSES.NOT_STARTED,
        'heading-in-progress': status === MILESTONE_STATUSES.PROCESSING,
        'heading-failed': status === MILESTONE_STATUSES.FAILED,
        'heading-action-required': [
          MILESTONE_STATUSES.ACTION_REQUIRED_VENDOR_DETAILS,
          MILESTONE_STATUSES.ACTION_REQUIRED_BIZ_PROFILE,
          MILESTONE_STATUSES.ACTION_REQUIRED_SIGN_CONTRACT,
          MILESTONE_STATUSES.ACTION_REQUIRED_VERIFY_REVENUE,
          MILESTONE_STATUSES.ACTION_REQUIRED_BANK_DOCUMENTS,
          MILESTONE_STATUSES.ACTION_REQUIRED_INSUFFICIENT_CAPACITY,
        ].includes(status),
      };
    },
    milestoneSubheadingClasses(status) {
      return {
        subheading: true,
        'text-active': status !== MILESTONE_STATUSES.NOT_STARTED,
        'text-inactive': status === MILESTONE_STATUSES.NOT_STARTED,
      };
    },
    getMarkerType(status) {
      switch (status) {
        case MILESTONE_STATUSES.COMPLETE:
          return 'complete';
        case MILESTONE_STATUSES.ACTION_REQUIRED_VENDOR_DETAILS:
        case MILESTONE_STATUSES.ACTION_REQUIRED_BIZ_PROFILE:
        case MILESTONE_STATUSES.ACTION_REQUIRED_SIGN_CONTRACT:
        case MILESTONE_STATUSES.ACTION_REQUIRED_VERIFY_REVENUE:
        case MILESTONE_STATUSES.ACTION_REQUIRED_BANK_DOCUMENTS:
        case MILESTONE_STATUSES.ACTION_REQUIRED_INSUFFICIENT_CAPACITY:
          return 'caution';
        case MILESTONE_STATUSES.FAILED:
          return 'fail';
        case MILESTONE_STATUSES.PROCESSING:
          return 'processing';
        default:
          return 'default';
      }
    },
    getConnectorType(status) {
      return status === MILESTONE_STATUSES.COMPLETE ? 'complete' : 'default';
    },
    async getPaymentTransactions() {
      if (this.hasLegacyPaymentPlatform) return null;
      try {
        this.loadingPayments = true;
        const response = await this.$store.dispatchApiAction(
          'GET_BILL_PAYMENT_TRANSACTIONS',
          {
            billId: this.bill.id,
          },
        );

        if (
          response.failureCode &&
          [
            API_FAILURE_CODES.MISSING_ADVANCE,
            API_FAILURE_CODES.TRANSACTION_FAILURE,
          ].includes(response.failureCode)
        ) {
          this.setPaymentsError(
            response.failureMessage || response.failureCode,
          );
        } else if (this.errorLoadingPayments) {
          this.errorLoadingPayments = false;
        }

        return response.payments;
      } catch (err) {
        this.setPaymentsError(err.message || err);
        return null;
      } finally {
        this.loadingPayments = false;
      }
    },
    trackPaymentConfirmation() {
      analytics.track(
        CLEARPAY_SEGMENT_EVENTS.CLICKED_DOWNLOAD_PAYMENT_CONFIRMATION,
        {
          businessId: this.business.id,
          submissionId: this.bill.id,
        },
      );
    },
    setPaymentsError(errorMessage = '') {
      this.errorLoadingPayments = true;
      analytics.track(CLEARPAY_SEGMENT_EVENTS.BE_STATUS_ERROR, {
        businessId: this.business.id,
        submissionId: this.bill.id,
        errorMsg: errorMessage,
      });
    },
    trackTimelineStatus() {
      if (this.statusEventTracked) return;
      const { milestoneInProgress } = this.billMilestoneData;
      analytics.track(CLEARPAY_SEGMENT_EVENTS.FE_CHECK_TIMELINE_STATUS, {
        businessId: this.business.id,
        submissionId: this.bill.id,
        activeMilestone: milestoneInProgress.name,
        activeMilestoneStatus: milestoneInProgress.status,
        activeMilestoneLabel: this.$t(milestoneInProgress.label),
      });
      this.statusEventTracked = true;
    },
    async signBnplContract() {
      try {
        this.redirecting = true;
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BNPL_REVIEW_AGREEMENT, {
          businessId: this.business.id,
          businessName: this.business.name,
          shortBillId: getShortBillId(this.bill.id),
        });
        analytics.track('if_user_click_sign_CTA', {
          businessId: this.business.id,
          businessName: this.business.name,
          shortBillId: getShortBillId(this.bill.id),
          billId: this.bill.id,
        });
        const response = await this.$store.dispatchApiAction(
          'SIGN_BNPL_CONTRACT_FORM',
          {
            billId: this.bill.id,
            shortBillId: getShortBillId(this.bill.id),
            returnURL: `${window.location.protocol}//${window.location.host}/payments/${this.bill.id}`,
          },
        );
        if (response.embeddedSigningSession.url !== undefined) {
          this.contractSignErrored = false;
          window.location = response.embeddedSigningSession.url;
        }
      } catch (err) {
        this.contractSignErrored = true;
        this.redirecting = false;
      }
    },
    toggleExpanded() {
      const oldVal = this.isExpanded;
      this.isExpanded = !oldVal;

      if (this.isExpanded) {
        analytics.track('if_user_view_submitted_bill_status_timeline', {
          type: this.selectedBill.isReceipt ? 'Receipt' : 'Invoice',
          browserWidth: getBrowserWidth(),
        });
      }
    },
  },
  watch: {
    billMilestoneData() {
      if (
        this.billMilestoneData?.milestoneInProgress.name === MILESTONES.PAID
      ) {
        this.trackTimelineStatus();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.status-timeline {
  text-align: left;

  :deep(.p-timeline-event) {
    &:not(:last-child) {
      .timeline-label-container {
        padding-bottom: 20px;
      }
    }
  }

  .timeline-label-container {
    font-size: 14px;

    .heading {
      display: inline-block;
      font-family: @gerstner-font;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .text-active {
      color: #000000;
    }

    .text-inactive {
      color: #ababad;
    }

    .heading-pill {
      color: #000000;
      border-radius: 4px;
      padding: 1px 6px;
    }

    .heading-in-progress {
      .heading-pill();
      background: #dee9f5;
    }

    .heading-failed {
      .heading-pill();
      background: #ffe6e4;
    }

    .heading-action-required {
      .heading-pill();
      background: #ffecbc;
    }

    .subheading {
      font-family: @gerstner-font;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      padding: 0 3px;
      display: inline-block;

      & + .subheading {
        margin-top: 10px;
      }
    }
  }

  .timeline__expand {
    display: flex;
    color: @color-info-400;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
    cursor: pointer;
  }
  .tokenizedBankAccountNumberWarning {
    margin: 0px;
    :deep(.p-message-text) {
      font-size: 14px;
      font-weight: 300;
    }
  }
}
</style>
<style lang="less" module>
.loader {
  display: block;
  margin: 2rem auto;
}
</style>
