<template>
  <div :class="$style['logo']">
    <div v-if="isSvgString" v-html="image"></div>
    <img
      v-else-if="image"
      :src="getInlineImageData"
      :alt="`${bankName} logo`"
    />
    <span v-else>{{ getBankShortInitials }}</span>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    },
    bankName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSvgString() {
      return this.image?.startsWith('<svg');
    },
    getInlineImageData() {
      return `data:image/png;base64,${this.image}`;
    },
    getBankShortInitials() {
      if (this.bankName) {
        const words = this.bankName.split(' ').filter((word) => word !== '');
        const letters = words.map((word) => word[0]).join('');
        return letters.slice(0, 2);
      }

      return '';
    },
  },
};
</script>

<style lang="less" module>
.logo img,
.logo svg,
.logo span {
  flex-shrink: 0;
}

.logo svg,
.logo img {
  height: 40px;
  width: 40px;
}

.logo span {
  height: 40px;
  width: 40px;
  background: #e8e8ea;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
</style>
