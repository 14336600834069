import { EMAIL_VERIFICATION } from '@/data/routes';
import EmailVerificationPage from '@/pages/email-verification-page';

export default [
  {
    path: '/email-verification',
    name: EMAIL_VERIFICATION.INDEX,
    component: EmailVerificationPage,
  },
];
