import i18n from '@/plugins/i18n';

export const ONBOARDING_2_STATE_MODULE = 'onboarding-2';

export const ONBOARDING_2_ROUTES = {
  HOME: 'onboarding2',
  SIGN_UP: 'onboarding-signup',
  SIGN_UP_SELF_SERVE: 'self-serve-capital-signup',
  SIGN_UP_UNIFIED: 'onboarding-unified',
  CONNECT_SALES: 'onboarding-connect-sales',
  BUSINESS: 'onboarding-business',
  BUSINESS_DETAILS: 'onboarding-business-details',
  BUSINESS_REGISTERED_ADDRESS: 'onboarding-business-registered-address',
  OWNERS: 'onboarding-owners',
  OWNERS_STEP_ONE: 'onboarding-owners-step-one',
  OWNERS_STEP_TWO: 'onboarding-owners-step-two',
  OWNERS_LIST: 'onboarding-owners-list',
  BUSINESS_INCORP_DOCS: 'onboarding-business-incorporation-docs',
  ACCOUNTING_DOCS: 'onboarding-accounting-docs',
  BUSINESS_UPLOAD_EIN: 'onboarding-business-upload-ein',
  SELECT_PRODUCT: 'onboarding-select-product',
  WAITING_ROOM: 'onboarding-waiting-room',
  OFFER_SELECTION: 'onboarding-offer-selection',
  CONNECT_ACCOUNTS: 'onboarding-connect-accounts',
  CONNECT_MARKETING: 'onboarding-connect-marketing',
  CONNECT_ACCOUNTING: 'onboarding-connect-accounting',
  CONNECT_BANKING: 'onboarding-connect-banking',
  BANK_FAILED_UPLOAD_DOCS: 'onboarding-bank-failed-upload-docs',
  BANK_LINK_CONFIRMATION: 'onboarding-bank-link-confirmation',
  NAVIGATION_PREVIEW: 'onboarding-navigation-preview',
  MISSED_STEPS: 'onboarding-missed-steps',
  SIGN_CONTRACTS: 'onboarding-sign-contracts',
  SIGN_ADVANCE_CONTRACT: 'onboarding-sign-advance-contract',
  SIGN_ACH_CONTRACT: 'onboarding-sign-ach-contract',
  APPLICATION_CONFIRMATION: 'onboarding-application-confirmation',
  BASIC_USER_DETAILS: 'onboarding-basic-user-details',
  BASIC_BUSINESS_DETAILS: 'onboarding-basic-business-details',
};

export const ONBOARDING_STAGE = {
  PRE_OFFER: 'pre-offer',
  POST_OFFER: 'post-offer',
};

export const ONBOARDING_2_POST_OFFER_STEP_GROUPS = {
  CONNECT_ACCOUNTS: 'connect-accounts',
  BUSINESS_DETAILS: 'business-details',
  SIGN_CONTRACTS: 'sign-contracts',
  OTHER: 'other',
};

export const ONBOARDING_GETTERS = {
  [ONBOARDING_2_STATE_MODULE]: {
    steps: 'onboardingSteps',
    featureFlags: 'onboardingFeatureFlags',
    offersPage: 'onboardingOffersPage',
    applicationProgress: 'applicationProgress',
    userIsInDiligence: 'userIsInDiligence',
    stepGroups: 'stepGroups',
    stepsUpdated: 'stepsUpdated',
    isRevisitingMissedStep: 'isRevisitingMissedStep',
    isSelectOfferStepComplete: 'isSelectOfferStepComplete',
    firstIncompleteStep: 'firstIncompleteStep',
    areRequiredBankingStepsIncomplete: 'isRequiredBankingStepsIncomplete',
    navigationPreviewStep: 'navigationPreviewStep',
    applicationConfirmationStep: 'applicationConfirmationStep',
    missingSkippablePostOfferSteps: 'missingSkippablePostOfferSteps',
    postOfferStepNames: 'postOfferStepNames',
    completedPostOfferSteps: 'completedPostOfferSteps',
    previousStepName: 'previousStepName',
    skippablePostOfferSteps: 'skippablePostOfferSteps',
    conditionalPostOfferSteps: 'conditionalPostOfferSteps',
    bankingPostOfferSteps: 'bankingPostOfferSteps',
    skippablePostOfferStepNames: 'skippablePostOfferStepNames',
    nextRequiredAndIncompletePostOfferStep:
      'nextRequiredAndIncompletePostOfferStep',
    nextStepForMcaContractPage: 'nextStepForMcaContractPage',
    isOwnersFormComplete: 'isOwnersFormComplete',
    isPostOfferApplicationComplete: 'isPostOfferApplicationComplete',
  },
};

const COMMON_ACTIONS = {
  UPDATE_STATE_MODULE: 'UPDATE_ONBOARDING_STATE_MODULE',
  SET_STEPS: 'SET_ONBOARDING_STEPS',
  UPDATE_ALL_STEPS: 'UPDATE_ALL_ONBOARDING_STEPS',
  UPDATE_STEP: 'UPDATE_ONBOARDING_STEP',
  UPDATE_SKIPPABLE_STATUS_FOR_CONDITIONAL_STEPS:
    'UPDATE_SKIPPABLE_STATUS_FOR_CONDITIONAL_ONBOARDING_STEPS',
  SET_EXPERIMENT_NAME: 'SET_EXPERIMENT_NAME',
  UPDATE_FEATURE_FLAG: 'UPDATE_FEATURE_FLAG',
  SET_OFFERS_PAGE: 'SET_ONBOARDING_OFFERS_PAGE',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  UPDATE_STEP_GROUPS: 'UPDATE_STEP_GROUPS',
  SET_STEPS_SETUP_UPDATED: 'SET_STEPS_SETUP_UPDATED',
};

export const ONBOARDING_ACTIONS = {
  [ONBOARDING_2_STATE_MODULE]: {
    ...COMMON_ACTIONS,
    UPDATE_ALL_BANKING_STEPS: 'UPDATE_ALL_ONBOARDING_BANKING_STEPS',
  },
};

const ONBOARDING_STEPS = {
  'onboarding-2': {
    [ONBOARDING_2_ROUTES.SIGN_UP_UNIFIED]: {
      name: ONBOARDING_2_ROUTES.SIGN_UP_UNIFIED,
      nextRoute: { name: ONBOARDING_2_ROUTES.BASIC_USER_DETAILS },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.SIGN_UP]: {
      name: ONBOARDING_2_ROUTES.SIGN_UP,
      nextRoute: { name: ONBOARDING_2_ROUTES.BASIC_USER_DETAILS },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.BASIC_USER_DETAILS]: {
      name: ONBOARDING_2_ROUTES.BASIC_USER_DETAILS,
      nextRoute: { name: ONBOARDING_2_ROUTES.BASIC_BUSINESS_DETAILS },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.BASIC_BUSINESS_DETAILS]: {
      name: ONBOARDING_2_ROUTES.BASIC_BUSINESS_DETAILS,
      nextRoute: { name: ONBOARDING_2_ROUTES.CONNECT_SALES },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.CONNECT_SALES]: {
      validationMsg: () =>
        i18n.t('data.onboarding.steps.connectSales.validationMessage'),
      name: ONBOARDING_2_ROUTES.CONNECT_SALES,
      nextRoute: { name: ONBOARDING_2_ROUTES.WAITING_ROOM },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.SELECT_PRODUCT]: {
      name: ONBOARDING_2_ROUTES.SELECT_PRODUCT,
      nextRoute: { name: ONBOARDING_2_ROUTES.WAITING_ROOM },
      complete: false,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.WAITING_ROOM]: {
      name: ONBOARDING_2_ROUTES.WAITING_ROOM,
      complete: true,
      stage: ONBOARDING_STAGE.PRE_OFFER,
    },
    [ONBOARDING_2_ROUTES.OFFER_SELECTION]: {
      name: ONBOARDING_2_ROUTES.OFFER_SELECTION,
      nextRoute: { name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.CONNECT_MARKETING]: {
      name: ONBOARDING_2_ROUTES.CONNECT_MARKETING,
      percentComplete: 25,
      title: () => i18n.t('data.onboarding.steps.connectMarketing.title'),
      alternateTitle: () =>
        i18n.t('data.onboarding.steps.connectMarketing.alternateTitle'),
      subtitle: () => i18n.t('data.onboarding.steps.connectMarketing.subtitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS,
      skippable: true,
      wasSkipped: false,
    },
    [ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING]: {
      name: ONBOARDING_2_ROUTES.CONNECT_ACCOUNTING,
      percentComplete: 50,
      title: () => i18n.t('data.onboarding.steps.connectAccounting.title'),
      subtitle: () =>
        i18n.t('data.onboarding.steps.connectAccounting.subtitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.CONNECT_BANKING },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS,
      skippable: true,
      wasSkipped: false,
    },
    [ONBOARDING_2_ROUTES.CONNECT_BANKING]: {
      name: ONBOARDING_2_ROUTES.CONNECT_BANKING,
      percentComplete: 75,
      title: () => i18n.t('data.onboarding.steps.connectBanking.title'),
      alternateTitle: () =>
        i18n.t('data.onboarding.steps.connectBanking.alternativeTitle'),
      subtitle: () => i18n.t('data.onboarding.steps.connectBanking.subtitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION },
      complete: false,
      skippable: true,
      ctaText: i18n.t('data.onboarding.steps.bankLinkConfirmation.ctaText'),
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS,
      wasSkipped: false,
      type: 'banking',
    },
    [ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION]: {
      name: ONBOARDING_2_ROUTES.BANK_LINK_CONFIRMATION,
      percentComplete: 75,
      title: () => i18n.t('data.onboarding.steps.bankLinkConfirmation.title'),
      alternateTitle: () =>
        i18n.t('data.onboarding.steps.bankLinkConfirmation.alternativeTitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS },
      skippable: false,
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS,
      wasSkipped: false,
      conditional: true,
      type: 'banking',
    },
    [ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS]: {
      name: ONBOARDING_2_ROUTES.BANK_FAILED_UPLOAD_DOCS,
      percentComplete: 75,
      nextRoute: { name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW },
      previousRoute: { name: ONBOARDING_2_ROUTES.CONNECT_BANKING },
      alternateTitle: () =>
        i18n.t('data.onboarding.steps.bankFailedUploadDocs.alternateTitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS,
      skippable: true,
      wasSkipped: false,
      type: 'banking',
    },
    [ONBOARDING_2_ROUTES.BUSINESS_DETAILS]: {
      name: ONBOARDING_2_ROUTES.BUSINESS_DETAILS,
      nextRoute: { name: ONBOARDING_2_ROUTES.BUSINESS_REGISTERED_ADDRESS },
      percentComplete: 25,
      title: () => i18n.t('data.onboarding.steps.businessDetails.title'),
      subtitle: () => i18n.t('data.onboarding.steps.businessDetails.subtitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.BUSINESS_REGISTERED_ADDRESS]: {
      name: ONBOARDING_2_ROUTES.BUSINESS_REGISTERED_ADDRESS,
      nextRoute: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      percentComplete: 25,
      title: () =>
        i18n.t('data.onboarding.steps.businessRegisteredAddress.title'),
      subtitle: () =>
        i18n.t('data.onboarding.steps.businessRegisteredAddress.subtitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.OWNERS]: {
      name: ONBOARDING_2_ROUTES.OWNERS,
      nextRoute: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      complete: true,
      skippable: true,
      firstStepOfForm: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      formCompleteStep: { name: ONBOARDING_2_ROUTES.OWNERS_LIST },
    },
    [ONBOARDING_2_ROUTES.OWNERS_STEP_ONE]: {
      name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE,
      percentComplete: 50,
      title: () => i18n.t('data.onboarding.steps.ownersStepOne.title'),
      subtitle: () => i18n.t('data.onboarding.steps.ownersStepOne.subtitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_TWO },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: false,
      firstStepOfForm: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      formCompleteStep: { name: ONBOARDING_2_ROUTES.OWNERS_LIST },
    },
    [ONBOARDING_2_ROUTES.OWNERS_STEP_TWO]: {
      name: ONBOARDING_2_ROUTES.OWNERS_STEP_TWO,
      percentComplete: 50,
      nextRoute: { name: ONBOARDING_2_ROUTES.OWNERS_LIST },
      formCompleteStep: { name: ONBOARDING_2_ROUTES.OWNERS_LIST },
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      complete: true,
      stage: ONBOARDING_STAGE.POST_OFFER,
      firstStepOfForm: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.OWNERS_LIST]: {
      name: ONBOARDING_2_ROUTES.OWNERS_LIST,
      percentComplete: 50,
      title: () => i18n.t('data.onboarding.steps.ownersStepOne.title'),
      subtitle: () => '',
      ctaText: i18n.t('common.buttons.next'),
      nextRoute: { name: ONBOARDING_2_ROUTES.BUSINESS_INCORP_DOCS },
      redirectRoute: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      stage: ONBOARDING_STAGE.POST_OFFER,
      complete: false,
      firstStepOfForm: { name: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE },
      formCompleteStep: { name: ONBOARDING_2_ROUTES.OWNERS_LIST },
      skippable: false,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
    },
    [ONBOARDING_2_ROUTES.BUSINESS_INCORP_DOCS]: {
      name: ONBOARDING_2_ROUTES.BUSINESS_INCORP_DOCS,
      nextRoute: { name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW },
      nextRouteForDEorATorCHIncorporatedBusiness: {
        name: ONBOARDING_2_ROUTES.ACCOUNTING_DOCS,
      },
      nextRouteForUSIncorporatedBusiness: {
        name: ONBOARDING_2_ROUTES.BUSINESS_UPLOAD_EIN,
      },
      percentComplete: 75,
      title: () => i18n.t('data.onboarding.steps.businessIncorpDocs.title'),
      subtitle: () =>
        i18n.t('data.onboarding.steps.businessIncorpDocs.subtitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.ACCOUNTING_DOCS]: {
      name: ONBOARDING_2_ROUTES.ACCOUNTING_DOCS,
      nextRoute: { name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW },
      percentComplete: 90,
      title: () => i18n.t('data.onboarding.steps.accountingDocs.title'),
      subtitle: () => i18n.t('data.onboarding.steps.accountingDocs.subtitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: false,
      conditional: true,
    },
    [ONBOARDING_2_ROUTES.BUSINESS_UPLOAD_EIN]: {
      name: ONBOARDING_2_ROUTES.BUSINESS_UPLOAD_EIN,
      nextRoute: { name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW },
      percentComplete: 90,
      title: () => i18n.t('data.onboarding.steps.businessUploadEin.title'),
      subtitle: () =>
        i18n.t('data.onboarding.steps.businessUploadEin.subtitle'),
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS,
      skippable: true, // only skippable for non-US incorporated businesses
      conditional: true,
    },
    [ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW]: {
      name: ONBOARDING_2_ROUTES.NAVIGATION_PREVIEW,
      complete: true,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.OTHER,
    },
    [ONBOARDING_2_ROUTES.MISSED_STEPS]: {
      name: ONBOARDING_2_ROUTES.MISSED_STEPS,
      percentComplete: 75,
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.SIGN_CONTRACTS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.SIGN_ADVANCE_CONTRACT]: {
      name: ONBOARDING_2_ROUTES.SIGN_ADVANCE_CONTRACT,
      percentComplete: 50,
      title: () => i18n.t('data.onboarding.steps.reviewAndSignMca.title'),
      subtitle: () => '',
      postContractSignedTitle: () =>
        i18n.t(
          'data.onboarding.steps.reviewAndSignMca.postContractSignedTitle',
        ),
      nextRoute: { name: ONBOARDING_2_ROUTES.SIGN_ACH_CONTRACT },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.SIGN_CONTRACTS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.SIGN_ACH_CONTRACT]: {
      name: ONBOARDING_2_ROUTES.SIGN_ACH_CONTRACT,
      percentComplete: 75,
      title: () => i18n.t('data.onboarding.steps.reviewAndSignAch.title'),
      subtitle: () => i18n.t('data.onboarding.steps.reviewAndSignAch.subtitle'),
      nextRoute: { name: ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION },
      complete: false,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.SIGN_CONTRACTS,
      skippable: false,
    },
    [ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION]: {
      name: ONBOARDING_2_ROUTES.APPLICATION_CONFIRMATION,
      complete: true,
      stage: ONBOARDING_STAGE.POST_OFFER,
      group: ONBOARDING_2_POST_OFFER_STEP_GROUPS.SIGN_CONTRACTS,
    },
  },
};

export const ONBOARDING_STEP_GROUPS = {
  [ONBOARDING_2_POST_OFFER_STEP_GROUPS.CONNECT_ACCOUNTS]: {
    title: () =>
      i18n.t('data.onboarding.onboardingStepGroups.connectAccounts.title'),
    visibleStepsText: () => [],
    active: false,
  },
  [ONBOARDING_2_POST_OFFER_STEP_GROUPS.BUSINESS_DETAILS]: {
    title: () => i18n.t('data.onboarding.steps.businessDetails.title'),
    visibleStepsText: () => [
      i18n.t(
        'data.onboarding.onboardingStepGroups.businessDetails.registeredAddress',
      ),
      i18n.t(
        'data.onboarding.onboardingStepGroups.businessDetails.businessOwnerProfiles',
      ),
      i18n.t(
        'data.onboarding.onboardingStepGroups.businessDetails.incorporationDocuments',
      ),
    ],
    active: false,
    subGroups: {
      owners: {
        parentRoute: ONBOARDING_2_ROUTES.OWNERS_STEP_ONE,
      },
    },
  },
  [ONBOARDING_2_POST_OFFER_STEP_GROUPS.SIGN_CONTRACTS]: {
    title: () =>
      i18n.t('data.onboarding.onboardingStepGroups.signContracts.title'),
    visibleStepsText: () => [
      i18n.t('data.onboarding.onboardingStepGroups.signContracts.signContract'),
      i18n.t('data.onboarding.onboardingStepGroups.signContracts.signDebit'),
    ],
    active: false,
  },
};

export const ONBOARDING_FEATURE_FLAG = {
  'clearco-unified-signup': 'clearcoUnifiedSignup',
};

const ONBOARDING_EXPERIMENT_NAME = {
  'onboarding-2': 'ss_2.0_pre_offer',
};

export const ONBOARDING_CONFIG = {
  'onboarding-2': {
    steps: ONBOARDING_STEPS['onboarding-2'],
    experimentName: ONBOARDING_EXPERIMENT_NAME['onboarding-2'],
  },
};
// TODO: Believe this can be removed.
export const NO_OFFERS_AVAILABLE_OPTIONS = () => [];

export const NO_OFFERS_AVAILABLE_CLEARPAY_OPTIONS = () => [
  {
    title: i18n.t('data.onboarding.noOffersAvailableClearpayOptions[0].title'),
    message: i18n.t(
      'data.onboarding.noOffersAvailableClearpayOptions[0].message',
    ),
    iconPath: require('@/assets/logos/globe-logo.svg'),
  },
  {
    title: i18n.t('data.onboarding.noOffersAvailableClearpayOptions[1].title'),
    message: i18n.t(
      'data.onboarding.noOffersAvailableClearpayOptions[1].message',
    ),
    iconPath: require('@/assets/logos/card-logo.svg'),
  },
  {
    title: i18n.t('data.onboarding.noOffersAvailableClearpayOptions[2].title'),
    message: i18n.t(
      'data.onboarding.noOffersAvailableClearpayOptions[2].message',
    ),
    iconPath: require('@/assets/logos/building-logo.svg'),
  },
];

export const GET_ITEMS_READY_OPTIONS = () => [
  {
    text: i18n.t('data.onboarding.getItemsReadyOptions[0].text'),
    tooltip: i18n.t('data.onboarding.getItemsReadyOptions[0].tooltip'),
    icon: require('@/assets/icons/photo-id.svg'),
  },
  {
    text: i18n.t('data.onboarding.getItemsReadyOptions[1].text'),
    tooltip: i18n.t('data.onboarding.getItemsReadyOptions[1].tooltip'),
    icon: require('@/assets/icons/business-registration.svg'),
  },
  {
    text: i18n.t('data.onboarding.getItemsReadyOptions[2].text'),
    tooltip: i18n.t('data.onboarding.getItemsReadyOptions[2].tooltip'),
    icon: require('@/assets/icons/ein-verification.svg'),
  },
];

export const STATE_TO_TEXT_MAPPING = {
  REQUEST_FUNDING: () =>
    i18n.t('data.onboarding.stateToTextMapping.requestFunding'),
  NO_OFFERS: () => i18n.t('data.onboarding.stateToTextMapping.noOffers'),
  COMPLETE_APPLICATION: () =>
    i18n.t('data.onboarding.stateToTextMapping.completeApplication'),
  DILIGENCE: () => i18n.t('data.onboarding.stateToTextMapping.diligence'),
};

export const INELIGIBLE_TEXT = {
  title: i18n.t('data.onboarding.ineligibleText.title'),
  subtitle: i18n.t('data.onboarding.ineligibleText.subtitle'),
};

export const INBOUND_INELIGIBLE_TEXT = {
  ...INELIGIBLE_TEXT,
  subtitle: `<strong>${i18n.t(
    'data.onboarding.inboundIneligibleText.youCanStartBuilding',
  )}</strong> ${i18n.t(
    'data.onboarding.inboundIneligibleText.toBecomeEligibleForFunding',
  )}`,
};

export const CONNECT_ACCOUNTS_PROPS = {
  NOT_ELIGIBLE_FOR_OFFERS: {
    title: () =>
      i18n.t('data.onboarding.connectAccountsProps.notEligibleForOffers.title'),
    text: () =>
      i18n.t('data.onboarding.connectAccountsProps.notEligibleForOffers.text'),
  },
  DEFAULT: {
    title: () => i18n.t('data.onboarding.connectAccountsProps.default.title'),
    text: () => i18n.t('data.onboarding.connectAccountsProps.default.text'),
  },
};

export const INTEGRATION_TYPES = {
  SALES: 'sales',
  MARKETING: 'marketing',
  ACCOUNTING: 'accounting',
  BANK: 'bank',
};

export const APPLICATION_CONFIRMATION_TEXT = {
  REVIEW_FUNDING_REQUESTS: i18n.t(
    'data.onboarding.applicationConfirmationText.reviewFundingRequests',
  ),
  MAY_GET_BACK_TO_YOU: i18n.t(
    'data.onboarding.applicationConfirmationText.mayGetBackToYou',
  ),
  EXCITED_TO_WORK_WITH_YOU: i18n.t(
    'data.onboarding.applicationConfirmationText.excitedToWorkWithYou',
  ),
  WILL_SEND_ACH: i18n.t(
    'data.onboarding.applicationConfirmationText.willSendAch',
  ),
};

export const APPLICATION_CONFIRMATION_PARAGRAPHS = {
  DEFAULT: () => [
    APPLICATION_CONFIRMATION_TEXT.REVIEW_FUNDING_REQUESTS,
    APPLICATION_CONFIRMATION_TEXT.MAY_GET_BACK_TO_YOU,
    APPLICATION_CONFIRMATION_TEXT.EXCITED_TO_WORK_WITH_YOU,
  ],
  ACH_NOT_SIGNED: () => [
    APPLICATION_CONFIRMATION_TEXT.REVIEW_FUNDING_REQUESTS,
    APPLICATION_CONFIRMATION_TEXT.WILL_SEND_ACH,
    APPLICATION_CONFIRMATION_TEXT.EXCITED_TO_WORK_WITH_YOU,
  ],
};

export const WAITING_ROOM_PARAGRAPHS = () => [
  i18n.t('data.onboarding.waitingRoomParagraphs.connectingToStore'),
  i18n.t('data.onboarding.waitingRoomParagraphs.synthesizeData'),
  i18n.t('data.onboarding.waitingRoomParagraphs.scenarioAnalysis'),
  i18n.t('data.onboarding.waitingRoomParagraphs.modelling'),
];

export const CLEARPAY_TYPEFORM_URL =
  'https://clearbanc-capital.typeform.com/to/OPvRYdzh';
