<template>
  <todo-section
    ref="container"
    :class="$style['vendor-details']"
    :title="vendor.label"
    :pending="vendor.approvalStatus === 'pending'"
    :complete="!!vendor.vendorApprovedAt"
    :error="!!vendor.vendorAdminNotes"
    status-icon-size="small"
    theme="flat-white"
    bottom-border
    @open-toggler="getFileUpload(vendor.invoiceUploadId)"
  >
    <template #title>
      <div :class="$style['title-container']">
        <div>
          {{ vendor.label }}
          <span class="small">- {{ vendor.approvalStatus }}</span>
        </div>
        <DSButton
          v-if="!vendor.vendorApprovedAt"
          :label="$t('common.buttons.edit')"
          class="p-button-link"
          :class="$style['edit-button']"
          @click="$emit('openVendorModal', vendor.id)"
        />
      </div>
    </template>
    <form-row>
      <form-input
        :label="$t('account.pages.subPageVendorList.accountInfo')"
        type="container"
      >
        <div>{{ vendor.nameOnAccount }}</div>
        <div>
          {{ $t('common.bankInfo.account') }}: {{ vendor.accountNumber }}
        </div>
        <div v-if="vendor.routingNumber">
          {{ $t('common.bankInfo.ach') }}: {{ vendor.routingNumber }}
        </div>
        <div v-if="vendor.wireRoutingNumber">
          {{ $t('common.bankInfo.wireNumber') }}: {{ vendor.wireRoutingNumber }}
        </div>
        <div v-if="vendor.swiftCode">
          {{ $t('common.bankInfo.swiftCode') }}: {{ vendor.swiftCode }}
        </div>
        <div v-if="vendor.iban">
          {{ $t('common.bankInfo.iban') }}: {{ vendor.iban }}
        </div>
        <div v-if="vendor.sortCode">
          {{ $t('common.bankInfo.sortCode') }}: {{ vendor.sortCode }}
        </div>
        <div v-if="vendor.bsbCode">
          {{ $t('common.bankInfo.bsbCode') }}: {{ vendor.bsbCode }}
        </div>
        <div v-if="vendor.bankCode">
          {{ $t('common.bankInfo.bankCode') }}: {{ vendor.bankCode }}
        </div>
        <div v-if="vendor.branchCode">
          {{ $t('common.bankInfo.branchCode') }}: {{ vendor.branchCode }}
        </div>
        <div v-if="vendor.clabe">
          {{ $t('common.bankInfo.clabe') }}: {{ vendor.clabe }}
        </div>
        <div v-if="vendor.cnaps">
          {{ $t('common.bankInfo.cnaps') }}: {{ vendor.cnaps }}
        </div>
        <div v-if="vendor.ifsc">
          {{ $t('common.bankInfo.ifsc') }}: {{ vendor.ifsc }}
        </div>
      </form-input>
      <form-input
        v-if="vendor.beneficiaryAddress"
        :label="$t('vendorsPage.vendorAddress')"
        type="container"
      >
        <div>{{ vendor.bankName }}</div>
        <div>{{ vendor.beneficiaryAddress.line1 }}</div>
        <div>
          {{ vendor.beneficiaryAddress.city }},
          {{ vendor.beneficiaryAddress.state }}
          {{ vendor.beneficiaryAddress.postalCode }}
        </div>
        <div>{{ vendor.beneficiaryAddress.country }}</div>
      </form-input>
      <form-input
        v-if="vendor.bankAddress"
        :label="$t('common.bankInfo.bankAddress')"
        type="container"
      >
        <div>{{ vendor.bankName }}</div>
        <div>{{ vendor.bankAddress.line1 }}</div>
        <div>{{ vendor.bankAddress.line2 }}</div>
        <div>
          {{ vendor.bankAddress.city }}, {{ vendor.bankAddress.state }}
          {{ vendor.bankAddress.postalCode }}
        </div>
        <div>{{ vendor.bankAddress.country }}</div>
      </form-input>
    </form-row>
    <div
      v-if="vendor.vendorAdminNotes"
      :class="$style['vendor-details__notes']"
    >
      <p>
        <span class="bold"
          >{{ $t('vendorsPage.vendorDetails.rejectionDetails') }}:</span
        >
        <br />
        {{ vendor.vendorAdminNotes }}
      </p>
      <Onboarding2Cta
        v-if="rejectedEditable"
        :text="$t('common.buttons.edit')"
        @click="$emit('openVendorModal', vendor.id)"
      />
      <div v-if="rejectedEditable">
        <div v-if="matchedUpload" :class="$style['action-link-container']">
          <a :href="matchedUpload.downloadUrl" target="_blank">{{
            $t('vendorsPage.vendorInvoice.invoice')
          }}</a>
        </div>
        <Onboarding2Cta
          :text="$t('vendorsPage.vendorInvoice.update')"
          @click="updateInvoice(vendor.id, vendor.invoiceUploadId)"
        />
      </div>
    </div>
    <div v-else-if="!vendor.vendorApprovedAt && matchedUpload">
      <p class="bold">{{ $t('vendorsPage.vendorInvoice.verifyingMsg') }}</p>
      <div>
        <div :class="$style['action-link-container']">
          <a :href="matchedUpload.downloadUrl" target="_blank">{{
            $t('vendorsPage.vendorInvoice.invoice')
          }}</a>
        </div>
        <Onboarding2Cta
          :text="$t('vendorsPage.vendorInvoice.update')"
          @click="updateInvoice(vendor.id, vendor.invoiceUploadId)"
        />
      </div>
    </div>
    <div v-else-if="!!vendor.vendorApprovedAt">
      <strong>{{ $t('vendorsPage.vendorDetails.payments') }}</strong>
      <p v-if="!filteredTransactions.length">
        {{ $t('vendorsPage.vendorDetails.noPayments') }}
      </p>
      <div
        v-for="transaction in filteredTransactions"
        v-else
        :key="transaction.id"
        :class="[$style['vendor-details__payment']]"
      >
        <div :class="$style['vendor-details__payment-container']">
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('common.amount') }}:
            </div>
            <div>
              {{
                determineTransactionAmount(
                  transaction.currencyCode,
                  transaction.exchangeDetails,
                  transaction.amountCents,
                )
              }}
            </div>
            <div>
              {{
                determineExchangeAmount(
                  transaction.currencyCode,
                  transaction.exchangeDetails,
                )
              }}
            </div>
          </div>
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('common.created') }}:
            </div>
            {{ $filters.date(transaction.createdAt) }}
          </div>
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('vendorsPage.labels.paymentDate') }}:
            </div>
            {{ transaction.dueDate }}
          </div>
          <div v-if="shouldShowDeliveryDate(transaction)" :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('vendorsPage.labels.deliveryDate') }}:
            </div>
            {{ transaction.paymentDeliveryDate }}
          </div>
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('vendorsPage.labels.additionalInfo') }}:
            </div>
            {{ transaction.paymentIdentifier }}
          </div>
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('common.status') }}:
            </div>
            <badge
              :text="getBillStatusLabel(transaction.status, 'transaction')"
              :theme="getBillStatusTheme(transaction.status)"
            />
          </div>
          <div :class="$style.flex">
            <div :class="$style['vendor-details__label']">
              {{ $t('vendorsPage.labels.actions') }}:
            </div>
            <div v-if="canRequestPaymentConfirmation(transaction)">
              <Onboarding2Cta
                :loading="isPaymentConfirmationLoading(transaction)"
                :text="
                  $t('account.pages.subPageVendorList.requestConfirmation')
                "
                @click="requestPaymentConfirmation(transaction)"
              />
            </div>
            <div v-else :class="$style['match-button-width']">
              {{ $t('common.notApplicable') }}
            </div>
          </div>
        </div>
        <div
          v-if="
            transaction.status === 'failed' && !!transaction.customDescription
          "
          :class="$style['vendor-details__description']"
        >
          <strong>{{ $t('vendorsPage.labels.failureReason') }}:</strong>
          &nbsp;{{ transaction.customDescription }}
        </div>
      </div>
      <div v-if="isPaymentFieldOpen">
        <form-row>
          <form-input
            v-model="paymentPayload.advance"
            type="dropdown"
            :label="$t('vendorsPage.labels.withAdvance')"
            :options="displayAdvancesToPayVendorWith()"
          />
        </form-row>
        <form-row>
          <form-input
            v-model="paymentPayload.currencyCode"
            type="dropdown"
            :label="$t('vendorsPage.labels.paymentCurrency')"
            :options="currencyDropdownOptions"
            auto-select="auto-select"
            disabled="disabled"
          />
          <form-input
            type="money"
            :label="$t('common.amount')"
            :placeholder="currencySymbol"
            :model-value="amountToDisplay"
            :instructions="
              !convertedAmountCents
                ? null
                : `${advanceCurrencyCode} ${convertedAmountCents / 100}`
            "
            required="required"
            @input="displayDollars"
          />
          <div class="form-input">
            <label class="form-input-label" for="due-date-picker">
              {{ $t('vendorsPage.labels.paymentDate') }}
            </label>
            <div>
              <DateSelector
                id="due-date-picker"
                v-model="paymentPayload.dueDate"
                data-cy="due-date-picker"
                :min-date="disabledDates.min"
                :max-date="disabledDates.max"
                :disabled-dates="{ weekdays: [1, 7] }"
                :class="$style['datepicker-styles']"
                required
                show-calendar-from-icon-click
                :locale="gettingLocaleForDatepicker()"
              />
            </div>
          </div>
          <div v-if="hasPurposeCode" :class="$style['break']"></div>
          <form-input
            v-model="paymentPayload.paymentIdentifier"
            :label="$t('vendorsPage.labels.memoToVendor')"
            required="required"
            :max-length="50"
            :instructions="
              $t('formInstructions.charRemaining', { char: memoCharCount })
            "
          />
          <form-input
            v-if="hasPurposeCode"
            v-model="paymentPayload.purposeCode"
            required
            :placeholder="$t('vendorsPage.labels.selectPurposePayment')"
            type="dropdown"
            :label="$t('vendorsPage.labels.purposePayment')"
            :options="purposeCodeDropdownOptions"
          />
        </form-row>
        <form-row no-inputs="no-inputs">
          <error-message :request-status="createVendorPaymentRequest" />
        </form-row>
        <div :style="{ 'text-align': 'center' }">
          <Onboarding2Cta
            data-cy="confirm-pay-btn"
            theme="green"
            :loading="createVendorPaymentRequest.isPending"
            :disabled="
              createVendorPaymentRequest.isPending ||
              !paymentPayload.dueDate ||
              !paymentPayload.amountCents ||
              !paymentPayload.advance ||
              !paymentPayload.paymentIdentifier
            "
            :text="$t('common.buttons.confirm')"
            @click="showTermsAndConditions"
          />
          <Onboarding2Cta
            data-cy="cancel-pay-btn"
            theme="red"
            :text="$t('common.buttons.cancel')"
            @click="hidePaymentField"
          />
        </div>
      </div>
      <Onboarding2Cta
        v-if="!isPaymentFieldOpen"
        data-cy="add-pay-btn"
        :text="$t('vendorsPage.buttons.payBill')"
        @click="addPayment"
      />
    </div>
    <popup
      ref="acceptTermsAndConditions"
      :title="$t('account.pages.subPageVendorList.pleaseReview')"
    >
      <form-row no-inputs="no-inputs">
        <p>{{ $t('vendorsPage.vendorDetails.termsConditions') }}</p>
      </form-row>
      <form-row>
        <Onboarding2Cta
          data-cy="agree-txn-btn"
          theme="green"
          :disabled="createVendorPaymentRequest.isPending"
          :text="$t('common.termsConditions.agree')"
          @click="createTransaction"
        />
        <Onboarding2Cta
          theme="red"
          :text="$t('common.buttons.cancel')"
          @click="hidePaymentField"
        />
      </form-row>
    </popup>
    <popup
      ref="paymentConfirmationErrorPopup"
      :title="$t('vendorsPage.vendorDetails.requestConfirmation')"
      :class="$style['payment-confirmation-error']"
    >
      <p>
        {{
          getPaymentConfirmationRequest.error
            ? getPaymentConfirmationRequest.error.errorCode
              ? $t(
                  `backendError.${this.getPaymentConfirmationRequest.error.errorCode}`,
                  this.getPaymentConfirmationRequest.error.details,
                )
              : getPaymentConfirmationRequest.error.message
            : $t('vendorsPage.vendorDetails.confirmationError')
        }}
      </p>
    </popup>
    <popup
      ref="clearPayPopup"
      :class="$style['clearpay-popup']"
      :title="
        $t('vendorsPage.vendorDetails.vendorPayment', { vendor: vendor.label })
      "
    >
      <div :class="$style['clearpay-popup-container']">
        <h2 class="modal-title">{{ $t('vendorsPage.billPayPopup.howPay') }}</h2>
        <form-row no-inputs>
          <button @click="payWithAdvance()">
            <h4>
              <b>{{ $t('vendorsPage.billPayPopup.balance') }}</b
              ><span>&emsp;{{ $t('vendorsPage.billPayPopup.free') }}</span>
            </h4>
            <p>{{ $t('vendorsPage.billPayPopup.payWBalance') }}</p>
          </button>
        </form-row>
      </div>
    </popup>
  </todo-section>
</template>

<script>
import _ from 'lodash';
import DateSelector from '@/components/DateSelector';
import * as dateFns from 'date-fns';
import { gettingLocaleForDatepickerLib } from '@/configs/i18n-constants';
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { validateChildren } from '@/composables/validation';
import { getBillStatusLabel, getBillStatusTheme } from '@/utils/payments';
import {
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
} from '@/data/currency-code-with-names';
import { PURPOSE_CODES } from '@/data/payment-purpose-codes';
import { formatMoney, dollarsToCents } from '@/utils/currency';
import { ADVANCE_SUBTYPES } from '@/data/advance-subtypes';
import { PAYMENT_TYPE } from '@/data/payment-type';
import { TransactionStatus, TransactionPlatform } from '@/data/payments';
import {
  ENTITY_TYPE,
  BANK_ACCOUNT_DOC_TYPE,
} from '@clearbanc/data-common-types';

import { Badge } from '@clearbanc/design-components/src/components';
import DSButton from '@clearbanc/clear-components/button';
import Onboarding2Cta from '@/components/buttons/ButtonOnboarding';

export default {
  components: {
    Badge,
    DSButton,
    DateSelector,
    Onboarding2Cta,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      isPaymentFieldOpen: false,
      paymentPayload: {
        isOpen: false,
        amountCents: '',
        dueDate: '',
        paymentIdentifier: '',
        purposeCode: '',
        currencyCode: this.vendor.currencyCode,
      },
      amountToDisplay: '',
      disabledDates: {
        min: dateFns.addBusinessDays(new Date(), 2),
        max: dateFns.addYears(new Date(), 1), // Up to 1 year from now
      },
      openDate: dateFns.addBusinessDays(new Date(), 1),
      // The id of a transaction for which a payment confirmation was requested.
      paymentConfirmationTransactionId: null,
    };
  },
  computed: {
    ...mapGetters([
      'vendorTransactions',
      'advances',
      'business',
      'activeAdvances',
      'convertedAmountCents',
      'totalCreditRemainingCents',
      'totalInventoryCreditRemainingCents',
      'totalVenturesCreditRemainingCents',
      'selectedLanguage',
      'isDiligenceServiceReadDataEnabled',
    ]),
    ...mapRequestStatuses({
      updateVendorRequest: 'UPDATE_VENDOR_ACCOUNT',
      createVendorPaymentRequest() {
        return ['CREATE_VENDOR_PAYMENT', this.vendor.id];
      },
      getPaymentConfirmationRequest: 'GET_PAYMENT_CONFIRMATION',
    }),
    memoCharCount() {
      return 50 - this.paymentPayload.paymentIdentifier.length;
    },
    advanceCurrencyCode() {
      const { currencyCode } = this.activeAdvances[0] || {};

      return currencyCode || 'USD';
    },
    filteredTransactions() {
      return this.vendorTransactions.filter(
        (txn) => txn.bankAccountId === this.vendor.id,
      );
    },
    currencyDropdownOptions() {
      return Object.keys(SUPPORTED_CURRENCIES);
    },
    purposeCodeDropdownOptions() {
      return PURPOSE_CODES[this.vendor.beneficiaryAddress.country].codes;
    },
    hasPurposeCode() {
      return PURPOSE_CODES.hasOwnProperty(this.vendor.bankAddress.country);
    },
    currencySymbol() {
      return SUPPORTED_CURRENCY_SYMBOLS[this.vendor.currencyCode].symbol_native;
    },
    matchedUpload() {
      if (this.isDiligenceServiceReadDataEnabled) {
        return this.$store.getters.uploadsMatching({
          externalIdType: ENTITY_TYPE.BANK_ACCOUNT,
          documentType: BANK_ACCOUNT_DOC_TYPE.VENDOR_INVOICE,
          externalId: `${this.vendor.id}`,
        })[0];
      }
      return this.$store.getters.uploadsMatching({
        entity: ENTITY_TYPE.BANK_ACCOUNT,
        type: BANK_ACCOUNT_DOC_TYPE.VENDOR_INVOICE,
        metaId: `${this.vendor.id}`,
      })[0];
    },
    rejectedEditable() {
      return (
        this.vendor.vendorAdminNotes &&
        this.filteredTransactions.every((t) =>
          ['failed', 'canceled'].includes(t.status),
        )
      );
    },
  },
  watch: {
    'paymentPayload.amountCents': _.debounce(function (val) {
      if (this.vendor.currencyCode !== this.advanceCurrencyCode) {
        this.$store.dispatchApiAction('GET_CONVERTED_CURRENCY_AMOUNT', {
          amountCents: Number(val),
          convertFrom: this.vendor.currencyCode,
          convertTo: this.advanceCurrencyCode,
        });
      }
    }, 500),
  },
  async mounted() {
    if (!this.vendor.invoiceUploadId) {
      this.$refs.container.toggleOpen();
    }
    if (this.business.id)
      this.$store.dispatchApiAction('FETCH_USER_CARDS', { canceled: false });
    this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
  },
  beforeUnmount() {
    this.$store.commit('CLEAR_CONVERTED_CURRENCY_AMOUNT');
  },
  methods: {
    getBillStatusLabel,
    getBillStatusTheme,
    async getFileUpload(uploadId) {
      if (uploadId) {
        await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
          id: uploadId,
          download: true,
        });
      }
    },
    getAdvanceType(advance) {
      const { UNIFIED, VENTURES, INVENTORY } = ADVANCE_SUBTYPES;

      switch (advance.subtype) {
        case UNIFIED:
          return this.$t('data.allAdvanceTypes.marketing');
        case VENTURES:
          return this.$t('data.allAdvanceTypes.ventures');
        case INVENTORY:
          return this.$t('data.allAdvanceTypes.inventory');
        default:
          return this.$t('data.allAdvanceTypes.unknown');
      }
    },
    getAdvanceBalance(advance) {
      const { UNIFIED, VENTURES, INVENTORY } = ADVANCE_SUBTYPES;
      switch (advance.subtype) {
        case UNIFIED:
          return this.totalCreditRemainingCents;
        case VENTURES:
          return this.totalVenturesCreditRemainingCents;
        case INVENTORY:
          return this.totalInventoryCreditRemainingCents;
        default:
          return 0;
      }
    },
    canUseAdvanceTypeToPayVendor(advance) {
      const { UNIFIED, VENTURES, INVENTORY } = ADVANCE_SUBTYPES;
      if (![INVENTORY, UNIFIED, VENTURES].includes(advance.subtype)) {
        return false;
      }
      if (
        advance.subtype === INVENTORY &&
        this.vendor.vendorCategory !== INVENTORY
      ) {
        return false;
      }
      return true;
    },
    getAdvancesWithAvailableSpend() {
      return this.advances.filter(
        (advance) => advance.activatedAt && this.getAdvanceBalance(advance) > 0,
      );
    },
    advancesToPayVendorWith() {
      const availableAdvances = this.getAdvancesWithAvailableSpend();
      const advancesThatCanBeUsed = availableAdvances.filter((advance) =>
        this.canUseAdvanceTypeToPayVendor(advance),
      );
      return this.getLatestAdvanceForEachSubtype(advancesThatCanBeUsed);
    },
    getLatestAdvanceForEachSubtype(advances) {
      const advanceSubtypeMap = advances.reduce((accumulator, currValue) => {
        if (accumulator[currValue.subtype]) {
          accumulator[currValue.subtype].push(currValue);
        } else {
          // eslint-disable-next-line no-param-reassign
          accumulator[currValue.subtype] = [currValue];
        }
        return accumulator;
      }, {});
      const latestAdvances = [];
      const subtypes = Object.keys(advanceSubtypeMap);
      subtypes.forEach((type) => {
        const latestAdvance = this.getLatestAdvance(advanceSubtypeMap[type]);
        if (latestAdvance) {
          latestAdvances.push(latestAdvance);
        }
      });
      return latestAdvances;
    },
    getLatestAdvance(advances) {
      advances.sort((a, b) => {
        return new Date(b.activatedAt) - new Date(a.activatedAt);
      });
      if (advances[0]) return advances[0];
      return null;
    },
    displayAdvancesToPayVendorWith() {
      return this.advancesToPayVendorWith().map((advance) => ({
        label: this.getAdvanceLabel(advance),
        value: `${advance.id}`,
      }));
    },
    getAdvanceLabel(advance) {
      return `${this.getAdvanceType(advance)} - #${advance.id} - ${formatMoney(
        this.getAdvanceBalance(advance),
        advance.currencyCode,
      )}`;
    },
    async updateInvoice(vendorId, uploadId) {
      await this.getFileUpload(uploadId);
      this.$emit('openVendorModal', vendorId, 5);
    },
    async invoiceUploaded() {
      // Notify our API that the vendor information has been updated.
      // This will set the vendor approval status to 'PENDING'.
      await this.$store.dispatchApiAction('UPDATE_VENDOR_ACCOUNT', this.vendor);
    },
    displayDollars(dollars) {
      const {
        paymentPayload: { currencyCode },
      } = this;
      this.amountToDisplay = dollars;
      this.paymentPayload.amountCents = dollarsToCents(dollars, currencyCode);
    },
    hidePaymentField() {
      this.isPaymentFieldOpen = false;
      this.amountToDisplay = '';
      this.paymentPayload = {
        isOpen: false,
        amountCents: '',
        dueDate: '',
        paymentIdentifier: '',
        currencyCode: this.vendor.currencyCode,
      };
      this.$store.commit('CLEAR_CONVERTED_CURRENCY_AMOUNT');
      this.$refs.acceptTermsAndConditions.close();
    },
    showPaymentField() {
      const advances = this.advancesToPayVendorWith();
      if (advances.length > 0) {
        this.paymentPayload.advance = `${advances[0].id}`;
      }
      this.isPaymentFieldOpen = true;
    },
    addPayment() {
      return this.$refs.clearPayPopup.open();
    },
    closePopup(ref) {
      this.$refs[ref].close();
    },
    determineTransactionAmount(
      transactionCurrency,
      exchangeDetails,
      transactionAmount,
    ) {
      if (!exchangeDetails) {
        return `${transactionCurrency} ${formatMoney(
          transactionAmount,
          transactionCurrency,
        )}`;
      }

      // we want to show user the exact original amount as their input
      // no the updatedOriginalAmount
      const { convertFrom, originalAmountCents } = exchangeDetails;
      if (transactionCurrency !== convertFrom) {
        return `${convertFrom} ${formatMoney(
          originalAmountCents,
          convertFrom,
        )}`;
      }
      return `${transactionCurrency} ${formatMoney(
        originalAmountCents,
        convertFrom,
      )}`;
    },
    determineExchangeAmount(transactionCurrency, exchangeDetails) {
      if (!exchangeDetails) return '';

      const { convertTo, convertFrom, convertedAmountCents } = exchangeDetails;

      if (transactionCurrency === convertFrom) return '';
      return `(${convertTo} ${formatMoney(convertedAmountCents, convertTo)})`;
    },
    async createTransaction() {
      this.$refs.acceptTermsAndConditions.close();
      await this.$store.dispatchApiAction('CREATE_VENDOR_PAYMENT', {
        amountCents: this.paymentPayload.amountCents,
        currencyCode: this.paymentPayload.currencyCode,
        vendorId: this.vendor.id,
        dueDate: dateFns.format(this.paymentPayload.dueDate, 'yyyy-MM-dd'),
        paymentIdentifier: this.paymentPayload.paymentIdentifier,
        advanceId: parseInt(this.paymentPayload.advance),
        purposeCode: this.paymentPayload.purposeCode,
      });
      if (this.createVendorPaymentRequest.isSuccess) {
        this.hidePaymentField();
      }
    },
    async showTermsAndConditions() {
      const error = await this.hasError();
      if (error) return;
      this.$refs.acceptTermsAndConditions.open();
    },
    canRequestPaymentConfirmation(txn) {
      // Currently, payment confirmation is only available for Currency Cloud transactions.
      const isCurrencyCloud = txn.platform === 'currency_cloud';
      const isSuccessful = txn.status === 'success';
      return isCurrencyCloud && isSuccessful;
    },
    shouldShowDeliveryDate(txn) {
      const { IN_TRANSIT } = TransactionStatus;
      const { CURRENCY_CLOUD } = TransactionPlatform;

      const isCurrencyCloud = txn.platform === CURRENCY_CLOUD;
      const isInTransit = txn.status === IN_TRANSIT;

      return isCurrencyCloud && isInTransit;
    },
    async requestPaymentConfirmation(txn) {
      this.paymentConfirmationTransactionId = txn.id;
      await this.$store.dispatchApiAction('GET_PAYMENT_CONFIRMATION', {
        transactionId: txn.id,
      });
      if (this.getPaymentConfirmationRequest.isError) {
        this.$refs.paymentConfirmationErrorPopup.open();
      }
      this.paymentConfirmationTransactionId = null;
    },
    isPaymentConfirmationLoading(txn) {
      return this.paymentConfirmationTransactionId === txn.id;
    },
    getPaymentUrl(paymentType) {
      const vendorId = this.vendor.id;
      const vendorName = this.vendor.label;
      const userBusinessId = this.vendor.business.id;

      const queryString = `#business_id=${userBusinessId}&vendor_name=${vendorName}&vendor_id=${vendorId}`;
      const typeFormUrl =
        paymentType === PAYMENT_TYPE.CREDIT
          ? process.env.TYPEFORM_CREDIT_INPUT_URL
          : process.env.TYPEFORM_BANK_INPUT_URL;

      return `${typeFormUrl}${queryString}`;
    },
    payWithAdvance() {
      const CLEAR_PAY_POPUP = 'clearPayPopup';
      this.showPaymentField();
      this.closePopup(CLEAR_PAY_POPUP);
    },
    trackEvent(event) {
      analytics.track(event, {
        businessId: this.vendor.business.id,
        businessName: this.vendor.business.name,
        vendorId: this.vendor.id,
        vendorName: this.vendor.label,
      });
    },
    gettingLocaleForDatepicker() {
      return gettingLocaleForDatepickerLib(this.selectedLanguage);
    },
  },
};
</script>

<style lang="less" module>
.datepicker-styles {
  position: relative;
  .vdp-styles();
}
.vendor-details {
  &__file-upload {
    background-color: lightgrey;
    padding: @gutter-10;
    margin-bottom: @gutter-10;
  }
  &__expand-dropzone {
    [class^='form-row-inner'] {
      display: unset;
    }
  }

  .action-link-container {
    margin: 20px 0;

    a {
      color: @color-black;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .vendor-details__payment {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid @dark-black;

    .vendor-details__payment-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flex {
      flex: 1 1 150px;

      &:last-child {
        flex: 1 1 100%;
      }

      button {
        float: none;
      }
    }

    &.success {
      border-left: 3px solid @green;
      background: lighten(@green, 45%);
    }

    &.pending {
      border-left: 3px solid orange;
      background: lighten(orange, 40%);
    }

    &.failed {
      border-left: 3px solid @red;
      background: lighten(@red, 30%);
    }

    .vendor-details__label {
      font-size: 11px;
    }

    .match-button-width {
      min-width: 220px;
    }

    .vendor-details__description {
      font-size: 14px;
    }
  }

  .payment-confirmation-error p {
    margin: 0;
    padding: 16px;
  }
}

a.no-underline {
  text-decoration: none;
}

.clearpay-popup-container {
  padding: @gutter-10;
  button {
    background: white;
    border: none;
    text-align: inherit;
    min-width: 100%;
    cursor: pointer;
    padding: 0;
    font-family: @regular-font;
    p {
      font-weight: 100;
    }
  }
  a {
    cursor: pointer;
    color: initial;
  }
  span {
    font-weight: 100;
    font-size: 75%;
  }
}

@media (min-width: 768px) {
  .break {
    flex-basis: 100%;
    height: 12px;
  }
  .due-date-calendar {
    right: 0;
  }
}

.date-picker-margin {
  margin-bottom: 220px;
}

button.edit-button {
  color: @color-black !important;
  padding: 0;
}
</style>
