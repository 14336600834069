<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import analytics from '@/utils/analytics';

import DSButton from '@clearbanc/clear-components/button';
import ModalDeleteOwner from '@/components/modals/ModalDeleteOwner';
import ChangeSignatoryUserModal from '@/components/ChangeSignatoryUserModal';
import OwnersListItem from '@/components/owners-list-item';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();

// ---------
// Variables
// ---------

const allOwners = computed(() => store.getters.allOwners);
const modalDeleteOwner = ref(null);
const modalSignatoryOwner = ref(null);

// ------------
// Misc Methods
// ------------

function addNewOwner() {
  analytics.track('if_secondary_profile_added');

  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_NEW_OWNER,
  });
}

function showSignatoryModal(owner) {
  modalSignatoryOwner.value = owner;
}

function hideSignatoryModal() {
  modalSignatoryOwner.value = null;
}

function toggleDeleteOwnerModal(ownerId) {
  modalDeleteOwner.value?.toggleModal(ownerId);
}

onMounted(() => {
  store.dispatchApiAction('GET_OWNERS');
});
</script>

<template>
  <div>
    <OwnersListItem
      v-for="(owner, index) in allOwners"
      :key="`owner-${index}`"
      :owner="owner"
      @setAsSignatory="showSignatoryModal(owner)"
      @deleteOwner="toggleDeleteOwnerModal(owner.id)"
    />
    <div :class="$style.actions">
      <DSButton
        label="Add new owner or director"
        class="p-button-outlined"
        :class="$style.actions__button"
        @click="addNewOwner"
      />
    </div>
  </div>

  <ModalDeleteOwner ref="modalDeleteOwner" />
  <ChangeSignatoryUserModal
    :visible="!!modalSignatoryOwner"
    :owner="modalSignatoryOwner"
    @close="hideSignatoryModal"
  />
</template>

<style lang="less" module>
.actions {
  margin-top: 24px;
}

.actions__button {
  width: 100%;
}
</style>
