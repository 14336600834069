import { authGuard } from '@auth0/auth0-vue';
import { PAY_VENDORS, PAYMENTS } from '@/data/routes';

import VendorsPage from '@/pages/account/vendors/page-vendors';

import VendorRepaymentsSubPage from '@/pages/account/vendors/subpage-repayments';
import VendorPaymentsSubPage from '@/pages/account/vendors/subpage-payment-transactions';

export default [
  {
    path: '/payments',
    name: PAY_VENDORS.INDEX,
    component: VendorsPage,
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      displayNav: true,
    },
    children: [
      {
        path: 'history',
        name: PAYMENTS.INDEX,
        component: VendorRepaymentsSubPage,
        props: () => ({
          repaymentsType: 'history',
        }),
      },
      {
        path: 'upcoming',
        alias: '/pay-vendors/upcoming-payments',
        name: PAYMENTS.UPCOMING,
        component: VendorRepaymentsSubPage,
        props: () => ({
          repaymentsType: 'upcoming',
        }),
      },
      {
        path: ':billId?',
        alias: '/pay-vendors/payments/:billId?',
        name: PAYMENTS.BILL,
        component: VendorPaymentsSubPage,
      },
    ],
  },
];
