import { authGuard } from '@auth0/auth0-vue';
import { FINANCIAL } from '@/data/routes';

import PaymentBizProfileDebtObligations from '@/pages/payments/business-profile/DebtObligations';
import PaymentBizProfileBankDocuments from '@/pages/payments/business-profile/bank-documents';

export default [
  {
    path: '/financial',
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        alias: '/pay-vendors/business-profile/debt-obligations',
        name: FINANCIAL.DEBT_OBLIGATIONS,
        component: PaymentBizProfileDebtObligations,
      },
      {
        path: 'documents',
        alias: '/pay-vendors/business-profile/documents',
        name: FINANCIAL.DOCUMENTS,
        component: PaymentBizProfileBankDocuments,
      },
    ],
  },
];
