import * as _ from 'lodash';
import { BUSINESS_TAX_ID_CONFIG } from '@/data/tax-id-config';
import i18n from '@/plugins/i18n';
import { CoreCorpType } from '@clearbanc/data-common-types';

export const CORP_TYPE_OTHER = {
  value: 'other_option',
  name: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.other'),
  nameString: i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.other'),
};
export function corpTypesForCountry(countryCode) {
  const CORP_TYPES = _.get(BUSINESS_TAX_ID_CONFIG, `${countryCode}.corpTypes`, {
    [CoreCorpType.Corporation]: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.corp'),
    [CoreCorpType.LLC]: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.llc'),
    [CoreCorpType['Sole Proprietorship']]: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.sole'),
    [CoreCorpType.Partnership]: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.partnership'),
    [CoreCorpType['Non-Profit']]: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.nonprofit'),
  });

  const CORP_TYPE_BY_COUNTRY_OPTIONS = Object.keys(CORP_TYPES).reduce(
    (acc, value, idx) => {
      const name = CORP_TYPES[value];
      return acc.concat({
        key: idx,
        value,
        name,
        nameString: name(),
      });
    },
    [],
  );

  // add other option
  CORP_TYPE_BY_COUNTRY_OPTIONS.push({
    key: CORP_TYPE_BY_COUNTRY_OPTIONS.length,
    ...CORP_TYPE_OTHER,
  });

  return CORP_TYPE_BY_COUNTRY_OPTIONS;
}
