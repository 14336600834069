// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disable-content_RGs3l{pointer-events:none}.wrapper_B2Q1X{width:100%;margin-bottom:20px}@media (min-width:800px){.wrapper_B2Q1X{margin-bottom:60px}}.search_CdPI_{position:relative;margin-bottom:24px}input.search__input_ODWms{padding-left:44px}.search__icon_UkZFO{position:absolute;top:50%;left:18px;transform:translateY(-50%)}.search__icon_UkZFO:before{color:#303032}.banks_ssv3r{display:flex;flex-wrap:wrap;width:100%;align-items:flex-start}.banks__item_EixBm{all:unset;width:calc(50% - 8px);margin:16px 0 0 16px;cursor:pointer}.banks__item_EixBm:focus{outline:none}@media (max-width:799px){.banks__item_EixBm{width:calc(50% - 8px)}.banks__item_EixBm:nth-child(odd){margin-left:0}.banks__item_EixBm:nth-child(-n+2){margin-top:0}}@media (min-width:800px){.banks__item_EixBm{width:calc(25% - 12px);margin:16px 0 0 16px}.banks__item_EixBm:nth-child(4n+1){margin-left:0}.banks__item_EixBm:nth-child(-n+4){margin-top:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disable-content": "disable-content_RGs3l",
	"wrapper": "wrapper_B2Q1X",
	"search": "search_CdPI_",
	"search__input": "search__input_ODWms",
	"search__icon": "search__icon_UkZFO",
	"banks": "banks_ssv3r",
	"banks__item": "banks__item_EixBm"
};
export default ___CSS_LOADER_EXPORT___;
