import { authGuard } from '@auth0/auth0-vue';
import { BUSINESS } from '@/data/routes';
import PaymentBizProfileBusinessDetails from '@/pages/payments/business-profile/business-details';
import PaymentBizProfileBusinessRegisteredAddress from '@/pages/payments/business-profile/registered-address';

export default [
  {
    path: 'business',
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      displayNav: true,
    },
    children: [
      {
        path: '',
        alias: '/pay-vendors/business-profile/details',
        name: BUSINESS.INDEX,
        component: PaymentBizProfileBusinessDetails,
      },
      {
        path: 'address',
        alias: '/pay-vendors/business-profile/address',
        name: BUSINESS.ADDRESS,
        component: PaymentBizProfileBusinessRegisteredAddress,
      },
    ],
  },
];
