<template>
  <wayfinding-content-layout :title="accountSectionTitle" />
</template>

<script>
import {
  WAYFINDING_MENU_KEYS,
  WAYFINDING_MENU_TEMPLATE,
} from '@/data/wayfinding';
import { useMeta } from 'vue-meta';

const components = {
  'wayfinding-content-layout': require('@/layout/LayoutContentWayfinding')
    .default,
};

export default {
  components,
  setup() {
    useMeta({
      title: 'My account | Clearco',
    });
  },

  computed: {
    accountSectionTitle() {
      if (this.$route.name === 'account-connections') {
        return 'Account Connections';
      }
      const accountSections =
        WAYFINDING_MENU_TEMPLATE[WAYFINDING_MENU_KEYS.ACCOUNT].children;
      return accountSections
        .find((section) => section.route === this.$route.name)
        ?.title();
    },
  },
};
</script>
