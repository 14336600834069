<template>
  <Panel :header="header" :class="$style.summary">
    <div :class="$style.summary__basics">
      <div>
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.dateCreated')
        }}</span>
        <span :class="$style.value">{{
          getFormattedDateString(bill.createdAt)
        }}</span>
      </div>
      <div v-if="showContract">
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.agreement')
        }}</span>
        <DSButton
          :label="$t('account.pages.pageVendors.billDetails.viewAgreement')"
          class="p-button-link"
          :disabled="downloadContractRequest.isPending"
          :style="{ padding: 0, 'text-decoration': 'none' }"
          @click="openDownloadContract()"
        />
      </div>
      <div>
        <span v-if="bill.isReceipt" :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.datePaid')
        }}</span>
        <span v-else :class="$style.label"
          >{{ $t('account.pages.pageVendors.billDetails.dueDate') }}
        </span>
        <span :class="$style.value">{{
          getFormattedDateString(
            bill.isReceipt ? bill.issuedDate : bill.dueDate,
          )
        }}</span>
      </div>
      <div>
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.upload')
        }}</span>
        <a :href="currentUpload.downloadUrl" target="_blank">
          {{ currentUploadDisplayName }}
        </a>
      </div>
      <div
        v-if="selectedBillBeneficiary?.name"
        :class="$style['col--full-width']"
      >
        <span :class="$style.label">{{
          isSelectedBillPayoutComplete
            ? $t('account.pages.pageVendors.billDetails.sentFundsTo')
            : $t('account.pages.pageVendors.billDetails.sendingFundsTo')
        }}</span>
        <span v-if="!bill.isReceipt" :class="$style.value">{{
          selectedBillBeneficiary.name
        }}</span>
        <span v-else-if="!!primaryBankAccountLabel" :class="$style.value">{{
          primaryBankAccountLabel
        }}</span>
      </div>
      <div v-if="!bill.isReceipt" :class="$style['col--full-width']">
        <span :class="$style.label">Payment Reference to Recipient</span>
        <EditMemo
          v-if="allowEditPaymentMemo"
          :memo="bill.memo"
          :class="$style.value"
        />
        <span v-else :class="$style.value">{{ bill.memo }}</span>
      </div>
    </div>
    <Divider :class="$style['divider--full-width']" />
    <template v-if="hasConvertedAmount">
      <div :class="$style.summary__conversion">
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.amount')
        }}</span>
        <span :class="$style.value">{{ billAmount }}</span>
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.conversionRate')
        }}</span>
        <span :class="$style.value"
          >{{ exchangeRate
          }}<template v-if="hasEstimatedValues">*</template></span
        >
      </div>
      <Divider />
    </template>
    <template v-if="hasConvertedAmount">
      <div :class="$style.summary__fees">
        <span :class="$style.label">{{
          hasEstimatedValues
            ? $t('paymentsPage.estimatedConvertedAmount')
            : $t('paymentsPage.convertedAmount')
        }}</span>
        <span :class="$style.value"
          >{{ moneyDisplayValue(bill.payOutConvertedAmountCents)
          }}<template v-if="hasEstimatedValues">*</template></span
        >
        <span :class="$style.label">{{
          $t('account.pages.pageVendors.billDetails.fee')
        }}</span>
        <span :class="$style.value">{{ displayFees }}</span>
      </div>
    </template>
    <div v-else :class="$style.summary__fees">
      <span :class="$style.label">{{
        $t('account.pages.pageVendors.billDetails.amount')
      }}</span>
      <span :class="$style.value">{{ billAmount }}</span>
      <span :class="$style.label">{{
        $t('account.pages.pageVendors.billDetails.fee')
      }}</span>
      <span :class="$style.value">{{ displayFees }}</span>
    </div>
    <Divider />
    <div :class="$style.summary__total">
      <span :class="$style.label">{{
        $t('account.pages.pageVendors.billDetails.total')
      }}</span>
      <span :class="$style.total"
        >{{ moneyDisplayValue(displayTotal)
        }}<template v-if="hasEstimatedValues">*</template></span
      >
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { startsWith } from 'lodash';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import {
  DEFAULT_CURRENCY,
  PAY_OUT_CURRENCY_OPTIONS,
  PAY_IN_PAY_OUT_STATUS,
  BillPaymentFees,
  BillPaymentMethods,
} from '@/data/payments';

import { getShortBillId, getFormattedDateString } from '@/utils/payments';
import { formatMoney } from '@/utils/currency';
import { convertBase64ToFileURL } from '@/utils/api';
import DSButton from '@clearbanc/clear-components/button';
import Panel from '@clearbanc/clear-components/panel';
import Divider from '@clearbanc/clear-components/divider';
import EditMemo from './EditMemo';

export default {
  components: {
    DSButton,
    Panel,
    Divider,
    EditMemo,
  },
  props: {
    billContract: {
      type: Object || null,
      required: false,
    },
    bill: { type: Object },
  },
  computed: {
    ...mapRequestStatuses({
      downloadContractRequest: 'GET_DOCUSIGN_CONTRACT_DOWNLOAD',
    }),
    ...mapGetters(['currentUpload', 'selectedBillBeneficiary', 'bankAccounts']),
    uploadType() {
      return this.bill.isReceipt
        ? this.$t('account.pages.pageVendors.billDetails.receipt')
        : this.$t('account.pages.pageVendors.billDetails.invoice');
    },
    header() {
      return `${this.uploadType} Summary`;
    },
    billAmount() {
      if (this.bill.payOutCurrency !== DEFAULT_CURRENCY) {
        return `${formatMoney(
          this.bill.amountCents,
          this.bill.payOutCurrency,
        )} ${this.bill.payOutCurrency}`;
      }

      return this.moneyDisplayValue(this.bill.amountCents);
    },
    exchangeRate() {
      return this.bill.payOutFxRate
        ? `${
            PAY_OUT_CURRENCY_OPTIONS[this.bill.payOutCurrency].symbol_native
          }1 ${this.bill.payOutCurrency} = $${this.bill.payOutFxRate.toFixed(
            4,
          )} ${DEFAULT_CURRENCY}`
        : false;
    },
    hasConvertedAmount() {
      return this.bill.payOutCurrency !== DEFAULT_CURRENCY;
    },
    hasEstimatedValues() {
      return this.billContract === null && this.hasConvertedAmount;
    },
    displayFees() {
      let fee;
      if (this.bill.feeCents) {
        fee = this.moneyDisplayValue(this.bill.feeCents);
      } else if (this.bill.paymentMethod === BillPaymentMethods.CREDIT_CARD) {
        fee = BillPaymentFees.CREDIT_CARD_FEES;
      } else {
        fee = BillPaymentFees.FREE;
      }

      return fee;
    },
    displayTotal() {
      const billAmount =
        this.bill.payOutConvertedAmountCents || this.bill.amountCents;
      return Number(billAmount) + Number(this.bill.feeCents);
    },
    showContract() {
      return this.billContract && !!this.billContract.userSignedAt;
    },
    currentUploadDisplayName() {
      if (!this.currentUpload.name) return '';
      if (this.currentUpload.name.length < 17) return this.currentUpload.name;
      return `${this.currentUpload.name.substring(0, 12)}...${
        this.currentUpload.extension
      }`;
    },
    primaryBankAccountLabel() {
      const primaryAccount = this.bankAccounts.find(
        (account) => account.isPrimary,
      );
      if (!primaryAccount) return '';
      return `${primaryAccount.bankName || ''} *** *** ** ${
        primaryAccount.accountNumberLast4
      }`;
    },
    isSelectedBillPayoutComplete() {
      return this.bill.payOutStatus === PAY_IN_PAY_OUT_STATUS.PAYMENT_COMPLETED;
    },
    allowEditPaymentMemo() {
      const { bnplDetails } = this.bill;
      return bnplDetails && !bnplDetails.diligenceApprovedAt;
    },
  },
  async mounted() {
    const { beneficiaryId } = this.bill;
    if (beneficiaryId && beneficiaryId !== '+newvendor') {
      await this.$store.dispatchApiAction('GET_BENEFICIARY_BY_ID', {
        beneficiaryId,
      });
    }
  },
  methods: {
    getShortBillId,
    getFormattedDateString,
    async openDownloadContract() {
      const downloadResponse = await this.$store.dispatchApiAction(
        'GET_DOCUSIGN_CONTRACT_DOWNLOAD',
        {
          contractId: this.billContract.id,
        },
      );
      let downloadURL = downloadResponse?.downloadURL;
      if (downloadURL && startsWith(downloadURL, 'data:')) {
        downloadURL = convertBase64ToFileURL(downloadURL);
      }
      if (downloadURL) {
        window.open(downloadURL);
      }
    },
    moneyDisplayValue(amount) {
      return `${formatMoney(amount, DEFAULT_CURRENCY)} ${DEFAULT_CURRENCY}`;
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.p-divider.p-divider-horizontal::before) {
  border-top-style: solid;
}
</style>
<style lang="less" module>
.summary {
  .summary__basics,
  .summary__conversion,
  .summary__fees {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 18px;

    span {
      display: block;
    }

    .col--full-width {
      grid-column: span 2;
    }

    .label {
      font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: @lighter-black;
      margin-bottom: 2px;
    }

    .value {
      font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: @black;
    }
  }

  .summary__basics > div {
    text-align: left;
  }

  .summary__conversion,
  .summary__fees {
    row-gap: 12px;

    .label {
      text-align: left;
    }

    .value {
      text-align: right;
    }
  }

  .summary__total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: @lighter-black;
      margin-bottom: 2px;
    }

    .total {
      font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: @black;
    }
  }

  .divider--full-width {
    transform: translateX(-1.25rem);
    width: calc(100% + 2.5rem);
  }
}
</style>
