export const GTM_CUSTOM_CONVERSIONS = {
  CLEARPAY__BUSINESS_PROFILE_COMPLETE: 'clearpay__business-profile-complete',
};

const ACTIONS_WITH_GTM_PAYLOADS = {
  CREATE_PAY_INVOICE: 'CREATE_PAY_INVOICE',
  GET_BILLS: 'GET_BILLS',
  USER_FEDERATED_LOGIN: 'USER_FEDERATED_LOGIN',
  USER_LOGIN: 'USER_LOGIN',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_NON_PRIMARY_USER: 'UPDATE_NON_PRIMARY_USER',
};

export const trackLinkedin = (conversion) => {
  const conversionIds = {
    mql: '2675796',
    connected: '2680604',
  };

  if (conversion && conversionIds[conversion]) {
    const imgtag = document.createElement('img');
    const src = `https://px.ads.linkedin.com/collect/?pid=1197724&conversionId=${conversionIds[conversion]}&fmt=gif`;
    imgtag.height = '1';
    imgtag.width = '1';
    imgtag.style = 'border-style:none;';
    imgtag.alt = '';
    imgtag.src = src;
    document.getElementsByTagName('body')[0].appendChild(imgtag);
  }
};

const handleGtmPayload = (eventName, data) => {
  const shouldSendPayload = Object.values(ACTIONS_WITH_GTM_PAYLOADS).includes(
    eventName,
  );
  if (shouldSendPayload) {
    if (eventName === ACTIONS_WITH_GTM_PAYLOADS.CREATE_PAY_INVOICE) {
      const billAmount = data.amountCents
        ? parseInt(data.amountCents) / 100
        : 0;
      return {
        billAmount,
        payOutCurrency: data.payOutCurrency || null,
        repaymentTerm: data.bnplDetails?.repaymentTerm || 0,
      };
    }

    if (eventName === ACTIONS_WITH_GTM_PAYLOADS.GET_BILLS) {
      return {
        numberOfBills: data.length,
      };
    }

    const payloadData = data.user?.business || data.business || data;
    const defaultPayload = {
      businessVertical: payloadData.vertical || null,
      corpCountry: payloadData.corpCountry || null,
      selfReportedRevenue:
        payloadData.qualifyingQuestions?.monthly_revenue || null,
    };

    return defaultPayload;
  }

  return {};
};

export const trackGtmConversion = (eventName, data) => {
  const payload = handleGtmPayload(eventName, data);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...payload,
  });
};
