import { authGuard } from '@auth0/auth0-vue';
import { DOCUMENTS } from '@/data/routes';

import VendorsPage from '@/pages/account/vendors/page-vendors';

import PaymentsAllDocuments from '@/pages/payments/all-documents';

export default [
  {
    path: '/documents',
    component: VendorsPage,
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      displayNav: true,
    },
    children: [
      {
        path: '',
        alias: '/pay-vendors/all-documents',
        name: DOCUMENTS.INDEX,
        component: PaymentsAllDocuments,
      },
    ],
  },
];
