import { buildApiActions } from '@/utils/vuex-api-utils';

function initialState() {
  return {
    activeStep: 1,
    signupProfileStep: 1,
    connectAccountsTabs: {
      activeTab: 0,
      tabs: [
        {
          label: 'sales',
          completed: false,
          disabled: false,
        },
        {
          label: 'marketing',
          completed: false,
          disabled: true,
        },
        {
          label: 'banking',
          completed: false,
          disabled: true,
        },
        {
          label: 'accounting',
          completed: false,
          disabled: true,
        },
        {
          label: 'submit',
          completed: false,
          disabled: true,
        },
      ],
    },
    params: {
      businessType: 'ecom',
      productSegment: 'core',
      agreedToNewsletter: false,
      revenue: 0.06,
      adspend: 0.06,
    },
  };
}

export default {
  state: initialState,
  getters: {
    signupProfileStep: (state) => state.signupProfileStep,
    activeStep: (state) => state.activeStep,
    activeTab: (state) => state.connectAccountsTabs.activeTab,
    connectAccountsTabs: (state) => state.connectAccountsTabs.tabs,
    newSignupParams: (state) => state.params,
    isQualifiedLead: (state) =>
      state.params.isEcomBusiness === 'yes' &&
      state.params.revenue > 0.12 &&
      ['AU', 'GB', 'US', 'CA'].includes(state.params.businessCountry),
  },
  ...buildApiActions(
    {},
    {
      mutations: {
        UPDATE_SIGNUP_PROFILE_STEP: (state, { step }) => {
          state.signupProfileStep = step;
        },
        UPDATE_SIGNUP_STEP: (state, { step }) => {
          state.activeStep = step;
        },
        UPDATE_SIGNUP_PARAMS: (state, field) => {
          state.params = { ...state.params, ...field };
        },
        RESET_SIGNUP_STATE: (state) => {
          const s = initialState();

          Object.keys(s).forEach((key) => {
            state[key] = s[key];
          });
        },
        UPDATE_ACCOUNT_CONNECT_TAB: (state, { step }) => {
          state.connectAccountsTabs.activeTab = step;
        },
        TOGGLE_ACCOUNT_CONNECT_TAB_DISABLED: (
          state,
          { tabIndex, disabled },
        ) => {
          state.connectAccountsTabs.tabs[tabIndex].disabled = disabled;
        },
        TOGGLE_ACCOUNT_CONNECT_TAB_COMPLETED: (
          state,
          { tabIndex, completed },
        ) => {
          state.connectAccountsTabs.tabs[tabIndex].completed = completed;
        },
      },
      actions: {
        UPDATE_SIGNUP_PARAMS: ({ commit }, field) => {
          commit('UPDATE_SIGNUP_PARAMS', field);
        },
        RESET_SIGNUP_STATE: ({ commit }) => {
          commit('RESET_SIGNUP_STATE');
        },
      },
    },
  ),
};
