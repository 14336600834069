// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-message_vES6s[data-v-13aa3327]{margin:24px 0 40px}.owners-wrapper_Kovkk[data-v-13aa3327]{max-width:556px;margin:0 auto}.actions_VPqoC[data-v-13aa3327]{margin-top:40px;display:flex;gap:16px}.actions__button_caONQ[data-v-13aa3327]{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-message": "page-message_vES6s",
	"owners-wrapper": "owners-wrapper_Kovkk",
	"actions": "actions_VPqoC",
	"actions__button": "actions__button_caONQ"
};
export default ___CSS_LOADER_EXPORT___;
