import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import i18n from '@/plugins/i18n';

export const validateAmazonSellerMixin = {
  methods: {
    invalidAmazonSellerID(sellerId) {
      // start simple - strip whitespaces and check for non-alpha-numeric characters
      // Can be elaborated in the future
      const validIdRegex = /^A([a-zA-Z\-0-9]*){8,19}$/;
      const isValid = !!validIdRegex.test(sellerId.trim());
      if (!isValid) {
        this.errorMessage = 'Please enter a valid Amazon Seller ID';
      }
      return !isValid;
    },
  },
};

export const scrollMixin = {
  methods: {
    scrollHandler(elem, to, dur) {
      const element =
        elem || document.scrollingElement || document.documentElement;
      if (dur <= 0) return false;
      const diff = to - element.scrollTop;
      const tick = (diff / dur) * 10;

      return setTimeout(() => {
        element.scrollTop += tick;
        if (element.scrollTop === to) return false;
        return this.scrollHandler(element, to, dur - 10);
      }, 10);
    },
  },
};

export const modalsRedirectMixin = {
  computed: {
    redirectRoute() {
      return this.$route.fullPath;
    },
  },
};

export const wistiaMixin = {
  mounted() {
    const isWistiaScriptLoaded = Array.from(
      document.getElementsByTagName('script'),
    ).filter((el) => el.src.includes('wistia')).length;

    if (!isWistiaScriptLoaded) {
      const wistiaScript = document.createElement('script');
      wistiaScript.setAttribute(
        'src',
        'https://fast.wistia.com/assets/external/E-v1.js',
      );
      document.head.appendChild(wistiaScript);
    }
  },
};

/**
 * This mixin is used to track clicks to connect accounts
 * in order to see the followthrough rates on attempted connections
 * and successful connections
 */
export const clickTrackingMixin = {
  computed: {
    ...mapGetters(['user', 'business']),
  },
  methods: {
    trackClick(platformName, props) {
      analytics.track('fe_integration_click', {
        platform: platformName,
        userId: this.user.id,
        businessId: this.business.id,
        productSegment: this.business.productSegment.label,
        ...props,
      });
    },
  },
};

/*
 * This mixin is used to extract the Shopify store name from
 * a given store URL. It will return an error message if a
 * non well-formed URL is passed.
 */
export const shopifyUrlMixin = {
  methods: {
    handleUrl(storeUrl, isSelfServeOrOutbound2User = false) {
      let storeName = `${storeUrl}.myshopify.com`;
      const httpRegex = new RegExp('^((https|http)://)');
      const wwwRegex = new RegExp('((w){1,}[.])(?!myshopify.com)', 'gi');
      const nameRegex = new RegExp('^[a-zA-Z0-9-]*.myshopify');
      const endUrlRegex = new RegExp(
        '[.][a-zA-Z][a-zA-Z][a-zA-Z]?([/]*[a-zA-Z0-9]*?)?$',
      );
      const defaultOnboardingErrorMessage = i18n.t(
        'components.connectShopifySS.defaultOnboardingErrorMessage',
      );
      // Checking for the case that storeUrl is empty
      if (!storeUrl) {
        return {
          errorMessage: i18n.t('common.requiredField'),
        };
      }
      // Checking for the case that they input NO url at all (e.g. just 1 character or a few characters)
      if (storeName.search('[.]') === -1) {
        return {
          errorMessage: isSelfServeOrOutbound2User
            ? defaultOnboardingErrorMessage
            : i18n.t('components.connectShopifySS.storeNameErrorMessage'),
        };
      }
      // Check to indicate if they included 'https://' or 'http://'; not required, but if included, ensures it is correctly formatted
      if (storeName.search(':') !== -1) {
        if (!httpRegex.test(storeName)) {
          return {
            errorMessage: isSelfServeOrOutbound2User
              ? defaultOnboardingErrorMessage
              : i18n.t('components.connectShopifySS.storeNameErrorMessageHttp'),
          };
        }
        storeName = storeName.replace('https://', '').replace('http://', '');
      }
      // Check if any number of 'w' characters follow a dot which does NOT follow '.myshopify.com'.
      // Eg. 'window.myshopify.com' will not be matched but 'ww.store.myshopify.com' will
      if (wwwRegex.test(storeName)) {
        storeName = storeName.replace(wwwRegex, ''); // remove the matched group
      }
      // checking for special characters
      if (!nameRegex.test(storeName)) {
        return {
          errorMessage: isSelfServeOrOutbound2User
            ? defaultOnboardingErrorMessage
            : i18n.t(
                'components.connectShopifySS.specialCharactersErrorMessage',
              ),
        };
      }
      // remove .myshopify ending
      storeName = storeName.replace('.myshopify', '');
      if (!isSelfServeOrOutbound2User && !endUrlRegex.test(storeName)) {
        const removeMyShopifyEndingErrorMessage = i18n.t(
          'components.connectShopifySS.removeMyShopifyEndingErrorMessage',
        );
        return {
          errorMessage: removeMyShopifyEndingErrorMessage,
        };
      }
      // remove .com ending
      storeName = storeName.replace(endUrlRegex, '');
      return { storeName };
    },
  },
};

export const plaidOAuthMixin = {
  methods: {
    /**
     * The Plaid OAuth flow is as follows:
     * 1) User inits Plaid Link with a new Link Token
     * 2) User gets taken to external banking site for OAuth
     * 3) User gets redirected to our app and we need to redirect them to the last page they were on
     * 4) We need to re-initialize Plaid Link a 2nd time with the same link token and the received redirect URI
     * 5) Clear the tokens so they're not stale
     * @param {object} p
     * @param {string} p.lastPage  - the page that we're navigating away from
     * @param {ref} p.plaidLinkRef - reference to Plaid Link widget
     * @param {number} [p.bankConnectionId=null]
     * @param {string} [p.routingNumber=null]
     */
    async openPlaidIntegration({
      lastPage,
      plaidLinkRef,
      bankConnectionId = null,
      routingNumber = null,
    }) {
      localStorage.setItem('lastPageBeforeOAuth', lastPage);

      let plaidLinkToken = localStorage.getItem('plaidLinkToken');
      if (bankConnectionId) plaidLinkToken = null; // need a new link token to relink
      const plaidRedirectURI = localStorage.getItem('plaidRedirectURI');

      if (!plaidLinkToken) {
        const args = {};

        if (bankConnectionId) {
          args.bankConnectionId = bankConnectionId;
        }

        if (routingNumber) {
          args.routingNumber = routingNumber;
        }

        // pass in bankConnectionId when it is a relink since this requires us to give plaid the original access_token
        const response = await this.$store.dispatchApiAction(
          'GET_PLAID_LINK_TOKEN',
          args,
        );
        plaidLinkToken = response.linkToken;
        localStorage.setItem('plaidLinkToken', plaidLinkToken);
      }

      plaidLinkRef.openWidget(plaidLinkToken, plaidRedirectURI);
    },
    clearPlaidOAuthData() {
      localStorage.removeItem('plaidLinkToken');
      localStorage.removeItem('plaidRedirectURI');
    },
    userIsInPlaidOAuthFlow() {
      return !!localStorage.getItem('plaidRedirectURI');
    },
    /**
     * We need to re-init Plaid Link with the receivedURI that they send back
     * We also need to redirect the user back to the page they were on before they were taken to the external OAuth page
     */
    setUpPlaidOAuthRedirectFlow(receivedURI) {
      localStorage.setItem('plaidRedirectURI', receivedURI);
      const pageToRedirectTo = localStorage.getItem('lastPageBeforeOAuth');
      localStorage.removeItem('lastPageBeforeOAuth');

      return pageToRedirectTo;
    },
  },
};

export const b2bNextRouteMixin = {
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'userHasEverHadActiveAdvance',
      'externalAccounts',
      'manualReadOnlyAccounts',
      'connectedBillingAccounts',
      'nextIncompleteB2BStep',
    ]),
  },
  methods: {
    async getB2BLoginRedirect() {
      await this.$store.dispatch('refreshAdvances');
      return this.userHasEverHadActiveAdvance
        ? 'home'
        : this.getB2BApplicationRedirect();
    },
    async getB2BApplicationRedirect() {
      if (this.$store.getters.businessId) {
        await Promise.all([
          this.$store.dispatchApiAction('FETCH_MANUAL_READ_ONLY_ACCOUNTS'),
          this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS'),
        ]);
      }

      if (
        this.productSegmentLabel === 'extend_runway' &&
        (this.connectedBillingAccounts.length ||
          this.manualReadOnlyAccounts.length)
      )
        return 'application';

      if (this.$store.getters.userId && this.$store.getters.businessId) {
        await Promise.all([
          this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS'),
          this.$store.dispatchApiAction('FETCH_USER_UPLOADS'),
        ]);
      }
      await this.$store.dispatch('UPDATE_ALL_B2B_SELFSERVE_STEPS');

      const { route } = this.nextIncompleteB2BStep;
      // go to the apply page if all b2b self serve steps are complete
      return !route || route === 'b2b-self-serve-submit-application'
        ? 'application'
        : route;
    },
  },
};

export const isMobileMixin = {
  methods: {
    isMobile() {
      return window.matchMedia('only screen and (max-width: 500px)').matches;
    },
  },
};

export const copyTextMixin = {
  methods: {
    async copyText(textToCopy) {
      const result = {};
      try {
        await navigator.clipboard.writeText(textToCopy);
        result.success = true;
      } catch (error) {
        result.error = true;
      }
      return result;
    },
  },
};

export const detectScrollMixin = {
  data() {
    return {
      onScroll: false,
      startPosition: true,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    // reset to false when return to widow.scrollY === 0
    scrolledWithReset() {
      return this.onScroll && !this.startPosition;
    },
  },
  methods: {
    handleScroll() {
      this.onScroll = true;
      this.startPosition = window.scrollY === 0;
    },
  },
};

export const downloadFileMixin = {
  computed: {
    ...mapGetters(['uploads']),
  },
  methods: {
    isBrowserSafari() {
      // Check if browser is Chrome on iOS: https://stackoverflow.com/questions/13807810/ios-chrome-detection/13808053#13808053
      if (navigator.userAgent.match('CriOS')) return false;
      // Check if browser is Safari: https://stackoverflow.com/questions/7944460/detect-safari-browser
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );
      return isSafari;
    },
    // Safari blocks window.open() when it is made inside an async call. Workaround is to open the window first, set the url after.
    async downloadFileSafari(fileId) {
      const newWindow = window.open();
      await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
        id: fileId,
        download: true,
      });
      const file = this.uploads.find((upload) => upload.id === fileId);
      newWindow.location = file.downloadUrl;
    },
    async downloadFileOther(fileId) {
      await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
        id: fileId,
        download: true,
      });
      const file = this.uploads.find((upload) => upload.id === fileId);
      window.open(file.downloadUrl);
    },
    async downloadFile(fileId) {
      if (this.isBrowserSafari()) {
        await this.downloadFileSafari(fileId);
      } else {
        await this.downloadFileOther(fileId);
      }
    },
  },
};
