import {
  DocumentsApi,
  BusinessDiligenceApi,
  ProfilingApi,
} from '@clearbanc/diligence-sdk';
import axios from 'axios';
import { auth0 } from '@/utils/auth0';

const baseUrl = process.env.DILIGENCE_SERVICE_URL;

const dcAxiosClient = axios.create({ baseUrl });

dcAxiosClient.interceptors.request.use(async (config) => {
  if (config.url.includes('user-invites')) {
    return config;
  }

  const token = await auth0.getAccessTokenSilently();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const diligenceService = new DocumentsApi(
  {
    basePath: baseUrl,
    isJsonMime: () => true,
  },
  undefined,
  dcAxiosClient,
);

export const businessDiligenceApi = new BusinessDiligenceApi(
  {
    basePath: baseUrl,
    isJsonMime: () => true,
  },
  undefined,
  dcAxiosClient,
);

export const profilingApi = new ProfilingApi(
  {
    basePath: baseUrl,
    isJsonMime: () => true,
  },
  undefined,
  dcAxiosClient,
);
