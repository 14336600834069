<template>
  <Modal
    v-model:visible="showModal"
    :header="$t('components.modalWoocommerce.connectYourWoo')"
    :modal="true"
    :draggable="false"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.WOOCOMMERCE, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.WOOCOMMERCE, 'close')"
  >
    <div :class="$style.container">
      <input-with-eyebrow
        v-model="wooCommAuth.storeUrl"
        :error-msg="$t('components.modalWoocommerce.errorMessage')"
        :placeholder="$t('components.modalWoocommerce.placeholder')"
        type="url"
        required
        name="WooCommerce Store URL"
        :class="$style.input"
        :validate-on-blur="validateOnBlur"
      />
      <Message
        v-if="!isStoreURLValid && showError"
        severity="error"
        :closable="false"
      >
        <p>{{ $t('components.modalWoocommerce.validStoreUrl') }}</p>
      </Message>
      <DSButton
        :label="$t('components.modalWoocommerce.connectWoo')"
        class="p-button-secondary"
        :class="$style.button"
        @click="continueWooCommerceOauth"
      />
    </div>
  </Modal>
</template>

<script>
import { isMobileMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { SALES_PLATFORMS } from '@/data/platforms';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    InputWithEyebrow,
    DSButton,
    Message,
    Modal,
  },
  mixins: [isMobileMixin],
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      wooCommAuth: {
        storeUrl: '',
      },
      showError: false,
      validateOnBlur: false,
      showModal: false,
    };
  },
  computed: {
    SALES_PLATFORMS: () => SALES_PLATFORMS,
    isStoreURLValid() {
      return (
        this.wooCommAuth.storeUrl.indexOf('https://') === 0 ||
        this.wooCommAuth.storeUrl.indexOf('http://') === 0
      );
    },
  },
  methods: {
    open() {
      this.wooCommAuth.storeUrl = '';
      this.showError = false;
      this.showModal = true;
    },
    async continueWooCommerceOauth() {
      this.validateOnBlur = true;
      this.showError = true;
      const error = await this.hasError();
      if (error || !this.isStoreURLValid) {
        this.$emit('error');
        return;
      }
      let storeUrl = `${this.wooCommAuth.storeUrl}`;
      const { authorizeUri } = OAUTH_PROVIDER_CONFIG.woocommerce;
      let toRemain = storeUrl.length;
      for (let i = storeUrl.length - 1; i > 0; i--) {
        if (storeUrl[i] !== '/') {
          toRemain = i + 1;
          break;
        }
      }
      storeUrl = storeUrl.substr(0, toRemain);
      const thisAuthorizeUri = authorizeUri.replace(
        'https://mywoocommerce.com',
        storeUrl,
      );
      const options = { authorizeUri: thisAuthorizeUri, storeUrl };
      if (this.isMobile()) {
        options.noPopup = true;
        options.redirectRoute = this.redirectRoute;
        localStorage.setItem('oauth-provider', SALES_PLATFORMS.WOOCOMMERCE);
        localStorage.setItem('oauth-redirect-route', this.redirectRoute);
      }
      this.$emit('submit', options);
      this.showModal = false;
    },
  },
};
</script>

<style lang="less" module>
.container {
  max-width: 400px;
  margin: 0 auto;
}
.input {
  margin: 1rem auto 2rem;
}
.button {
  display: block;
  margin: 0 auto;
}
</style>
