<script setup>
import { ref, defineExpose } from 'vue';
import { useRouter } from 'vue-router';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import Modal from '@clearbanc/clear-components/dialog';
import ModalControls from '@/components/modals/shared/ModalControls';

// -----
// Setup
// -----

const router = useRouter();

// ---------
// Variables
// ---------

const isModalVisible = ref(false);

// --------------
// Modal Controls
// --------------

function toggleModal() {
  isModalVisible.value = !isModalVisible.value;
}

function onConfirm() {
  router
    .push({
      name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
    })
    .catch(() => {});
}

// -----------------
// Component Options
// -----------------

defineExpose({ toggleModal });
</script>

<template>
  <Modal
    v-model:visible="isModalVisible"
    header="Confirmation of Owner and Director Information"
    :modal="true"
    :draggable="false"
    append-to="self"
    :style="{ maxWidth: '670px', textAlign: 'center' }"
  >
    <p>
      You certify, to the best of your knowledge, that you have provided us with
      accurate and complete beneficial ownership and director information.
    </p>

    <template #footer>
      <ModalControls
        :buttons="[
          {
            text: 'Cancel',
            action: toggleModal,
            classes: ['p-button-outlined'],
            attributes: {},
          },
          {
            text: 'Confirm',
            action: onConfirm,
            classes: [],
            attributes: {},
          },
        ]"
      />
    </template>
  </Modal>
</template>
