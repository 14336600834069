/* eslint-disable max-len */
import _ from 'lodash';
import Cookies from 'js-cookie';
import { isFuture, parseISO } from 'date-fns';
import {
  ADVANCE_SUBTYPES,
  ADVANCE_SUPRATYPES,
  VALID_ADVANCE_SUBTYPES,
} from '@/data/advance-subtypes';
import {
  SUPPORTED_SELF_SERVE_COUNTRY_CODES,
  COUNTRY_CODES,
} from '@/data/supported-country-codes';
import { BNPL_USER_CATEGORIES } from '@/data/payments';

export const signupStart = (state) => state.signupStart;
export const isImpersonatingUser = () => !!Cookies.get('cb-admin-token');

export const advances = (state) =>
  _.sortBy(_.values(state.advances), 'createdAt').reverse();

export const advanceOfferOptions = (state) => (advanceId) =>
  state.advances[advanceId]?.offerOptions || [];

export const advancesBySubtype = (state) =>
  _.keyBy(
    Object.values(state.advances).filter(
      ({ activatedAt, rejectedAt }) => !activatedAt && !rejectedAt,
    ),
    ({ subtype }) => subtype || 'other',
  );

export const activatedAdvancesBySubtype = (state) => {
  return _.groupBy(
    Object.values(state.advances).filter(
      (advance) => advance.activatedAt && !advance.rejectedAt,
    ),
    ({ subtype }) => subtype || 'other',
  );
};

// returns an object like { 12345: 'marketing', 5678: 'inventory', ...etc }
export const supratypeById = (state) => {
  return Object.values(state.advances).reduce((rest, adv) => {
    return {
      ...rest,
      [adv.id]: ADVANCE_SUPRATYPES[adv.subtype],
    };
  }, {});
};

// returns an object like { marketing: [12345, ...etc], inventory: [5678, ...etc] }
export const idsBySupratype = (state) => {
  const idsBySupratypeMap = Object.values(ADVANCE_SUPRATYPES).reduce((a, c) => {
    return {
      ...a,
      [c]: [],
    };
  }, {});

  Object.values(state.advances).forEach((adv) => {
    idsBySupratypeMap[ADVANCE_SUPRATYPES[adv.subtype]] = [
      ...idsBySupratypeMap[ADVANCE_SUPRATYPES[adv.subtype]],
      adv.id,
    ];
  });

  return idsBySupratypeMap;
};

// prioritize advances w/ amounts approved -> advances that are open to negotiate -> {}
export const advanceInNegotiation = (state, getters) =>
  _.find(
    getters.advances,
    (a) => !a.activatedAt && a.amountCents && !a.rejectedAt,
  ) ||
  _.find(getters.advances, (a) => !a.activatedAt && !a.rejectedAt) ||
  {};

export const bnplAdvanceInNegotiation = (state, getters) => {
  return (
    getters.advances.find(
      (a) => a.subtype === 'bnpl' && !a.activatedAt && !a.rejectedAt,
    ) || {}
  );
};

/**
 * a BNPL advance is considered valid if a user has selected
 * an offer by accepting the offer terms during BNPL flow
 */
export const validInactiveBnplAdvances = (state, getters) => {
  return getters.advances.filter(
    (a) => a.subtype === 'bnpl' && !!a.amountCents && !a.activatedAt,
  );
};

export const activeAdvances = (state, getters) =>
  _.filter(getters.advances, (a) => a.activatedAt && !a.settledAt);

export const validActiveAdvances = (state, getters) =>
  _.filter(
    getters.advances,
    (a) =>
      a.activatedAt &&
      !a.settledAt &&
      VALID_ADVANCE_SUBTYPES.includes(a.subtype),
  );

export const userHasActiveValidAdvances = (state, getters) =>
  _.some(
    getters.activeAdvances,
    (a) =>
      a.subtype === ADVANCE_SUBTYPES.VENTURES ||
      a.subtype === ADVANCE_SUBTYPES.UNIFIED ||
      a.subtype === ADVANCE_SUBTYPES.INVENTORY,
  );

export const settledAdvances = (state, getters) =>
  _.filter(getters.advances, (a) => a.settledAt);

export const settledValidAdvances = (state, getters) =>
  _.filter(
    getters.advances,
    (a) => a.settledAt && VALID_ADVANCE_SUBTYPES.includes(a.subtype),
  );

export const userHasPreviousBillAdvances = (state, getters) =>
  _.some(getters.advances, (a) => a.subtype === ADVANCE_SUBTYPES.BILLS);

export const userHasSettledBillAdvances = (state, getters) =>
  _.some(getters.settledAdvances, (a) => a.subtype === ADVANCE_SUBTYPES.BILLS);

export const userHasActiveBillAdvances = (state, getters) =>
  _.some(getters.activeAdvances, (a) => a.subtype === ADVANCE_SUBTYPES.BILLS);

export const userHasActiveBillsAdvanceAndDisconnectedSalesAccount = (
  state,
  getters,
) => getters.userHasActiveBillAdvances && getters.hasDisconnectedSalesAccounts;

export const userHasSettledValidAdvances = (state, getters) =>
  _.some(
    getters.advances,
    (a) =>
      a.settledAt &&
      (a.subtype === ADVANCE_SUBTYPES.UNIFIED ||
        a.subtype === ADVANCE_SUBTYPES.INVENTORY ||
        a.subtype === ADVANCE_SUBTYPES.VENTURES),
  );

export const userHasEverHadActiveAdvance = (state, getters) =>
  _.some(getters.advances, (a) => a.activatedAt);

export const userHasCashbackAdvance = (state, getters) =>
  !!getters.activeAdvances.find((a) => a.isCashbackActive);

export const userHasValidActiveAdvance = (state, getters) => {
  const { UNIFIED, INVENTORY, VENTURES, BNPL } = ADVANCE_SUBTYPES;

  return (
    getters.activeAdvances?.filter((advance) =>
      [UNIFIED, INVENTORY, VENTURES, BNPL].includes(advance.subtype),
    ).length > 0 ?? false
  );
};

export const userHasActiveOrSettledInventoryOrUnifiedAdvance = (
  state,
  getters,
) =>
  _.some(getters.advances, (a) => {
    const subtype =
      a.subtype === ADVANCE_SUBTYPES.UNIFIED ||
      a.subtype === ADVANCE_SUBTYPES.INVENTORY;
    const activated = a.activatedAt;

    return subtype && activated;
  });

export const activeInventoryAdvance = (state, getters) =>
  getters.activeAdvances.find(
    (advance) => advance.subtype === ADVANCE_SUBTYPES.INVENTORY,
  );

export const activeMarketingAdvance = (state, getters) =>
  getters.activeAdvances.find(
    (advance) => advance.subtype === ADVANCE_SUBTYPES.UNIFIED,
  );

export const activeVenturesAdvance = (state, getters) =>
  getters.activeAdvances.find((advance) => advance.subtype === 'ventures');

export const userHasOfferToBeSigned = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  for (const advanceType of [
    ADVANCE_SUBTYPES.UNIFIED,
    ADVANCE_SUBTYPES.INVENTORY,
  ]) {
    const advance = getters.advancesBySubtype[advanceType];
    const { advanceContracts } = rootGetters;
    if (
      !(advanceContracts && advanceContracts[advance?.id]?.userSignedAt) &&
      !!Number(advance?.amountCents) &&
      advance.offerOptions?.length
    ) {
      return true;
    }
  }
  return false;
};

export const hasAvailableValidAdvanceSpend = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  return (
    rootGetters.totalCreditRemainingCents > 0 ||
    rootGetters.totalInventoryCreditRemainingCents > 0 ||
    rootGetters.totalVenturesCreditRemainingCents > 0
  );
};

// Users can have paid back their advance (settled it) but still have available spend
// We should treat these users as still "active" so they can use the remaining funds we owe them
export const activeValidAdvanceOrAvailableValidSpend = (state, getters) => {
  return (
    getters.userHasActiveValidAdvances ||
    (getters.hasAvailableValidAdvanceSpend &&
      !getters.userHasActiveValidAdvances &&
      getters.userHasSettledValidAdvances)
  );
};

export const isClosedLostValidAdvanceUser = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  if (getters.activeValidAdvanceOrAvailableValidSpend) {
    return false;
  }

  for (const advanceType of [
    ADVANCE_SUBTYPES.UNIFIED,
    ADVANCE_SUBTYPES.INVENTORY,
    ADVANCE_SUBTYPES.VENTURES,
  ]) {
    const advance = getters.advancesBySubtype[advanceType];
    const advanceContracts = rootGetters.advanceContracts;

    if (
      advance &&
      advance.offerOptions?.length &&
      !(advanceContracts && advanceContracts[advance?.id]?.userSignedAt)
    ) {
      return advance.offerOptions.some(
        (offer) => new Date(offer.expiresAt) < new Date(),
      );
    }
  }
  return false;
};

export const historicalValidAdvanceUser = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  return (
    !getters.activeValidAdvanceOrAvailableValidSpend &&
    getters.userHasSettledValidAdvances
  );
};
export const userHasUnifiedAdvanceOffer = (state, getters) => {
  const advance = getters.advancesBySubtype[ADVANCE_SUBTYPES.UNIFIED];

  if (advance && advance.offerOptions?.length) {
    return advance.offerOptions.some((offer) =>
      isFuture(parseISO(offer.expiresAt)),
    );
  }

  return false;
};
export const userHasAdvanceOffer = (state, getters, rootState, rootGetters) => {
  for (const advanceType of [
    ADVANCE_SUBTYPES.UNIFIED,
    ADVANCE_SUBTYPES.INVENTORY,
  ]) {
    const advance = getters.advancesBySubtype[advanceType];
    const advanceContracts = rootGetters.advanceContracts;

    if (
      advance &&
      advance.offerOptions?.length &&
      !(advanceContracts && advanceContracts[advance?.id]?.userSignedAt)
    ) {
      return advance.offerOptions.some((offer) =>
        isFuture(parseISO(offer.expiresAt)),
      );
    }
  }

  return false;
};

export const getAdjudication = (state) => state.adjudication;

export const ecomRevenue = (state) => state.ecomRevenue;

// all advance offerOptions (including expired)
export const advanceOffers = (state, getters) => {
  const { offerOptions } = getters.advanceInNegotiation;

  return [...(offerOptions || [])].sort(
    (a, b) => a.repaymentRate - b.repaymentRate,
  );
};

// expired advance offerOptions
export const inactiveAdvanceOffers = (state, getters) => {
  return getters.advanceOffers.filter(
    (offer) => new Date(offer.expiresAt) < new Date(),
  );
};

// selected & non-expired advance offerOptions
export const activeAdvanceOffers = (state, getters) => {
  return getters.advanceOffers.filter(
    (offer) => offer.selectedAt || new Date(offer.expiresAt) > new Date(),
  );
};

// advances with selected offerOptions
export const offerOptionSelectedAdvances = (state, getters) => {
  return getters.advances.filter(
    (advance) =>
      !!advance.offerOptions?.length &&
      _.some(
        advance.offerOptions,
        (offer) => offer.selectedAt && new Date(offer.expiresAt) > new Date(),
      ),
  );
};

/**
 * return first active advance currency code if exists
 * otherwise return the first advance currency code is exists any
 * otherwise return USD as default
 */
export const getAdvanceCurrencyCode = (state, getters) => {
  if (getters.activeAdvances.length > 0) {
    return getters.activeAdvances[0].currencyCode;
  } else if (getters.advances.length > 0) {
    return getters.advances[0].currencyCode;
  }

  return 'USD';
};

export const latestAdvanceRejected = (state, getters) => {
  return !!getters.advances[0]?.rejectedAt;
};

export const isSupportedSelfServeCountry = (state, getters) => {
  return SUPPORTED_SELF_SERVE_COUNTRY_CODES.includes(
    getters.businessCorpCountry ?? null,
  );
};

export const advanceFeesInfo = (state) => {
  return state.advanceFeesInfo;
};

export const dateFunded = (state, getters) => {
  return getters.activeAdvances
    .filter((advance) => advance.activatedAt)
    .sort((a, b) => new Date(a.activatedAt) - new Date(b.activatedAt))[0]
    ?.activatedAt;
};

export const topUpOffersAvailable = (state, getters) => {
  return getters.activeAdvanceOffers.some(
    (activeAdvance) =>
      activeAdvance.topUpAmount !== 0 || activeAdvance.topUpAmountCents !== '0',
  );
};

export const calculatePreferredRates = (state, getters) => (rates) => {
  return {
    preferred: rates?.fee - rates?.preferredCashbackRate,
    nonPreferred: rates?.fee - rates?.nonPreferredCashbackRate,
  };
};

export const customRatesCappedIrr = (_state, getters) => (rates) => {
  return {
    preferred: rates.cappedIrrFee - rates.preferredCashbackRate,
    nonPreferred: rates.cappedIrrFee - rates.nonPreferredCashbackRate,
  };
};

export const autocompleteEnabled = (state) => {
  return state.autocompleteEnabled;
};

export const currentStateModule = (state) => state.currentStateModule;

export const facebookUrl = (state) => state.facebookUrl;

export const contactDetails = (state, getters) => {
  switch (getters.business.corpCountry) {
    case COUNTRY_CODES.DE:
      return {
        link: 'tel:+442038856739',
        number: '+44 (20) 3885-6739',
      };
    case COUNTRY_CODES.AU:
      return {
        link: 'tel:+61255023124',
        number: '+61 255 023 124',
      };
    case COUNTRY_CODES.CA:
      return {
        link: 'tel:+16475608775',
        number: '+1 (647) 560-8775',
      };
    case COUNTRY_CODES.AT:
    case COUNTRY_CODES.BE:
    case COUNTRY_CODES.FI:
    case COUNTRY_CODES.NL:
    case COUNTRY_CODES.GB:
    case COUNTRY_CODES.IE:
    case COUNTRY_CODES.DK:
    case COUNTRY_CODES.SE:
    case COUNTRY_CODES.CH:
      return {
        link: 'tel:+442038680308',
        number: '+44 (20) 3868-0308',
      };
    // default US
    default:
      return {
        link: 'tel:+14156105166',
        number: '+1 (415) 610-5166',
      };
  }
};

/**
 * PROJECT PHOENIX
 */

export const userCategory = (state, getters) => {
  if (getters.isClosedLostValidAdvanceUser) {
    return BNPL_USER_CATEGORIES.CLOSED_LOST_VALID_ADVANCE;
  } else if (getters.activeValidAdvanceOrAvailableValidSpend) {
    return BNPL_USER_CATEGORIES.ACTIVE_VALID_ADVANCE;
  } else if (getters.historicalValidAdvanceUser) {
    return BNPL_USER_CATEGORIES.PAST_VALID_ADVANCE_ONLY;
  }
  return BNPL_USER_CATEGORIES.NET_NEW;
};

// No access of Phoenix
export const pureValidAdvanceUser = (state, getters) => {
  // remove?
  return (
    (getters.business.corpCountry === COUNTRY_CODES.US && // US users with active MCA
      getters.activeValidAdvanceOrAvailableValidSpend) || // OR
    (!getters.isAMERBusiness && // International users with active or settled MCAs.
      (getters.activeValidAdvanceOrAvailableValidSpend ||
        getters.userHasSettledValidAdvances))
  );
};

// they dont have spend, active advance or a settled advance
export const netNewUser = (state, getters) => {
  return (
    !getters.activeValidAdvanceOrAvailableValidSpend &&
    !getters.userHasSettledValidAdvances
  );
};

export const netNewInternationalUser = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  return (
    !rootGetters.isAMERBusiness &&
    !getters.activeValidAdvanceOrAvailableValidSpend &&
    !getters.userHasSettledValidAdvances
  );
};

export const showLegacyModal = (state) => state.showLegacyModal;
