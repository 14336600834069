<template>
  <transition
    :enter-active-class="isMobile() ? $style['slide-enter-active'] : ''"
    :leave-active-class="isMobile() ? $style['slide-leave-active'] : ''"
  >
    <div v-if="showModal" :class="classes">
      <div ref="modal" :class="$style.modal">
        <ProgressBarOnboarding
          v-if="showProgressBar && step !== 2"
          :percent-complete="trackPercentageComplete"
          :class="$style['progress-bar']"
          @resize="emitResizeEvent()"
        />

        <div
          v-if="showCloseButton"
          :class="$style['close-icon-container']"
          data-cy="modal-close"
          @click="close"
        >
          <svg
            :class="$style.icon"
            v-html="require('@/assets/icons/close-icon.svg')"
          ></svg>
        </div>

        <div :class="$style.title" data-cy="onboarding-modal-title">
          {{ title }}
        </div>
        <div :class="$style.content">
          <slot></slot>
        </div>
        <div :class="$style.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { isMobileMixin } from '@/utils/vue-mixins';
import ProgressBarOnboarding from '@/components/progress/ProgressBarOnboarding';

export default {
  components: {
    ProgressBarOnboarding,
  },
  mixins: [isMobileMixin],
  props: {
    title: String,
    visible: Boolean,
    large: Boolean,
    largest: Boolean,
    align: { type: String, default: 'center' },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 0,
    },

    trackPercentageComplete: {
      type: Number,
      default: 30,
    },
    showProgressBar: {
      default: false,
      type: Boolean,
    },
    shouldCloseModal: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style['modal-container']]: true,
        [this.$style.large]: this.large,
        [this.$style.largest]: this.largest,
        [this.$style['align-left']]: this.align === 'left',
        [this.$style['align-center']]: this.align === 'center',
        [this.$style['align-right']]: this.align === 'right',
      };
    },
    showModal() {
      return this.isOpen || this.visible;
    },
  },
  mounted() {
    if (this.visible) {
      this.open();
    }
  },
  unmounted() {
    window.removeEventListener('keyup', this.onKeyUp);
  },
  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
      window.addEventListener('keyup', this.onKeyUp);
    },
    close() {
      this.$emit('close');
      if (this.shouldCloseModal) {
        this.isOpen = false;

        window.removeEventListener('keyup', this.onKeyUp);
      }
    },
    onKeyUp(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
  },
};
</script>

<style lang="less" module>
.slide-enter-active {
  animation: slide-up 0.5s;
}
.slide-leave-active {
  animation: slide-up 0.5s reverse;
}
.modal-container {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: @font-size-15;
  height: 100%;
  width: 96%;
  position: fixed;
  display: flex;
  top: 0;
  left: 2%;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 200;

  .modal {
    position: relative;
    min-height: 300px;
    width: 540px;
    background: @color-white;
    opacity: 1;
    border-radius: 4px;
    transition: top 2s;
    overflow: auto;
    max-height: 100vh;

    .title {
      padding: 0 30px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 29px;
      color: @onboarding-dark-blue;
      margin-top: 60px;
    }

    .content {
      font-size: 16px;
      font-weight: normal;
      margin: 0 auto;
      padding: 25px 20px;
      color: @medium-gray;
    }
    .close-icon-container {
      background: @self-serve-nav-bar-gray;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: absolute;
      display: flex;
      top: 28px;
      right: 26px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        height: 12px;
        width: 12px;
      }
    }
    .progress-bar {
      position: absolute;
      top: 48px;
      left: 28px;
      width: 260px;

      /* Extra small devices (phones, 500px and down) */
      @media only screen and (max-width: 500px) {
        width: 200px;
        top: 40px;
        left: 20px;
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        width: 370px;
        top: 48px;
        left: 20px;
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        width: 510px;
        top: 48px;
        left: 20px;
      }

      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        width: 600px;
      }
    }

    @media only screen and (max-width: 500px) {
      border-radius: 40px 40px 0 0;
      max-height: unset;
      height: 100%;
      overflow: scroll;

      .title {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        padding-left: 20px;
        max-width: 335px;
      }
      .close-icon-container {
        top: 20px;
        right: 20px;
      }
    }

    .large& {
      min-width: 800px;
    }

    .largest& {
      width: 100%;
      max-width: 1200px;
    }

    .align-left& {
      text-align: left;
    }

    .align-right& {
      text-align: right;
    }

    .align-center& {
      text-align: center;
    }
  }

  .footer {
    background: #efeee7;
  }

  &::before {
    content: '';
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    background: @color-black;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    left: 0;

    &.large .modal {
      min-width: unset;
      width: 100%;
    }
  }
}
@keyframes slide-up {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
</style>
