<template>
  <div :class="$style['connect-accounts-container']">
    <LayoutConnectAccounts
      :show-error="showError && !errorMessage"
      :show-encryption-message="false"
      :event-tracking-fields="commonTrackingFields"
    >
      <template #titles>
        <h2 :class="$style.heading">
          {{ $t('paymentsPage.verifyRevenue') }}
        </h2>
        <p :class="$style['sub-heading']">
          {{ $t('paymentsPage.connectAtLeastOneSalesPlatform') }}
        </p>
      </template>

      <template v-if="errorMessage && showError" #errorMessage>
        <Message severity="error" :closable="false">
          <p>
            {{ errorMessage }}
            Need help? You can reach us by phone at +1 (415) 610-5166 (9AM - 6PM
            ET) or by email at
            <a href="mailto:support@clear.co">support@clear.co</a>.
          </p>
        </Message>
      </template>

      <EncryptionMessage />
      <TileGroupConnectSales
        ref="tileGroup"
        v-model:show-error="showError"
        v-model:error-message="errorMessage"
        :event-tracking-fields="commonTrackingFields"
        load-all-platforms
      />
      <template #cta>
        <div :class="$style['buttons-container']">
          <ButtonLinkContainer
            :text="$t('common.buttons.nextStep')"
            center-cta
            :class="$style['next-btn']"
            @nextClick="nextStep"
          />
        </div>
      </template>
    </LayoutConnectAccounts>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import { mapStateModuleGetters } from '@/utils/state-modules';
import TileGroupConnectSales from '@/components/TileGroupConnectSales';
import Message from '@clearbanc/clear-components/message';
import { ButtonLinkContainer, LayoutConnectAccounts } from '@/components';
import EncryptionMessage from '@/components/EncryptionMessage';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { useProfiling } from '@/composables/useProfiling';

export default {
  components: {
    ButtonLinkContainer,
    TileGroupConnectSales,
    LayoutConnectAccounts,
    EncryptionMessage,
    Message,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
      errorMessage: '',
    };
  },
  setup() {
    const store = useStore();
    const profiling = useProfiling(
      PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
      {
        type: store.getters.eventTypeBasedOnLoginsCount,
      },
    );

    return { profiling };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'connectedSalesAccounts']),
    ...mapStateModuleGetters(['steps', 'experimentName']),
    hasConnectedSalesAccounts() {
      return this.connectedSalesAccounts.length;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        experiment: this.experimentName,
        targetArea: this.$route.name,
      };
    },
  },
  methods: {
    nextStep() {
      this.errorMessage = '';
      if (!this.hasConnectedSalesAccounts) {
        this.showError = true;
        return;
      }

      this.profiling.finish();

      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_MARKETING,
      });
    },
  },
};
</script>

<style lang="less" module>
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-btn {
  button {
    background-color: @color-purple-2022;
    &:hover {
      background-color: @color-purple-2022 !important;
    }
  }
}

h2.heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 24px;
}

.sub-heading {
  margin: 0 auto 20px;
  max-width: 550px;
}

@media screen and (max-width: 500px) {
  h2.heading,
  .sub-heading {
    text-align: left;
  }
}
</style>
