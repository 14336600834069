import { USER_INVITE } from '@/data/routes';
import UserInvite from '@/pages/user-invite';
import UserInviteSuccess from '@/pages/user-invite/success';
import UserInviteError from '@/pages/user-invite/error';
import UserInviteVerify from '@/pages/user-invite/verify';

export default [
  {
    path: '/user-invite',
    component: UserInvite,
    children: [
      {
        path: 'success',
        name: USER_INVITE.SUCCESS,
        component: UserInviteSuccess,
      },
      {
        path: 'error',
        name: USER_INVITE.ERROR,
        component: UserInviteError,
      },
      {
        path: 'verify/:uuid',
        name: USER_INVITE.VERIFY,
        component: UserInviteVerify,
      },
    ],
  },
];
