// Base64 expands data size by ~33%
const BYTES_PER_BASE64_CHAR = 3 / 4;
const BYTES_PER_MB = 1024 * 1024;

export async function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const result = reader.result;
        const base64Data =
          typeof result === 'string' ? result.split(',')[1] : '';
        resolve(base64Data);
      } catch (error) {
        reject(new Error('Error processing file'));
      }
    };

    reader.onerror = () =>
      reject(new Error('FileReader failed to read the file'));
    reader.readAsDataURL(file);
  });
}

export function getBase64FileSize(fileBase64) {
  if (!fileBase64?.data) {
    return 0;
  }
  const base64Data = fileBase64.data.split(',')[1] || fileBase64.data;
  const sanitizedData = base64Data.replace(/\s/g, '');

  // Calculate size in bytes
  const sizeInBytes = sanitizedData.length * BYTES_PER_BASE64_CHAR;
  return sizeInBytes / BYTES_PER_MB;
}

export function validateMaxFileSize(file, maxSize, errorMessage) {
  if (file.size > maxSize) {
    const maxSizeMB = (maxSize / 1024 / 1024).toFixed(2);
    return (
      errorMessage ??
      `Upload failed because file size exceeds ${maxSizeMB}mb. Please upload a smaller file or contact support for assistance.`
    );
  }
  return undefined;
}
