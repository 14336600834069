import { authGuard } from '@auth0/auth0-vue';
import store from '@/store';
import { OWNERS, USER } from '@/data/routes';
import PaymentBizProfileBusinessOwners from '@/pages/payments/business-profile/business-owners';
import BusinessProfileEditOwner from '@/pages/payments/business-profile/owners/edit-owner';
import BusinessOwners from '@/pages/payments/business-profile/owners/list/index-new';

export default [
  {
    path: 'owners',
    component: PaymentBizProfileBusinessOwners,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: OWNERS.INDEX,
        alias: '/pay-vendors/business-profile/owners/list',
        component: BusinessOwners,
        props: () => ({
          theme: 'purple',
          ctaLangKey: 'common.buttons.finish',
        }),
      },
      {
        path: 'new',
        alias: '/pay-vendors/business-profile/owners/new',
        name: OWNERS.NEW,
        component: BusinessProfileEditOwner,
      },
      {
        path: ':id',
        name: OWNERS.EDIT,
        component: BusinessProfileEditOwner,
        beforeEnter: (to) => {
          const selectedOwner = store.getters.allOwners.find(
            (owner) => owner.id === to.params.id,
          );
          if (
            !selectedOwner ||
            store.getters.ownerHasPendingBillContractOrActiveAdvance
          ) {
            return { name: OWNERS.INDEX };
          }
          if (selectedOwner.isPrimary) {
            return {
              name: USER.INDEX,
            };
          }
          return true;
        },
      },
    ],
  },
];
