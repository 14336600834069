import { useStore } from 'vuex';

export function useDownloadFile() {
  const store = useStore();

  function isBrowserSafari() {
    // Check if browser is Chrome on iOS: https://stackoverflow.com/questions/13807810/ios-chrome-detection/13808053#13808053
    if (navigator.userAgent.match('CriOS')) return false;
    // Check if browser is Safari: https://stackoverflow.com/questions/7944460/detect-safari-browser
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return isSafari;
  }

  async function downloadFileSafari(fileId) {
    const newWindow = window.open();
    await store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
      id: fileId,
      download: true,
    });
    const file = store.getters.uploads.find((upload) => upload.id === fileId);
    newWindow.location = file.downloadUrl;
  }

  async function downloadFileOther(fileId) {
    await store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
      id: fileId,
      download: true,
    });
    const file = store.getters.uploads.find((upload) => upload.id === fileId);
    window.open(file.downloadUrl);
  }

  async function downloadFile(fileId) {
    if (isBrowserSafari()) {
      await downloadFileSafari(fileId);
    } else {
      await downloadFileOther(fileId);
    }
  }

  return { downloadFile };
}
