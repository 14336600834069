import { authGuard } from '@auth0/auth0-vue';
import { CONNECTED_ACCOUNTS } from '@/data/routes';

import VendorsPage from '@/pages/account/vendors/page-vendors';
import PaymentsBizProfileDashboard from '@/pages/payments/business-profile';

import PaymentBizProfileConnectAccounts from '@/pages/payments/business-profile/connect-accounts';
import PaymentBizProfileConnectMarketing from '@/pages/payments/business-profile/connect-marketing';

export default [
  {
    path: '/connected-accounts',
    component: VendorsPage,
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      displayNav: true,
    },
    children: [
      {
        path: '',
        component: PaymentsBizProfileDashboard,
        children: [
          {
            path: '',
            alias: '/pay-vendors/business-profile/connected-accounts',
            name: CONNECTED_ACCOUNTS.INDEX,
            component: PaymentBizProfileConnectAccounts,
          },
          {
            path: 'marketing',
            alias: '/pay-vendors/business-profile/connect-marketing',
            name: CONNECTED_ACCOUNTS.MARKETING,
            component: PaymentBizProfileConnectMarketing,
          },
        ],
      },
    ],
  },
];
