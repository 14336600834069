import { authGuard } from '@auth0/auth0-vue';
import { DATA_SECURITY } from '@/data/routes';
import DataSecurityPage from '@/pages/account/data-security/page-data-security';
import ExportDataPage from '@/pages/account/data-security/page-export-data';
import RemoveDataPage from '@/pages/account/data-security/page-remove-data';

export default [
  {
    path: 'data-security',
    beforeEnter: authGuard,
    meta: {
      displayNav: true,
    },
    children: [
      {
        path: '',
        name: DATA_SECURITY.INDEX,
        component: DataSecurityPage,
      },
      {
        path: 'remove',
        name: DATA_SECURITY.REMOVE,
        component: RemoveDataPage,
      },
      {
        path: 'export',
        name: DATA_SECURITY.EXPORT,
        component: ExportDataPage,
      },
    ],
  },
];
