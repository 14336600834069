<template>
  <div v-if="!isLoading" data-cy="connect-bank-account-modal">
    <LayoutConnectAccounts
      :show-encryption-message="false"
      :show-error="error.show"
      :is-bank-connect-error="error.isBankConnectError"
      :is-bank-statements-error="error.isBankStatementsError"
      :event-tracking-fields="commonTrackingFields"
    >
      <!-- titles and tooltips -->
      <template #titles>
        <SubtitleWithTooltip
          v-if="showLinkAccounts"
          subtitle-text="Select your business' primary account"
          tooltip-text="We automate debits so you never need to worry about it."
        />
        <p :class="$style['explainer-text']">
          Your primary business banking account will be used to auto-debit your
          weekly payments and if applicable, deposit funding for receipts.
        </p>
      </template>
      <!-- connect accounts -->
      <ContainerConnectBankAccounts
        v-show="showConnectAccounts"
        ref="connectBanking"
        v-model:show-error="error.show"
        :event-tracking-fields="commonTrackingFields"
        @plaidSuccess="handlePlaidSuccess"
        @plaidError="handlePlaidError"
        @exit="plaidLoading = false"
      />
      <!-- link accounts -->
      <TileGroupBankLinkConfirmation
        v-show="showLinkAccounts"
        ref="linkBanking"
        v-model:error="error"
        :event-tracking-fields="commonTrackingFields"
        @validationSuccess="handleValidationSuccess"
        @isPlaidLoading="
          (isLoading) => {
            plaidLoading = isLoading;
          }
        "
      />
    </LayoutConnectAccounts>
    <SkipForNow
      :button-text="ctaText"
      :allow-next="true"
      @skip="setSkipChecked"
      @click="handleCtaClick"
    />
  </div>
  <LoadingScreen v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';

import { LayoutConnectAccounts, SubtitleWithTooltip } from '@/components';
import TileGroupBankLinkConfirmation from '@/components/TileGroupBankLinkConfirmation';
import ContainerConnectBankAccounts from '@/components/ContainerConnectBankAccounts';
import LoadingScreen from '@/components/LoadingScreen';
import SkipForNow from '@/components/payments/SkipForNow';

const ctaLabels = {
  done: 'Done',
  tryAgain: 'Try Again',
  next: 'Next',
};

export default {
  components: {
    SkipForNow,
    ContainerConnectBankAccounts,
    LayoutConnectAccounts,
    SubtitleWithTooltip,
    TileGroupBankLinkConfirmation,
    LoadingScreen,
  },
  mixins: [stateModuleAware],
  props: {
    partOfAFlow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plaidLoading: false,
      isLoading: false,
      skipChecked: false,
      error: {
        show: false,
        isBankConnectError: false,
        isBankStatementsError: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'experimentName',
      'business',
      'isConnectBankingStepComplete',
      'isBankLinkConfirmationStepComplete',
    ]),
    ...mapStateModuleGetters(['experimentName']),
    ctaText() {
      if (this.error.isBankConnectError && !this.skipChecked) {
        return ctaLabels.tryAgain;
      }
      if (!this.partOfAFlow && this.skipChecked) {
        return ctaLabels.done;
      }
      return ctaLabels.next;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        experiment: this.experimentName,
        targetArea: this.$route.name,
      };
    },
    showConnectAccounts() {
      return !this.isConnectBankingStepComplete;
    },
    showLinkAccounts() {
      return !this.showConnectAccounts;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
    const plaidStepsComplete =
      this.isConnectBankingStepComplete &&
      this.isBankLinkConfirmationStepComplete;

    if (plaidStepsComplete) {
      // account connected & linked - skip tab
      this.done();
    }

    this.isLoading = false;
  },
  methods: {
    done() {
      this.$emit('next-tab');
    },
    resetError() {
      this.error = {
        show: false,
        isBankConnectError: false,
        isBankStatementsError: false,
      };
    },
    setSkipChecked(value) {
      this.skipChecked = value;
    },
    handleCtaClick() {
      if (this.skipChecked) {
        this.done();
      } else {
        this.resetError();
        if (this.showLinkAccounts) {
          this.$refs.linkBanking.validate();
        } else if (this.showConnectAccounts) {
          this.plaidLoading = true;
          this.$refs.connectBanking.openBankingIntegration();
        } else {
          this.plaidLoading = false;
          this.done();
        }
      }
    },
    handlePlaidSuccess() {
      this.plaidLoading = false;
      if (!this.isConnectBankingStepComplete) {
        this.$refs.connectBanking.showPlaidPreview = true;
      }
    },
    handlePlaidError() {
      this.plaidLoading = false;
      this.error.show = true;
      this.error.isBankConnectError = true;
      this.$refs.connectBanking.showPlaidPreview = true;
    },
    handleValidationSuccess() {
      if (this.isBankLinkConfirmationStepComplete) {
        this.plaidLoading = false;
        this.done();
      } else {
        this.error = { show: true, isBankConnectError: false };
      }
    },
  },
};
</script>

<style lang="less" module>
.explainer-text {
  max-width: 615px;
  &:first-child {
    margin-top: 0;
  }
}
div[class^='inner-container'] div[class^='spinner'] {
  margin: 0 auto;
}
</style>
