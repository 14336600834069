import { authGuard } from '@auth0/auth0-vue';
import { BANKING } from '@/data/routes';

import PaymentBizProfileConnectBanking from '@/pages/payments/business-profile/connect-banking';
import PaymentBizProfileBankStatements from '@/pages/payments/business-profile/BankStatements';

export default [
  {
    path: 'banking',
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: BANKING.INDEX,
        alias: '/pay-vendors/business-profile/banking',
        component: PaymentBizProfileConnectBanking,
      },
      {
        path: ':id',
        name: BANKING.STATEMENTS,
        component: PaymentBizProfileBankStatements,
      },
      // This is to keep legacy route working
      {
        path: '/pay-vendors/business-profile/banking/:id/statements',
        name: BANKING.STATEMENTS_LEGACY,
        component: PaymentBizProfileBankStatements,
      },
    ],
  },
];
