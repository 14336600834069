<template>
  <div :class="$style.container">
    <div
      v-if="displayClearcoLogo || percentComplete"
      :class="$style['header-container']"
    >
      <div :class="$style['logo-container']">
        <div
          v-if="displayClearcoLogo"
          :class="$style.logo"
          data-cy="logo"
          v-html="logoSVG"
        ></div>
        <slot name="logo"></slot>
        <ProgressBarOnboarding
          v-if="percentComplete"
          :class="$style['progress-bar']"
          :percent-complete="percentComplete"
        ></ProgressBarOnboarding>
      </div>
    </div>
    <div :class="$style['content-container']">
      <div
        v-if="$slots.title"
        data-cy="onboarding-ui-title"
        :class="$style.title"
      >
        <slot name="title"></slot>
      </div>
      <Subtitle v-if="$slots.subtitle" :class="$style.subtitle"
        ><slot name="subtitle"></slot>
      </Subtitle>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Subtitle from '@/components/Subtitle';
import ProgressBarOnboarding from '@/components/progress/ProgressBarOnboarding';

export default {
  components: { ProgressBarOnboarding, Subtitle },
  props: {
    percentComplete: { type: Number },
    displayClearcoLogo: { type: Boolean, default: true },
  },
  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
    };
  },
};
</script>

<style lang="less" module>
.container {
  max-width: 900px;
  height: max-content;
  margin: @gutter-75 auto @gutter-40;
  border: 1px solid @onboarding-light-gray;
  border-radius: 8px;
  padding: @gutter-40;
  font-family: 'Montserrat';
  color: @dark-black;
  display: flex;
  flex-direction: column;
  align-items: center;

  // mobile
  @media only screen and (max-width: 500px) {
    align-items: unset;
    padding: unset;
  }

  // tablets
  @media only screen and (max-width: 960px) {
    margin: unset;
    border: none;
  }
  .header-container {
    position: relative;
    width: 100%;

    @media only screen and (max-width: 500px) {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 7;
    }

    .back-link {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      font-weight: 500;
      font-family: @gerstner-font;
      color: @color-info-400;
      text-decoration: none;
      @media only screen and (max-width: 500px) {
        right: 20px;
        z-index: 10;
        top: 15px;
      }
    }

    .back-icon {
      width: 8px;
      height: 16px;
      margin-right: 15px;
    }

    & + .content-container {
      margin-top: 30px;
    }
  }
  .logo-container {
    .logo {
      margin: 0 0 @gutter-30;
      display: flex;
      justify-content: center;
      svg {
        width: 130px;
        height: 100%;
        fill: @color-black;
      }
      // mobile
      @media only screen and (max-width: 500px) {
        margin: unset;
        height: 55px;
        background: @color-white;
        box-shadow: 0 3px 3px #cacaca;
        justify-content: left;
        padding: 0 20px;
      }
    }
  }
  .progress-bar {
    width: 40vw;
    max-width: 600px;
    margin: 0 auto;

    @media only screen and (max-width: 500px) {
      width: 100vw;
    }
  }

  .content-container {
    text-align: center;
    width: 100%;
    @media only screen and (max-width: 500px) {
      margin: unset;
      text-align: left;
      padding: @gutter-20;
    }

    .title {
      font-size: 21px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .subtitle {
      max-width: 40vw;
      margin: auto;
      position: relative;

      @media only screen and (max-width: 500px) {
        margin: unset !important;
      }
      // this breakpoint is to prevent the tooltip from appearing off-screen
      @media only screen and (max-width: 710px) {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
</style>
