import store from '@/store';
import { authGuard } from '@auth0/auth0-vue';
import { auth0 } from '@/utils/auth0';
import { ONBOARDING } from '@/data/routes';
import PaymentsOnboardingFlow from '@/pages/payments';
import PaymentsUserProfilePage from '@/pages/payments/profile-details';
import PaymentsBusinessDetailsPage from '@/pages/payments/business-details';
import PaymentsRegisteredAddressPage from '@/pages/payments/registered-address';

export default [
  {
    path: '/onboarding',
    name: ONBOARDING.INDEX,
    component: PaymentsOnboardingFlow,
    beforeEnter: (to) => {
      const incompleteStep =
        store.getters.firstIncompletePaymentsOnboardingStep;
      if (!!auth0.isAuthenticated?.value && incompleteStep?.name !== to.name) {
        return {
          name: incompleteStep.name,
        };
      }
      return authGuard(to);
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        alias: '/payments/onboarding/user-profile',
        name: ONBOARDING.USER,
        component: PaymentsUserProfilePage,
      },
      {
        path: 'business',
        alias: '/payments/onboarding/business-details',
        name: ONBOARDING.BUSINESS,
        component: PaymentsBusinessDetailsPage,
      },
      {
        path: 'address',
        alias: '/payments/onboarding/registered-address',
        name: ONBOARDING.ADDRESS,
        component: PaymentsRegisteredAddressPage,
      },
    ],
  },
];
