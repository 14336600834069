<template>
  <div :class="$style.container">
    <h1 :class="$style.heading">Documents</h1>
    <div :class="$style['navigation-wrapper']">
      <Dropdown
        v-model="activeCategory"
        :class="$style['navigation-mobile']"
        :options="categories"
        placeholder="Select"
        option-label="title"
        option-value="title"
        append-to="self"
        @change="changeCategory(activeCategory)"
      />
      <ul :class="$style.navigation">
        <li v-for="category in categories" :class="$style['navigation__item']">
          <button
            :class="[
              $style.button,
              category.title === activeCategory && $style['button--active'],
            ]"
            :disabled="category.title === activeCategory"
            :data-cy="`tab-${category.title}`"
            @click="changeCategory(category.title)"
          >
            <span :class="$style.button__icon">
              <span
                class="material-symbols-outlined"
                :class="$style.button__icon__symbol"
                >{{ category.icon }}</span
              >
            </span>
            {{ category.title }}
          </button>
        </li>
      </ul>
    </div>
    <div :class="$style.content">
      <RequestedDocumentsTable
        v-if="hasActiveDocumentRequests"
        :format-type="formatType"
        :is-loading="isLoading"
        @openUploadModal="openUploadModal"
      />

      <div :class="$style['table-navigation']">
        <DSButton
          label="Upload Document"
          data-cy="upload-button"
          :loading="isLoading"
          @click="openUploadModal()"
        />
        <div :class="$style['table-navigation__right']">
          <InputText
            ref="search"
            v-model="tableFilters.name.constraints[0].value"
            name="search"
            placeholder="Search"
            data-cy="search-input"
          />
          <DSButton
            outlined
            label="Clear Filters"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            data-cy="clear-filters-button"
            @click="clearFilters()"
          />
        </div>
      </div>

      <DataTable
        v-model:filters="tableFilters"
        v-model:first="currentPage"
        v-model:sort-field="tableSortedBy"
        v-model:editingRows="editingRows"
        filter-display="menu"
        :value="tableData"
        :class="$style.table"
        paginator
        :always-show-paginator="false"
        :rows="20"
        :sort-order="-1"
        responsive-layout="scroll"
        edit-mode="row"
        :loading="isLoading"
        show-gridlines
        @row-edit-save="onRowEditSave"
      >
        <template #empty>
          {{ isLoading ? 'Loading files...' : 'No files found.' }}
        </template>

        <template v-if="activeCategory === DOCUMENT_CATEGORIES.ALL">
          <Column field="name" header="Name" :show-filter-operator="false">
            <template #body="slotProps">
              <button
                text
                :class="$style['file-name']"
                :data-cy="`file ${slotProps.data.name}`"
                @click="downloadFile(slotProps.data)"
              >
                {{ slotProps.data.name }}
              </button>
            </template>

            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="type"
            header="Type"
            :show-filter-operator="false"
            :show-add-button="false"
            :show-filter-match-modes="false"
            :filter-menu-class="$style['filter']"
          >
            <template #body="slotProps">
              <span :class="$style.capitalize">
                {{ formatType(slotProps.data.type) }}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <ul>
                <li
                  v-for="(type, index) in getUniqueEntries(tableData, 'type')"
                  :key="`filter-type-${encodeURI(type)}-${index}`"
                >
                  <Checkbox
                    v-model="filterModel.value"
                    :input-id="`filter-type-${encodeURI(type)}-${index}`"
                    :value="type"
                  />
                  <label :for="`filter-type-${encodeURI(type)}-${index}`">
                    {{ formatType(type) }}
                  </label>
                </li>
              </ul>
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="createdAt"
            header="Date Uploaded"
            sortable
            data-type="date"
            :show-filter-operator="false"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createdAt, dateDisplayFormat) }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                name="calendar"
                :manual-input="false"
                placeholder="yyyy-mm-dd"
                show-icon
              />
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>
        </template>

        <template
          v-if="
            [
              DOCUMENT_CATEGORIES.BUSINESS,
              DOCUMENT_CATEGORIES.FINANCIAL,
            ].includes(activeCategory)
          "
        >
          <Column field="name" header="Name" :show-filter-operator="false">
            <template #body="slotProps">
              <button
                text
                :class="$style['file-name']"
                :data-cy="`file ${slotProps.data.name}`"
                @click="downloadFile(slotProps.data)"
              >
                {{ slotProps.data.name }}
              </button>
            </template>

            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="type"
            header="Type"
            :show-filter-operator="false"
            :show-add-button="false"
            :show-filter-match-modes="false"
            :filter-menu-class="$style['filter']"
          >
            <template #body="slotProps">
              <span :class="$style.capitalize">
                {{ formatType(slotProps.data.type) }}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <ul>
                <li
                  v-for="(type, index) in getUniqueEntries(tableData, 'type')"
                  :key="`filter-type-${encodeURI(type)}-${index}`"
                >
                  <Checkbox
                    v-model="filterModel.value"
                    :input-id="`filter-type-${encodeURI(type)}-${index}`"
                    :value="type"
                  />
                  <label :for="`filter-type-${encodeURI(type)}-${index}`">
                    {{ formatType(type) }}
                  </label>
                </li>
              </ul>
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="typesInCategories[activeCategory]"
                placeholder="Select a Type"
              />
            </template>
          </Column>

          <Column
            field="createdAt"
            header="Date Uploaded"
            sortable
            data-type="date"
            :show-filter-operator="false"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createdAt, dateDisplayFormat) }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                name="calendar"
                :manual-input="false"
                placeholder="yyyy-mm-dd"
                show-icon
              />
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            :row-editor="true"
            class="edit-column"
            :class="$style['edit-column']"
          ></Column>
        </template>

        <template v-if="activeCategory === DOCUMENT_CATEGORIES.BANK">
          <Column field="name" header="Name" :show-filter-operator="false">
            <template #body="slotProps">
              <button
                text
                :class="$style['file-name']"
                :data-cy="`file ${slotProps.data.name}`"
                @click="downloadFile(slotProps.data)"
              >
                {{ slotProps.data.name }}
              </button>
            </template>

            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="type"
            header="Type"
            :show-filter-operator="false"
            :show-add-button="false"
            :show-filter-match-modes="false"
            :filter-menu-class="$style['filter']"
          >
            <template #body="slotProps">
              <span :class="$style.capitalize">
                {{ formatType(slotProps.data.type) }}
              </span>
            </template>

            <template #filter="{ filterModel }">
              <ul>
                <li
                  v-for="(type, index) in getUniqueEntries(tableData, 'type')"
                  :key="`filter-type-${encodeURI(type)}-${index}`"
                >
                  <Checkbox
                    v-model="filterModel.value"
                    :input-id="`filter-type-${encodeURI(type)}-${index}`"
                    :value="type"
                  />
                  <label :for="`filter-type-${encodeURI(type)}-${index}`">
                    {{ formatType(type) }}
                  </label>
                </li>
              </ul>
            </template>

            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>

            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="typesInCategories[activeCategory]"
                placeholder="Select a Type"
                :disabled="
                  data?.type === BANK_ACCOUNT_DOC_TYPE?.BANK_STATEMENTS
                "
              />
            </template>
          </Column>

          <Column
            field="metaId"
            header="Bank Account"
            :show-filter-operator="false"
            :show-add-button="false"
            :show-filter-match-modes="false"
            :filter-menu-class="$style['filter']"
          >
            <template #body="slotProps">
              {{ getBankAccountBasedOnId(slotProps.data.metaId) || 'N/A' }}
            </template>
            <template #filter="{ filterModel }">
              <ul>
                <li
                  v-for="(type, index) in getUniqueEntries(tableData, 'metaId')"
                  :key="`filter-type-${encodeURI(type)}-${index}`"
                >
                  <Checkbox
                    v-model="filterModel.value"
                    :input-id="`filter-type-${encodeURI(type)}-${index}`"
                    :value="type"
                  />
                  <label :for="`filter-type-${encodeURI(type)}-${index}`">
                    {{
                      bankAccounts.find((account) => account.id === type)
                        ?.accountNumber || 'N/A'
                    }}
                  </label>
                </li>
              </ul>
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                option-label="accountNumber"
                option-value="id"
                :options="bankAccounts"
                placeholder="Select a Bank Account"
                :disabled="
                  data?.type === BANK_ACCOUNT_DOC_TYPE?.BANK_STATEMENTS
                "
              />
            </template>
          </Column>

          <Column
            field="createdAt"
            header="Date Uploaded"
            sortable
            data-type="date"
            :show-filter-operator="false"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createdAt, dateDisplayFormat) }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                name="calendar"
                :manual-input="false"
                placeholder="yyyy-mm-dd"
                show-icon
              />
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            :row-editor="true"
            class="edit-column"
            :class="$style['edit-column']"
          ></Column>
        </template>

        <template v-if="activeCategory === DOCUMENT_CATEGORIES.CONTRACTS">
          <Column field="name" header="Name" :show-filter-operator="false">
            <template #body="slotProps">
              <button
                text
                :class="$style['file-name']"
                :data-cy="`file ${slotProps.data.name}`"
                @click="downloadFile(slotProps.data)"
              >
                {{ slotProps.data.name }}
              </button>
            </template>

            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="type"
            header="Type"
            :show-filter-operator="false"
            :show-add-button="false"
            :show-filter-match-modes="false"
            :filter-menu-class="$style['filter']"
          >
            <template #body="slotProps">
              <span :class="$style.capitalize">
                {{ formatType(slotProps.data.type) }}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <ul>
                <li
                  v-for="(type, index) in getUniqueEntries(tableData, 'type')"
                  :key="`filter-type-${encodeURI(type)}-${index}`"
                >
                  <Checkbox
                    v-model="filterModel.value"
                    :input-id="`filter-type-${encodeURI(type)}-${index}`"
                    :value="type"
                  />
                  <label :for="`filter-type-${encodeURI(type)}-${index}`">
                    {{ formatType(type) }}
                  </label>
                </li>
              </ul>
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>

          <Column
            field="userSignedAt"
            header="Signed On"
            sortable
            data-type="date"
            :show-filter-operator="false"
          >
            <template #body="slotProps">
              <span v-if="slotProps.data.userSignedAt">
                {{ formatDate(slotProps.data.userSignedAt, dateDisplayFormat) }}
              </span>
              <span v-else>-</span>
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                name="calendar"
                :manual-input="false"
                placeholder="yyyy-mm-dd"
                show-icon
              />
            </template>
            <template #filterapply="{ filterCallback }">
              <DSButton
                label="Apply"
                class="p-button-sm"
                @click="onFilterChange(filterCallback)"
              />
            </template>
          </Column>
        </template>
      </DataTable>
    </div>
    <UploadModal
      :open-on-file-type="openOnFileType"
      :visibility="showUploadModal"
      @close-modal="closeUploadModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import DSButton from '@clearbanc/clear-components/button';
import InputText from '@clearbanc/clear-components/inputtext';
import Dropdown from '@clearbanc/clear-components/dropdown';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';
import {
  BANK_ACCOUNT_DOC_TYPE,
  BUSINESS_DOC_TYPE,
  CLIENT_DOWNLOAD_DOC_TYPE,
} from '@clearbanc/data-common-types';
import {
  DOCUMENT_CATEGORIES,
  FILE_TYPES_IN_CATEGORIES,
} from '@/data/file-upload-types';
import { filter } from 'lodash';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Checkbox from '@clearbanc/clear-components/checkbox';
import Calendar from '@clearbanc/clear-components/calendar';
import formatDate from 'date-fns/format';
import UploadModal from './upload-modal';
import RequestedDocumentsTable from './requestedDocumentsTable';

export default {
  components: {
    DSButton,
    InputText,
    Dropdown,
    DataTable,
    Column,
    Checkbox,
    Calendar,
    UploadModal,
    RequestedDocumentsTable,
  },
  data() {
    const initialTableFilters = {
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      category: {
        value: null,
        matchMode: FilterMatchMode.IN,
      },
      type: {
        value: null,
        matchMode: FilterMatchMode.IN,
      },
      createdAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      startDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      endDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      userSignedAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      metaId: {
        value: null,
        matchMode: FilterMatchMode.IN,
      },
    };

    return {
      isLoading: false,
      activeCategory: 'all',
      categories: [
        { title: DOCUMENT_CATEGORIES.ALL, icon: 'clear_all' },
        { title: DOCUMENT_CATEGORIES.BUSINESS, icon: 'work' },
        { title: DOCUMENT_CATEGORIES.FINANCIAL, icon: 'attach_money' },
        { title: DOCUMENT_CATEGORIES.BANK, icon: 'home' },
        { title: DOCUMENT_CATEGORIES.CONTRACTS, icon: 'receipt_long' },
      ],
      typesInCategories: FILE_TYPES_IN_CATEGORIES,
      DOCUMENT_CATEGORIES,
      BANK_ACCOUNT_DOC_TYPE,
      initialTableFilters,
      tableFilters: JSON.parse(JSON.stringify(initialTableFilters)),
      currentPage: 0,
      tableSortedBy: 'createdAt',
      filterByCategories: [],
      dateDisplayFormat: 'MMMM do, yyyy',
      editingRows: [],
      showUploadModal: false,
      openOnFileType: null,
    };
  },
  computed: {
    ...mapGetters([
      'uploads',
      'contracts',
      'currentUpload',
      'bankAccounts',
      'hasActiveDocumentRequests',
      'activeDocumentRequests',
      'isUploadInProgress',
    ]),
    ...mapRequestStatuses({
      getUserUploadDetailsRequest: 'GET_USER_UPLOAD_DETAILS',
      getUserContractDownloadRequest: 'GET_DOCUSIGN_CONTRACT_DOWNLOAD',
    }),
    userUploads() {
      const uploadsWithoutBlankEntities = this.uploads.filter(
        (upload) => upload.entity,
      );
      const updatedUploads = uploadsWithoutBlankEntities.map((upload) => {
        return {
          ...upload,
          type: upload.type || 'Other',
          category: this.getCategory(upload.type),
          createdAt: upload.createdAt
            ? this.convertToDateForFilters(upload.createdAt)
            : undefined,
          endDate: upload.endDate
            ? this.convertToDateForFilters(upload.endDate)
            : undefined,
          startDate: upload.startDate
            ? this.convertToDateForFilters(upload.startDate)
            : undefined,
          metaId: Number(upload.metaId) || 'N/A',
        };
      });

      return this.filterUploadsByCategory(updatedUploads, this.activeCategory);
    },
    userContracts() {
      const updatedContracts = this.contracts
        .filter((contract) => !!contract.fileName)
        .map((contract) => {
          return {
            ...contract,
            name: contract.fileName,
            type: 'contract',
            category: this.getCategory(contract.type),
            createdAt: contract.createdAt
              ? this.convertToDateForFilters(contract.createdAt)
              : undefined,
            userSignedAt: contract.userSignedAt
              ? this.convertToDateForFilters(contract.userSignedAt)
              : undefined,
          };
        });

      return updatedContracts;
    },
    allUploads() {
      return [...this.uploads, ...this.contracts];
    },
    tableData() {
      if (this.activeCategory === DOCUMENT_CATEGORIES.ALL) {
        return [...this.userUploads, ...this.userContracts];
      }

      const filteredUploads = this.filterUploadsByCategory(
        this.userUploads,
        this.activeCategory,
      );

      if (this.activeCategory === DOCUMENT_CATEGORIES.CONTRACTS) {
        return [...filteredUploads, ...this.userContracts];
      }

      return filteredUploads;
    },
    latestUploadFileType() {
      const latestFile = this.allUploads.reduce((latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      }, this.allUploads[0]);
      return latestFile.type;
    },
  },
  methods: {
    formatDate,
    getUniqueEntries(data, key) {
      return [...new Set(data.map((entry) => entry[key]))];
    },
    changeCategory(category) {
      this.activeCategory = category;
      this.updateURLParams();
      this.clearFilters();
    },
    updateURLParams() {
      const params = new URLSearchParams(window.location.search);
      params.set('category', this.activeCategory);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    },
    checkURLParams() {
      const params = new URLSearchParams(window.location.search);
      const category = params.get('category');
      const categoryExistsInOptions = this.categories.some(
        (c) => c.title === category,
      );
      if (category && categoryExistsInOptions) {
        this.activeCategory = category;
      }
    },
    getCategory(type) {
      let typeIsInCategory = 'N/A';

      Object.keys(this.typesInCategories).forEach((category) => {
        if (this.typesInCategories[category].includes(type)) {
          typeIsInCategory = category;
        }
      });

      return typeIsInCategory;
    },
    formatType(type) {
      return type && type.replace(/_/g, ' ').toLowerCase();
    },
    async downloadUpload(id) {
      await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
        id,
        download: true,
      });

      if (this.getUserUploadDetailsRequest.isSuccess) {
        const { downloadUrl } = this.currentUpload;
        window.open(downloadUrl, '_blank');
      }
    },
    async downloadContract(id) {
      const response = await this.$store.dispatchApiAction(
        'GET_DOCUSIGN_CONTRACT_DOWNLOAD',
        {
          contractId: id,
        },
      );

      if (
        this.getUserContractDownloadRequest.isSuccess &&
        response.downloadURL
      ) {
        window.open(response.downloadURL, '_blank');
      }
    },
    filterUploadsByCategory(uploads, category) {
      if (category === DOCUMENT_CATEGORIES.ALL) {
        return uploads;
      }

      return filter(uploads, (upload) => {
        const type = upload.type;
        return this.typesInCategories[category].includes(type);
      });
    },
    clearFilters() {
      this.currentPage = 0;
      this.tableSortedBy = 'createdAt';
      this.tableFilters = JSON.parse(JSON.stringify(this.initialTableFilters));
    },
    onFilterChange(applyFilters) {
      applyFilters();
    },
    convertToDateForFilters(date) {
      return new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
    },
    isContractType(data) {
      return this.typesInCategories[DOCUMENT_CATEGORIES.CONTRACTS].includes(
        data.type,
      );
    },
    async onRowEditSave(editData) {
      this.isLoading = true;

      const { data, newData } = editData;
      const fileChanges = { id: data.id };

      if (data.type === BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS) {
        return;
      }

      ['name', 'type', 'startDate', 'endDate', 'metaId'].forEach((field) => {
        if (data[field] !== newData[field]) {
          fileChanges[field] = newData[field];
        }
      });

      if (Object.keys(fileChanges).length > 1) {
        await this.$store.dispatchApiAction('UPDATE_USER_UPLOAD', fileChanges);
        this.getLatestData();
      }
    },
    async getLatestData() {
      this.isLoading = true;
      await this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
      await this.$store.dispatchApiAction('FETCH_USER_UPLOADS');
      this.isLoading = false;
    },
    downloadFile(data) {
      const { id, type, bucket } = data;
      if (CLIENT_DOWNLOAD_DOC_TYPE.CONTRACT === type && !bucket) {
        return this.downloadContract(id);
      }

      return this.downloadUpload(id);
    },
    closeUploadModal() {
      this.showUploadModal = false;
    },
    getBankAccountBasedOnId(accountId) {
      return this.bankAccounts.find((account) => account.id === accountId)
        ?.accountNumber;
    },
    openUploadModal(fileType) {
      this.openOnFileType = fileType;
      this.showUploadModal = true;
    },
  },
  async mounted() {
    await this.getLatestData();
    this.checkURLParams();
  },
  watch: {
    async allUploads(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        if (oldVal.length === newVal.length) {
          return;
        }

        const currentDocumentRequestCount = this.activeDocumentRequests.length;
        await this.$store.dispatchApiAction('GET_DOCUMENT_REQUESTS');
        const updatedDocumentRequestCount = this.activeDocumentRequests.length;

        if (
          this.latestUploadFileType === BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS
        ) {
          await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');
        }

        if (
          currentDocumentRequestCount !== updatedDocumentRequestCount &&
          !updatedDocumentRequestCount
        ) {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            detail: 'All document requests fulfilled',
            life: 3000,
          });
          return;
        }

        this.$toast.add({
          severity: 'success',
          detail: 'File uploaded successfully',
          icon: 'pi pi-send',
          life: 3000,
        });
      }

      this.isLoading = false;
    },
    isUploadInProgress(newVal) {
      this.isLoading = newVal;
    },
  },
};
</script>

<style>
.p-datatable .p-paginator {
  padding-bottom: 0;
}

.p-paginator-bottom {
  border: 0;
  padding-bottom: 0;
}

.p-column-filter-menu {
  margin-left: 0;
}

body .p-datatable .p-datatable-tbody > tr > td {
  border-left: inherit;
  border-color: #ceced0;
  padding: 1rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-right: 0;
  background: #f8f8fa;
  padding: 1rem;
  line-height: 1;
}

.p-datatable .p-datatable-tbody > tr > td.edit-column {
  text-align: center;
}

body .p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

body .p-component-overlay {
  background-color: rgb(255 255 255 / 50%);
}

.p-datatable .p-datatable-loading-icon {
  color: #7f30ff;
}
</style>

<style module>
.container {
  background: #fff;
  border: 1px solid #ceced0;
  border-radius: 4px;
  max-width: 1100px;
  margin: 0 auto;
}

.heading {
  margin: 0 auto;
  padding: 28px;
}

.navigation-wrapper {
  border-bottom: 2px solid #ceced0;
  padding: 0 28px 28px;
}

.navigation-mobile * {
  text-transform: capitalize;
}

.navigation {
  display: none;
  flex-direction: row;
  justify-content: center;
}

.navigation__item + .navigation__item {
  margin-left: 8px;
}

.button {
  display: flex;
  flex-direction: column;
  min-width: 94px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  line-height: 1;
  padding: 10px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bold;
  transition: color 0.2s;
}

.button--active {
  color: #7f30ff;
}

.button__icon {
  width: 45px;
  height: 45px;
  margin: 0 0 10px;
  border-radius: 50%;
  border: 2px solid #aaa;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s, background 0.2s;
}

.button:hover .button__icon {
  background: #f0e9fb;
}

.button--active .button__icon {
  border-color: #7f30ff;
}

.button--active:hover {
  cursor: default !important;
}

.button--active:hover .button__icon {
  background: none !important;
}

.button__icon__symbol {
  font-size: 28px;
}

.button--active .button__icon__symbol {
  color: #111;
}

.button::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #7f30ff;
  position: absolute;
  top: calc(100% + 26px);
  left: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.button--active::after {
  opacity: 1;
}

.content {
  padding: 28px;
}

.table-navigation {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
}

.table-navigation__right {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 10px;
  row-gap: 10px;
}

.table {
  font-size: 14px;
}

.capitalize {
  text-transform: capitalize;
}

.file-name {
  word-break: break-word;
  text-align: right;
  text-decoration: underline;
  color: #7f30ff;
  font-size: 14px;
}

.edit-column {
  white-space: nowrap;
}

@media (min-width: 850px) {
  .heading {
    padding: 48px;
    text-align: center;
  }

  .navigation-wrapper {
    padding: 0 38px 26px;
  }

  .navigation-mobile {
    display: none !important;
  }

  .navigation {
    display: flex;
  }

  .content {
    padding: 48px;
  }

  .table-navigation {
    flex-direction: row;
    align-items: flex-end;
  }

  .table-navigation__right {
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  .file-name {
    text-align: left;
  }
}

.filter {
  border: 1px solid #ceced0;

  [class^='p-column-filter-constraint'] {
    border-bottom: 1px solid #ceced0;
  }

  li + li {
    margin-top: 10px;
  }

  div + label {
    margin-left: 10px;
  }

  label {
    cursor: pointer;
    text-transform: capitalize;
  }
}
</style>
