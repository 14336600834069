<template>
  <div :class="$style['button-wrapper']">
    <design-checkbox
      v-if="showSkipCheckbox"
      v-model="skipStep"
      value="true"
      :class="$style['skip-for-now']"
      name="skip for now"
      @input="skipChanged"
    >
      Skip for now, I’ll do it later
    </design-checkbox>
    <NextButton
      :text="buttonText"
      :disabled="!canProceed"
      @click="$emit('click')"
    />
  </div>
</template>
<script>
import NextButton from '@/components/buttons/ButtonOnboarding';
import { DesignCheckbox } from '@/components/deprecated';

export default {
  emits: ['click'],
  components: {
    DesignCheckbox,
    NextButton,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Next',
    },
    allowNext: {
      type: Boolean,
      default: false,
    },
    showSkipCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      skipStep: false,
    };
  },
  computed: {
    canProceed() {
      return this.allowNext || this.skipStep;
    },
  },
  methods: {
    skipChanged(value) {
      this.$emit('skip', value);
    },
  },
};
</script>
<style lang="less" module>
.button-wrapper {
  display: flex;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  gap: @gutter-40;
  margin: 0 auto;
}
.skip-for-now {
  font-family: @gerstner-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: @cc-light-brown;

  span {
    display: inline-block;

    &[class^='text_'] {
      padding-left: @gutter-10;
    }

    &[class^='checkbox_'] {
      width: 24px;
      height: 24px;
      border: 1px solid @onboarding-light-gray;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
