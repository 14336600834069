import { PAYMENTS_ROUTE_NAMES } from './payments';

export const AUTH = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGNUP: PAYMENTS_ROUTE_NAMES.SIGN_UP,
  PASSWORD_RESET: 'password-reset',
  ERROR: 'auth-error',
};

export const PAY_VENDORS = {
  INDEX: PAYMENTS_ROUTE_NAMES.PAY_VENDORS,
};

export const PROFILE = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
};

export const OWNERS = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
  NEW: PAYMENTS_ROUTE_NAMES.PROFILE_NEW_OWNER,
  EDIT: PAYMENTS_ROUTE_NAMES.PROFILE_EDIT_OWNER,
};

export const USER = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE,
};

export const BUSINESS = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS,
  ADDRESS: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS,
};

export const ONBOARDING = {
  INDEX: PAYMENTS_ROUTE_NAMES.ROOT,
  USER: PAYMENTS_ROUTE_NAMES.USER_PROFILE,
  BUSINESS: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS,
  ADDRESS: PAYMENTS_ROUTE_NAMES.ADDRESS,
};

export const PAYMENTS = {
  INDEX: PAYMENTS_ROUTE_NAMES.REPAYMENTS,
  UPCOMING: PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS,
  BILL: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
  BILL_LEGACY: 'bill-legacy',
};

export const BANKING = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING,
  STATEMENTS: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS,
  STATEMENTS_LEGACY: 'statements-legacy',
};

export const OAUTH = {
  TRIGGER: 'oauth-trigger',
  SIGNUP: 'oauth-signup',
  CALLBACK: 'oauth-callback',
  PLAID_CALLBACK: 'plaid-oauth-callback',
};

export const MCA = {
  VENDOR_LIST: PAYMENTS_ROUTE_NAMES.VENDOR_LIST,
  CLEARCO_CARD: 'clearco-card',
  PAYMENTS: 'payments',
  SPEND_TRANSACTIONS: 'spend-transactions',
  FINANCING: 'financing',
  SPEND: 'spend',
  ADVANCES: 'advances',
};

export const FINANCING = {
  INDEX: 'financing',
  SPEND: 'spend',
  ADVANCES: 'advances',
};

export const ACCOUNT = {
  INDEX: 'account',
  SETTINGS: 'account-settings',
};

export const DATA_SECURITY = {
  INDEX: 'data-security',
  REMOVE: 'remove-data',
  EXPORT: 'export-data',
};

export const DOCUMENTS = {
  INDEX: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS,
};

export const FINANCIAL = {
  DEBT_OBLIGATIONS: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS,
  DOCUMENTS: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS,
};

export const DEBT_OBLIGATIONS = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS,
};

export const EMAIL_VERIFICATION = {
  INDEX: 'email-verification',
};

export const CONNECTED_ACCOUNTS = {
  INDEX: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
  MARKETING: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_MARKETING,
};

export const MAINTENANCE = {
  INDEX: 'maintenance',
};

export const EMBED = {
  INDEX: 'embed',
  CALCULATOR: 'calculator',
};

export const NOT_FOUND = {
  INDEX: 'not-found',
};

export const HOW_IT_WORKS = {
  INDEX: 'how-it-works',
};

export const USER_INVITE = {
  SUCCESS: 'user-invite-success',
  ERROR: 'user-invite-error',
  VERIFY: 'user-invite-verify',
};

export const HOME = {
  INDEX: 'home',
  INITIAL: PAYMENTS.BILL,
};
