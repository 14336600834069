// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rounded_OylVh{border-radius:4px;width:-moz-max-content;width:-webkit-max-content;width:intrinsic;font-size:16px;min-width:186px}.rounded_OylVh.purple_GbNbI{background-color:#7f30ff}.rounded_OylVh.purple_GbNbI:hover{background-color:#7f30ff!important}.rounded_OylVh.white_dUNMO{color:#020202;line-height:24px;font-weight:400;padding:0 20px}.rounded_OylVh.white_dUNMO,.rounded_OylVh.white_dUNMO:hover:not(.disabled_zkm1h){background:#fff}.rounded_OylVh.transparent_PUWyU{background:none;color:#020202;line-height:24px;padding:0 20px;border:1px solid #000}.rounded_OylVh.transparent_PUWyU:hover:not(.disabled_zkm1h){background:none}.rounded_OylVh.modals-theme__7IvO{letter-spacing:.1px;width:170px;padding:unset}.rounded_OylVh.focused_msdgk{display:none}@media only screen and (max-width:500px){.rounded_OylVh.focused_msdgk{display:unset;position:fixed;top:7px;z-index:7;right:7px;height:40px}}.rounded_OylVh.grey_mleEO{background:#e8e8ea;color:#000;font-weight:600;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:0;box-shadow:none}.rounded_OylVh.grey_mleEO:hover:not(.disabled_zkm1h){background:#ceced0}.rounded_OylVh:hover:not([class*=disabled]){background:#000}@media only screen and (max-width:500px){.rounded_OylVh{float:right;margin:unset;min-width:unset}}.rounded_OylVh .loader_E_TZo{margin-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rounded": "rounded_OylVh",
	"purple": "purple_GbNbI",
	"white": "white_dUNMO",
	"disabled": "disabled_zkm1h",
	"transparent": "transparent_PUWyU",
	"modals-theme": "modals-theme__7IvO",
	"focused": "focused_msdgk",
	"grey": "grey_mleEO",
	"loader": "loader_E_TZo"
};
export default ___CSS_LOADER_EXPORT___;
