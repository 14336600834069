<template>
  <div :class="$style['white-background']">
    <onboarding-ui-container>
      <div :class="$style.content">
        <svg
          :class="$style.icon"
          v-html="require('@/assets/icons/lightning-bolt-icon.svg')"
        ></svg>
        <div :class="$style.text">Connecting your accounts...</div>
      </div>
    </onboarding-ui-container>
    <oauth-connect
      :is-listener-instance="false"
      :callback-payload="this.$route.query"
    />
  </div>
</template>

<script>
import OnboardingUiContainer from '@/components/UiContainer';
import OauthConnect from '@/components/OauthConnect';
import { useMeta } from 'vue-meta';

export default {
  components: {
    OauthConnect,
    OnboardingUiContainer,
  },
  setup() {
    useMeta({
      title: 'Connecting your accounts... | Clearco',
    });
  },
};
</script>

<style lang="less" module>
.white-background {
  background: @color-white;
  min-height: 100vh;
  position: absolute;
  min-width: 100vw;
  .content {
    padding: 120px 0 230px;
    .icon {
      display: block;
      margin: 0 auto;
      height: 40px;
      width: 19px;
      @media only screen and (max-width: 500px) {
        margin-left: 20px;
      }
    }
    .text {
      height: 33px;
      font-weight: 500;
      font-size: 21px;
      line-height: 30px;
      color: @color-black;
      margin-top: 30px;
    }
  }
}
</style>
