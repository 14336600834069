import { authGuard } from '@auth0/auth0-vue';
import { HOW_IT_WORKS } from '@/data/routes';
import HowItWorks from '@/pages/how-it-works';

export default [
  {
    path: '/how-it-works',
    name: HOW_IT_WORKS.INDEX,
    component: HowItWorks,
    beforeEnter: authGuard,
    meta: {
      displayNav: true,
    },
  },
];
