<script setup>
import { computed, readonly } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { isPast } from 'date-fns';

import Message from '@clearbanc/clear-components/message';
import DSButton from '@clearbanc/clear-components/button';
import Tag from '@clearbanc/clear-components/tag';

import { useDownloadFile } from '@/composables/useDownloadFile';
import { useToast } from '@/composables/useToast';
import { isProfileComplete } from '@/store/modules/profile-module';
import { USER } from '@/data/routes';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { COUNTRY_CODE_WITH_NAMES } from '@/data/country-code-with-names';
import {
  OWNER_ROLES,
  ENTITY_TYPE,
  ID_TYPE,
  isAuthorizedOwner,
} from '@clearbanc/data-common-types';
import analytics from '@/utils/analytics';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();
const toast = useToast('bottom-left');
const props = defineProps({
  owner: {
    type: Object,
    required: true,
  },
});

defineEmits(['deleteOwner', 'setAsSignatory']);

// ---------
// Variables
// ---------

const requestStatuses = readonly({
  createUserInvite: computed(() =>
    store.getters.requestStatus('CREATE_USER_INVITE', props.owner.id),
  ),
});
const isSignatory = computed(() =>
  props.owner.jobRoles.includes(OWNER_ROLES.SIGNATORY),
);
const isCompanyOwner = computed(() =>
  props.owner.jobRoles.includes(OWNER_ROLES.OWNER),
);
const isDirectorBoardMember = computed(() =>
  props.owner.jobRoles.includes(OWNER_ROLES.DIRECTOR),
);
const isEligibleToBeSignatory = computed(() => {
  const { jobTitle, jobRoles } = props.owner;
  return isAuthorizedOwner(jobTitle, jobRoles);
});
const isUserProfileCompleted = computed(() => {
  return props.owner.isPrimary
    ? store.getters.isPrimaryUserInfoComplete
    : isProfileComplete(props.owner);
});
const canEditProfile = computed(() => {
  return !store.getters.ownerHasPendingBillContractOrActiveAdvance;
});
const pendingUserInvite = computed(() =>
  store.getters.userInvites.find(
    (invite) => invite.invitedUser.id === props.owner.id && !invite.fulfilledAt,
  ),
);
const canResendUserInvite = computed(() => {
  const { resendAvailableAt } = pendingUserInvite.value;
  return resendAvailableAt && isPast(new Date(resendAvailableAt));
});

// ------------
// Misc Methods
// ------------

function getHiddenSSN(ssn) {
  return ssn ? `********${ssn.slice(-1)}` : '';
}

function getCountryName(countryCode) {
  if (!countryCode) return '';
  return COUNTRY_CODE_WITH_NAMES[countryCode]();
}

function formatAddress(address) {
  if (!address) {
    return '';
  }

  return [
    address.line1 && `${address.line1}, `,
    address.city && `${address.city}<br />`,
    address.state && `${address.state}, `,
    address.postalCode && `${address.postalCode}, `,
    address.country && getCountryName(address.country),
  ]
    .filter((t) => !!t)
    .join('')
    .trim();
}

function ownerHasIdUploads(owner) {
  const fileFilters = {
    metaId: `${owner.id}`,
    entity: owner.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
    type: [
      ID_TYPE.PASSPORT,
      ID_TYPE.DRIVER_LICENSE,
      ID_TYPE.ID,
      ID_TYPE.PASSPORT_OR_LICENSE,
    ],
  };

  return !!store.getters.uploadsMatching(fileFilters).length;
}

// --------------
// Download Files
// --------------

const { downloadFile } = useDownloadFile();

async function downloadFiles(ownerId) {
  const ids = [];

  store.getters.uploads.forEach((file) => {
    if (file.metaId === ownerId.toString()) {
      ids.push(file.id);
    }
  });

  await Promise.all(ids.map((id) => downloadFile(id)));
}

// ----------
// Edit Owner
// ----------

async function editOwner() {
  analytics.track('if_user_profile_btn_edit', {
    business_id: store.getters.businessId,
  });

  if (props.owner.isPrimary) {
    if (!store.getters.isRoutesV2Enabled) {
      await store.dispatch('SET_SELECTED_OWNER', props.owner.id);
    }

    router.push({
      name: USER.INDEX,
      ...(!store.getters.isRoutesV2Enabled && {
        params: { id: props.owner.id },
      }),
    });
    return;
  }

  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_EDIT_OWNER,
    params: { id: props.owner.id },
  });
}

// -------------
// Resend Invite
// -------------

function resendInvite() {
  store.dispatchApiAction('CREATE_USER_INVITE', {
    id: props.owner.id,
    type: pendingUserInvite.value.type,
  });

  const userName = `${props.owner.firstName} ${props.owner.lastName}`;
  toast.success(`Email sent to ${userName}.`);

  analytics.track('if_identity_verification_email_sent', {
    primary_user_email: store.getters.user.email,
    business_id: store.getters.businessId,
    secondary_user_id: props.owner.id,
    secondary_user_email: props.owner.email,
    secondary_user_firstname: props.owner.firstName,
    secondary_user_lastname: props.owner.lastName,
    business_name: store.getters.business.name,
  });
}
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <div :class="$style.title">
        <div :class="$style.title__identification">
          <h3 :class="$style.title__identification__name">
            {{ props.owner.firstName }} {{ props.owner.lastName }}
          </h3>
          <Tag
            v-if="isSignatory"
            :class="$style.title__identification__tag"
            value="Signatory"
          ></Tag>
        </div>
        <div
          v-if="!isEligibleToBeSignatory && isUserProfileCompleted"
          :class="$style.title__notification"
        >
          This individual does not meet the signatory criteria.
          <a
            href="https://help.clear.co/s/article/Adding-Additional-Users?language=en_US"
            target="_blank"
            >Learn more</a
          >
        </div>
      </div>

      <div :class="$style.actions">
        <button
          v-if="canEditProfile"
          :class="$style.actions__item"
          @click="editOwner()"
        >
          <span class="pi pi-pencil" :class="$style.actions__item__icon"></span>
        </button>
        <button
          v-if="!props.owner.isPrimary && canEditProfile"
          :class="$style.actions__item"
          @click="$emit('deleteOwner', props.owner.id)"
        >
          <span class="pi pi-trash" :class="$style.actions__item__icon"></span>
        </button>
      </div>
    </div>

    <div
      v-if="
        isEligibleToBeSignatory &&
        isUserProfileCompleted &&
        !isSignatory &&
        canEditProfile
      "
      :class="$style.operations"
    >
      <DSButton
        class="p-button-outlined"
        label="Set as signatory"
        @click="$emit('setAsSignatory')"
      />
    </div>

    <div :class="$style.details">
      <div :class="$style.details__row">
        <div :class="$style.details__row__left">Email</div>
        <div :class="$style.details__row__right">
          {{ props.owner.email }}
        </div>
      </div>
      <div v-if="isCompanyOwner" :class="$style.details__row">
        <div :class="$style.details__row__left">Ownership</div>
        <div :class="$style.details__row__right">25%+</div>
      </div>
      <div v-if="props.owner.jobTitle" :class="$style.details__row">
        <div :class="$style.details__row__left">Job Title</div>
        <div :class="$style.details__row__right">
          {{ props.owner.jobTitle }}
        </div>
      </div>
      <div v-if="props.owner.personalTaxId" :class="$style.details__row">
        <div :class="$style.details__row__left">SSN Number</div>
        <div :class="$style.details__row__right">
          {{ getHiddenSSN(props.owner.personalTaxId) }}
        </div>
      </div>
      <div
        v-if="props.owner.isPrimary && ownerHasIdUploads(props.owner)"
        :class="$style.details__row"
      >
        <div :class="$style.details__row__left">ID</div>
        <div :class="$style.details__row__right">
          <button
            :class="$style['button-link']"
            @click="downloadFiles(props.owner.id)"
          >
            View ID
          </button>
        </div>
      </div>
      <div
        v-if="Object.keys(props.owner.address).length"
        :class="$style.details__row"
      >
        <div :class="$style.details__row__left">Home Address</div>
        <div
          :class="$style.details__row__right"
          v-html="formatAddress(props.owner.address)"
        ></div>
      </div>
      <div v-if="props.owner.phoneNumber" :class="$style.details__row">
        <div :class="$style.details__row__left">Phone Number</div>
        <div :class="$style.details__row__right">
          {{ props.owner.phoneNumber }}
        </div>
      </div>
      <div v-if="props.owner.citizenship" :class="$style.details__row">
        <div :class="$style.details__row__left">Citizenship</div>
        <div :class="$style.details__row__right">
          {{ getCountryName(props.owner.citizenship) }}
        </div>
      </div>
      <div v-if="isDirectorBoardMember" :class="$style.details__row">
        <div :class="$style.details__row__left">Owner Type</div>
        <div :class="$style.details__row__right">Director/Board member</div>
      </div>
    </div>

    <div :class="$style.alerts">
      <Message
        v-if="pendingUserInvite"
        :class="$style.alerts__item"
        severity="warn"
        :closable="false"
      >
        <h3>ID verification pending</h3>
        <p>
          ID verification email sent. We're waiting for it to be completed. The
          profile will be updated once their identity is verified.
        </p>
        <p>
          <DSButton
            v-if="canResendUserInvite"
            class="p-button-link"
            :class="$style['button-link']"
            label="Resend email"
            :disabled="requestStatuses.createUserInvite.isPending"
            :loading="requestStatuses.createUserInvite.isPending"
            @click="resendInvite()"
          />
        </p>
      </Message>
      <!-- <Message :class="$style.alerts__item" severity="error" :closable="false">
        <h3>ID verification failed</h3>
        <p>
          We can’t verify this person’s identity based on the information they
          provide.
        </p>
      </Message> -->

      <Message
        v-if="!pendingUserInvite && !isUserProfileCompleted && canEditProfile"
        :class="$style.alerts__item"
        severity="warn"
        :closable="false"
      >
        <h3>Incomplete Profile</h3>
        <p>Please complete this profile, so we can verify your identity.</p>
        <p>
          <button :class="$style['button-link']" @click="editOwner()">
            Edit profile
          </button>
        </p>
      </Message>
    </div>
  </div>
</template>

<style scoped module lang="less">
.wrapper {
  padding: 20px;
  border: 1px solid var(--neutral-grey-100, #e8e8ea);
  background: var(--neutral-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
}

.wrapper:first-child {
  margin-top: 40px;
}

.wrapper + .wrapper {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .wrapper {
    padding: 40px;
  }
}

/* ------ */
/* Header */
/* ------ */

.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.title {
  margin-top: 20px;
}

.title__identification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title__identification__name {
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.title__identification__tag {
  font-size: 14px;
  font-family: @gerstner-font;
  background: var(--status-purple-light, #f3dfff);
  color: var(--status-purple-dark, #820ec6);
  margin-top: 10px;
}

.title__notification {
  margin-top: 4px;
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.title__notification a {
  display: inline-block;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .header {
    flex-direction: row;
  }

  .title {
    margin: 0 20px 0 0;
  }

  .title__identification {
    flex-direction: row;
  }

  .title__identification__tag {
    margin: 0 0 0 8px;
  }
}

/* ------- */
/* Actions */
/* ------- */

.actions {
  display: flex;
  order: -1;
}

.actions__item {
  all: unset;
  padding: 4px;
  cursor: pointer;
}

.actions__item + .actions__item {
  margin-left: 20px;
}

.actions__item__icon {
  display: block;
  font-size: 20px;
}

.actions__item:hover .actions__item__icon {
  color: var(--color-cc-purple-500, #6f2dff);
}

@media (min-width: 768px) {
  .actions {
    margin-left: auto;
    order: 0;
  }

  .actions__item + .actions__item {
    margin-left: 16px;
  }

  .actions__item__icon {
    font-size: auto;
  }
}

/* ---------- */
/* Operations */
/* ---------- */

.operations {
  margin-top: 40px;
}

/* ------- */
/* Details */
/* ------- */

.details {
  margin-top: 40px;
}

.details__row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details__row + .details__row {
  margin-top: 16px;
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  padding-top: 16px;
}

.details__row__right {
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  max-width: 100%;
}

.details__row__right a {
  font-weight: 400;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .details__row {
    flex-direction: row;
    justify-content: space-between;
  }

  .details__row + .details__row {
    border-top: 0;
    padding-top: 0;
  }

  .details__row__right {
    margin-left: 40px;
    max-width: 60%;
    text-align: right;
  }
}

/* ------ */
/* Alerts */
/* ------ */

.alerts [class^='p-message'] {
  margin: 0;
}

.alerts__item:first-child {
  margin-top: 40px;
}

.alerts__item + .alerts__item {
  margin-top: 16px;
}

/* ------------ */
/* Misc Helpers */
/* ------------ */

.button-link {
  all: unset;
  color: var(--info-info-400, #215cb6);
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>
