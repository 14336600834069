import { MAINTENANCE } from '@/data/routes';
import Maintenance from '@/pages/maintenance';

export default [
  {
    path: '/maintenance',
    name: MAINTENANCE.INDEX,
    component: Maintenance,
  },
];
