import * as Sentry from '@sentry/vue';
import { useStore } from 'vuex';
import { faro } from '@grafana/faro-web-sdk';
import { PAGE_IDS, SUB_TYPES } from '@/data/lexisnexis-types';

class Profiling {
  /**
   * Creates an instance of the Profiling class.
   *
   * @param {string} pageName  The name of the page to be profiled.
   * @param {object} options  (Optional) Additional configuration options.
   * @property {boolean} options.isExternalForm  (Optional) Indicates if the profiling is for an external form. Defaults to false.
   * @property {number} options.userId  (Optional) User ID for external form profiling.
   * @property {string} options.type  (Optional) Type of event. Only provide if you want to skip the dynamic generation based on user logins count. An example would be if you always want the page to be considered an account creation event no matter how many logins.
   */
  constructor(pageName, options = {}) {
    this.pageName = pageName;
    this.options = options;
    this.store = useStore();
    this.sessionId = null;
    this.type = null;
  }

  /**
   * Generates a unique and cryptographically secure session ID.
   *
   * This function combines a random number, a timestamp, and hashing
   * to create a session ID. The random number and timestamp provide
   * uniqueness, while hashing ensures the ID is not easily guessable
   * and protects against potential manipulation.
   *
   * @returns {Promise<string>} A hexadecimal representation of the hashed session ID.
   */
  async generateSessionId() {
    const randomNumber = Math.floor(Math.random() * 1000000);
    const timestamp = Math.floor(Date.now() / 1000);
    const compositeKey = `${randomNumber}_${timestamp}`;

    const encoder = new TextEncoder();
    const data = encoder.encode(compositeKey);
    const hash = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  }

  /**
   * Starts the profiling process.
   *
   * @throws {Error} If the page ID is not found.
   */
  async start() {
    if (!this.store.getters.isLexisNexisTrackingEnabled) {
      return;
    }

    if (!PAGE_IDS[this.pageName]) {
      throw new Error(`Page ID not found for ${this.pageName}`);
    }

    if (!SUB_TYPES[this.pageName]) {
      throw new Error(`Subtype not found for ${this.pageName}`);
    }

    try {
      this.sessionId = await this.generateSessionId();

      processInfo.initiate(
        process.env.LEXIS_NEXIS_PROFILING_DOMAIN,
        process.env.LEXIS_NEXIS_ORG_ID,
        this.sessionId,
        PAGE_IDS[this.pageName],
      );

      faro.api.pushEvent('Profiling Initiated', {
        sessionId: this.sessionId,
      });
    } catch (error) {
      Sentry.captureException(new Error('Error generating Session ID'));
    }
  }

  /**
   * Finishes the profiling process.
   *
   * @throws {Error} If required options are missing.
   */
  async finish() {
    if (this.sessionId) {
      const {
        isExternalForm = false,
        inviteId = null,
        type = null,
      } = this.options;

      if (!type) {
        throw new Error(`Missing required type`);
      }

      try {
        if (isExternalForm && inviteId) {
          await this.store.dispatchApiAction('FINISH_PROFILING_EXTERNAL', {
            inviteId,
            sessionId: this.sessionId,
            type,
            subtype: SUB_TYPES[this.pageName],
          });
        } else {
          await this.store.dispatchApiAction('FINISH_PROFILING', {
            sessionId: this.sessionId,
            type,
            subtype: SUB_TYPES[this.pageName],
          });
        }

        faro.api.pushEvent('Profiling Finished', {
          sessionId: this.sessionId,
        });
      } catch (error) {
        faro.api.pushEvent('Profiling Failed', {
          sessionId: this.sessionId,
        });

        Sentry.captureException(
          new Error(`Error finishing profiling for session ${this.sessionId}`),
        );
      }
    }
  }
}

/**
 * Creates a new Profiling instance and starts the profiling process.
 *
 * @param {string} pageName - The name of the page to be profiled.
 * @param {object} options - Additional configuration options.
 * @returns {Profiling} The Profiling instance.
 */
export function useProfiling(pageName, options) {
  const profiling = new Profiling(pageName, options);
  profiling.start();

  return profiling;
}
