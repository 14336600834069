<template>
  <div :class="$style['container']">
    <VeeForm
      ref="form"
      class="c-form"
      :class="$style.form"
      :validation-schema="schema"
      :initial-values="initialValues"
      @submit="submitForm"
    >
      <div class="c-group">
        <div
          v-if="isHeronPdfAutomationEnabled"
          class="c-form__row grid grid-cols-12"
        >
          <div class="col-span-6 sm:col-span-12">
            <InputTextGroup
              v-model="bankDetails.bankName"
              name="bankName"
              label="Bank Name"
            />
          </div>
          <div class="col-span-6 sm:col-span-12">
            <InputTextGroup
              v-model="bankDetails.label"
              name="label"
              label="Account Label"
              placeholder="Eg. Business Savings Account"
            />
          </div>
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 sm:col-span-12">
            <InputTextGroup
              v-model="bankDetails.nameOnAccount"
              name="nameOnAccount"
              :label="$t('paymentsPage.accountHolderName')"
            />
          </div>
          <div class="col-span-6 sm:col-span-12">
            <DropdownGroup
              name="accountHolderType"
              label="Account Holder Type"
              option-label="label"
              :options="[
                {
                  label: 'Business Account',
                  value: 'company',
                },
                {
                  label: 'Personal Account',
                  value: 'individual',
                },
              ]"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                (e) => {
                  updateBankDetails(e.value.value, 'accountHolderType');
                }
              "
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-4 sm:col-span-12">
            <DropdownGroup
              name="countryCode"
              :label="$t('common.bankInfo.bankCountry')"
              :filter="true"
              option-label="nameString"
              :options="countries"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                (e) => {
                  updateBankDetails(e.value.value, 'countryCode');
                }
              "
            />
          </div>
          <div class="col-span-4 sm:col-span-12">
            <DropdownGroup
              name="currencyCode"
              label="Currency"
              option-label="nameString"
              :options="currencyCodes"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                (e) => {
                  updateBankDetails(e.value.value, 'currencyCode');
                }
              "
            />
          </div>
          <div class="col-span-4 sm:col-span-12">
            <DropdownGroup
              name="subtype"
              label="Type"
              :options="['Checking', 'Savings']"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                (e) => {
                  updateBankDetails(e.value.toLowerCase(), 'subtype');
                }
              "
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div
            v-if="bankDetails.currencyCode !== 'EUR'"
            class="col-span-6 sm:col-span-12"
          >
            <InputTextGroup
              v-model="bankDetails.accountNumber"
              name="accountNumber"
              label="Account Number"
            />
          </div>
          <!-- IBAN for EUR-->
          <div
            v-show="
              bankDetails.currencyCode && bankDetails.currencyCode === 'EUR'
            "
            class="col-span-6 sm:col-span-12"
          >
            <InputTextGroup
              v-model="bankDetails.iban"
              name="iban"
              label="International Bank Account Number (IBAN)"
            />
          </div>
          <!-- SORT CODE for GBP -->
          <div
            v-show="
              bankDetails.currencyCode && bankDetails.currencyCode === 'GBP'
            "
            class="col-span-6 sm:col-span-12"
          >
            <InputTextGroup
              v-model="bankDetails.sortCode"
              name="sortCode"
              label="Sort Code"
            />
          </div>
          <!-- BSB CODE -->
          <div
            v-show="
              bankDetails.currencyCode && bankDetails.currencyCode === 'AUD'
            "
            class="col-span-6 sm:col-span-12"
          >
            <InputTextGroup
              v-model="bankDetails.bsbCode"
              name="bsbCode"
              label="BSB Code"
            />
          </div>
          <!-- ROUTING NUMBER -->
          <div
            v-show="
              bankDetails.currencyCode &&
              !['EUR', 'GBP'].includes(bankDetails.currencyCode || '')
            "
            class="col-span-6 sm:col-span-12"
          >
            <InputTextGroup
              v-model="bankDetails.routingNumber"
              name="routingNumber"
              label="Routing Number"
            />
          </div>
        </div>
      </div>
      <div :class="$style['void-check-container']">
        <p>
          Please Upload a Void Check to confirm your account and routing numbers
        </p>
        <UploadSingleFile
          ref="uploadSingleFile"
          :upload-filters="uploadFilters"
          :file="fileUploaded"
          :auto-upload="false"
          @onUpload="updateFileUploaded"
          @onRemove="removeFileUploaded"
        />
      </div>
      <Message
        v-if="showError && submitBankDetailsRequest.isError"
        severity="error"
        :closable="false"
        class="m-t-30"
      >
        <p>{{ submittingBankError }}</p>
      </Message>
      <div class="c-form">
        <div class="c-form__controls justify-center">
          <DSButton
            class="p-button-outlined"
            :label="$t('common.buttons.back')"
            @click="$emit('back')"
          />
          <DSButton
            data-cy="submit-bank-details"
            :loading="isSubmitting"
            :disabled="!fileUploaded"
            :label="$t('common.buttons.next')"
            type="submit"
          />
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { Form as VeeForm } from 'vee-validate';
import { string, object } from 'yup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import {
  ENTITY_TYPE,
  BANK_ACCOUNT_DOC_TYPE,
} from '@clearbanc/data-common-types';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { countryOptionsWithPriority } from '@/data/country-code-with-names';
import { SUPPORTED_CURRENCY_SYMBOLS } from '@/data/currency-code-with-names';
import { UploadSingleFile } from '@/components';
import {
  getAccountNumberRequirements,
  getBranchCodeRequirements,
  IBAN_REQUIREMENTS_BY_COUNTRY,
} from '@/utils/currency';
import { LOCAL_STORAGE_KEYS } from '@/data/local-storage';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { useProfiling } from '@/composables/useProfiling';

export default {
  components: {
    Message,
    VeeForm,
    InputTextGroup,
    DropdownGroup,
    DSButton,
    UploadSingleFile,
  },
  props: {
    plaidErrorCode: { type: String, default: '' },
    prefillProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const store = useStore();
    const profiling = useProfiling(
      PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING_BYPASS,
      {
        type: store.getters.eventTypeBasedOnLoginsCount,
      },
    );

    return { profiling };
  },
  data() {
    return {
      bankDetails: {
        currencyCode: '',
        countryCode: '',
        nameOnAccount: '',
        subtype: '',
        accountHolderType: '',
        bankName: '',
        label: '',
      },
      fileUploaded: null,
      showError: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters(['isHeronPdfAutomationEnabled', 'user', 'business']),
    ...mapRequestStatuses({
      submitBankDetailsRequest: 'ADD_USER_BANK_ACCOUNT',
    }),
    SUPPORTED_CURRENCY_SYMBOLS: () => SUPPORTED_CURRENCY_SYMBOLS,
    schema() {
      const fieldSchema = {
        label: string()
          .required(this.$t('common.required'))
          .matches(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters, numbers, and spaces are allowed',
          )
          .max(30),
        nameOnAccount: string().required(this.$t('common.required')),
        routingNumber: string()
          .matches(this.accountNumberRequirements.regex, {
            message: this.accountNumberRequirements.errorMessage,
            excludeEmptyString: true,
          })
          .when('currencyCode', {
            is: 'GBP' || 'EUR',
            then: string().required(),
          }),
        sortCode: string()
          .matches(this.accountNumberRequirements.regex, {
            message: this.accountNumberRequirements.errorMessage,
            excludeEmptyString: true,
          })
          .length(6)
          .when('currencyCode', {
            is: 'GBP',
            then: string().required(),
          }),
        iban: string()
          .matches(this.countryIbanRequirements.regex, {
            message: this.countryIbanRequirements.errorMessage,
            excludeEmptyString: true,
          })
          .when('currencyCode', {
            is: 'EUR',
            then: string().required(),
          }),
        bsbCode: string()
          .matches(this.accountNumberRequirements.regex, {
            message: this.accountNumberRequirements.errorMessage,
            excludeEmptyString: true,
          })
          .when('currencyCode', {
            is: 'AUD',
            then: string().required(),
          }),
        subtype: string().required(this.$t('common.required')),
        accountNumber: string()
          .matches(this.accountNumberRequirements.regex, {
            message: this.accountNumberRequirements.message,
            excludeEmptyString: true,
          })
          .when('currencyCode', {
            is: 'EUR',
            then: string().required(this.$t('common.required')),
          }),
        currencyCode: object().nullable().required(this.$t('common.required')),
        countryCode: object().nullable().required(this.$t('common.required')),
        accountHolderType: object()
          .nullable()
          .required(this.$t('common.required')),
      };
      return object(fieldSchema);
    },
    countries() {
      const countryList = countryOptionsWithPriority();

      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      const optionsWithStringName = countryList.map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });

      return optionsWithStringName;
    },
    currencyCodes() {
      return Object.keys(this.SUPPORTED_CURRENCY_SYMBOLS).map((key) => {
        const { code, name } = this.SUPPORTED_CURRENCY_SYMBOLS[key];
        return {
          key: code,
          name,
          nameString: code,
          value: code,
        };
      });
    },
    countryIbanRequirements() {
      return (
        IBAN_REQUIREMENTS_BY_COUNTRY[this.bankDetails.countryCode] ||
        IBAN_REQUIREMENTS_BY_COUNTRY.OTHER
      );
    },
    accountNumberRequirements() {
      return getAccountNumberRequirements(this.bankDetails);
    },
    branchCodeRequirements() {
      return getBranchCodeRequirements(this.bankDetails);
    },
    uploadFilters() {
      return {
        entity: ENTITY_TYPE.BANK_ACCOUNT,
        type: BANK_ACCOUNT_DOC_TYPE.VOID_CHEQUE,
      };
    },
    submittingBankError() {
      if (this.submitBankDetailsRequest?.errorMessage?.includes('errorCode')) {
        return `You're not authorized to add a bank account for this routing number. Routing number must match ${this.business.plaidBypass.bank_id}. Please contact support for assistance.`;
      }
      return (
        this.submitBankDetailsRequest.errorMessage ??
        this.submitBankDetailsRequest.message
      );
    },
    initialValues() {
      return {
        ...this.prefillProps,
        ...(this.prefillProps?.currencyCode && {
          currencyCode: this.getCurrencyOption(this.prefillProps.currencyCode),
        }),
        ...(this.prefillProps?.countryCode && {
          countryCode: this.getCountryOption(this.prefillProps.countryCode),
        }),
      };
    },
  },
  mounted() {
    this.bankDetails = {
      currencyCode: '',
      countryCode: '',
      nameOnAccount: '',
      subtype: '',
      accountHolderType: '',
      bankName: '',
      label: '',
      ...this.prefillProps,
    };
  },
  methods: {
    updateBankDetails(value, key) {
      this.bankDetails.iban = '';
      this.bankDetails.sortCode = '';
      this.bankDetails.bsbCode = '';

      if (this.bankDetails.currencyCode === 'EUR') {
        this.bankDetails.accountNumber = '';
      }
      this.bankDetails[key] = value;
    },
    async submitForm(values, { resetForm }) {
      this.isSubmitting = true;
      this.showErrors = false;
      this.bankDetails.errorCode = this.plaidErrorCode;
      // remove whitespaces from the form values
      for (const key in this.bankDetails) {
        if (typeof this.bankDetails[key] === 'string') {
          this.bankDetails[key] = this.bankDetails[key].trim();
        }
      }

      const newBank = await this.$store.dispatchApiAction(
        'ADD_USER_BANK_ACCOUNT',
        this.bankDetails,
      );
      if (this.submitBankDetailsRequest.isError) {
        this.showError = true;
      } else {
        if (this.fileUploaded) {
          await this.$refs.uploadSingleFile.processFiles({
            metaId: newBank.id,
          });
        }
        localStorage.removeItem(LOCAL_STORAGE_KEYS.PLAID_ERROR_CODE);
        if (this.isHeronPdfAutomationEnabled) {
          // Need to get the business (via the user) to get the plaid by pass status
          // TODO (sc-178331) Remove this condition as well as the else block when the write user in BAUS is done
          if (!this.user.business && this.user.businessId) {
            await this.$store.dispatchApiAction('GET_BUSINESS');
          } else {
            await this.$store.dispatchApiAction('GET_USER', {
              userId: this.user.id,
            });
          }
        }
        this.bankDetails = {
          currencyCode: '',
          countryCode: '',
          nameOnAccount: '',
          subtype: '',
          accountHolderType: '',
        };
        this.fileUploaded = null;
        this.profiling.finish();
        resetForm();
        this.$emit('success');
      }
      this.isSubmitting = false;
    },
    updateFileUploaded(file) {
      this.fileUploaded = file;
    },
    removeFileUploaded() {
      this.fileUploaded = null;
    },
    getCurrencyOption(currencyCode) {
      return this.currencyCodes.find(
        (currency) => currency.key === currencyCode,
      );
    },
    getCountryOption(countryCode) {
      return this.countries.find((country) => country.value === countryCode);
    },
  },
  watch: {
    prefillProps: {
      handler() {
        this.bankDetails = {
          currencyCode: '',
          countryCode: '',
          nameOnAccount: '',
          subtype: '',
          accountHolderType: '',
          bankName: '',
          label: '',
          ...this.prefillProps,
        };
      },
      deep: true,
    },
  },
};
</script>

<style>
.c-form {
  text-align: left;
}
</style>

<style lang="less" module>
.container {
  margin: 20px auto 0;
}

.form {
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
}
.void-check-container {
  p {
    margin: 2rem 0 1rem 0;
    text-align: center;
    font-weight: 500;
    font-size: 0.875rem;
  }
}
</style>
