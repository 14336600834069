import { authGuard } from '@auth0/auth0-vue';
import { MCA } from '@/data/routes';
import financingRoutes from '@/routes/financing.routes';

import VendorListPage from '@/pages/mca/vendor-list';
import ClearbancCardSubpage from '@/pages/mca/subpage-clearbanc-card';
import PaymentsPage from '@/pages/mca/payments-page';
import SpendTransactionsPage from '@/pages/mca/spend-transactions-page';

export default [
  {
    path: '/mca',
    beforeEnter: authGuard,
    meta: {
      displayNav: true,
      isLegacy: true,
    },
    children: [
      {
        path: '', // MCA Route
        alias: '/vendor-list',
        name: MCA.VENDOR_LIST,
        component: VendorListPage,
      },
      {
        path: 'clearco-card', // MCA Route - Should Remove?
        name: MCA.CLEARCO_CARD,
        component: ClearbancCardSubpage,
      },
      {
        path: 'payments', // MCA Route
        name: MCA.PAYMENTS,
        component: PaymentsPage,
        meta: {
          requiresAuth: true,
          segment: [
            'core',
            'self_serve',
            'extend_runway',
            'mrr_factoring',
            'bnpl',
          ],
        },
      },
      {
        path: 'spend-transactions', // MCA Route
        name: MCA.SPEND_TRANSACTIONS,
        component: SpendTransactionsPage,
        meta: {
          requiresAuth: true,
          segment: [
            'core',
            'self_serve',
            'extend_runway',
            'mrr_factoring',
            'indiegogo',
            'bnpl',
          ],
        },
      },
      ...financingRoutes,
    ],
  },
];
