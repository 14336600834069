<script setup>
import { computed, readonly, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from '@/composables/useToast';
import Modal from '@clearbanc/clear-components/dialog';
import UserItemButton from '@/components/UserItemButton';
import ModalControls from '@/components/modals/shared/ModalControls';
import { getParsedMessage } from '@/data/error-messages';
import { OWNER_ROLES } from '@clearbanc/data-common-types';
import UiMsg from '@/components/UiMsg';

// -----
// Setup
// -----

const store = useStore();
const toast = useToast('bottom-left');

const emit = defineEmits(['close']);

const props = defineProps({
  owner: {
    type: Object,
  },
  visible: {
    type: Boolean,
  },
});

// ---------
// Constants
// ---------
const requestStatuses = readonly({
  updateNonPrimaryUser: computed(() =>
    store.getters.requestStatus('UPDATE_NON_PRIMARY_USER'),
  ),
});

const errorMessage = computed(() => {
  if (requestStatuses.updateNonPrimaryUser.isError) {
    return getParsedMessage(requestStatuses.updateNonPrimaryUser.error);
  }
  return undefined;
});

const currentSignatory = ref(null);

// -------
// Actions
// -------

watch(
  () => props.visible,
  (newValue) => {
    if (newValue) {
      currentSignatory.value = store.getters.authorizedSignatory;
    }
  },
);

function closeModal() {
  emit('close');
}

async function confirmNewSignatoryChange() {
  const jobRoles = [...props.owner.jobRoles, OWNER_ROLES.SIGNATORY];
  await store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', {
    jobRoles,
    id: props.owner.id,
    overrideSignatories: true,
  });
  if (requestStatuses.updateNonPrimaryUser.isSuccess) {
    closeModal();
    toast.success('Signatory updated.');
  }
}
</script>

<template>
  <Modal
    :visible="props.visible"
    header="Make User a Signatory?"
    :modal="true"
    :draggable="false"
    append-to="self"
    @update:visible="(visible) => !visible && closeModal()"
  >
    <div :class="$style['modal-content']">
      <p :class="$style.description">
        Contracts will be sent directly to the signatory's email on file.
      </p>
      <ul :class="$style.users">
        <li
          v-if="currentSignatory"
          :class="[$style.users__item, $style['users__item--disabled']]"
        >
          <UserItemButton disabled tag="Current" :owner="currentSignatory" />
        </li>

        <li v-if="props.owner" :class="$style.users__item">
          <UserItemButton tag="New" :owner="props.owner" />
        </li>
      </ul>
    </div>

    <template #footer>
      <UiMsg v-if="errorMessage" type="error">
        {{ errorMessage }}
      </UiMsg>

      <ModalControls
        :buttons="[
          {
            text: 'Cancel',
            action: closeModal,
            classes: ['p-button-outlined'],
            attributes: {},
          },
          {
            text: 'Confirm',
            action: confirmNewSignatoryChange,
            classes: [],
            loading: requestStatuses.updateNonPrimaryUser.isPending,
            attributes: {
              loading: requestStatuses.updateNonPrimaryUser.isPending,
            },
          },
        ]"
      />
    </template>
  </Modal>
</template>

<style lang="less" module>
.description {
  max-width: 500px;
  margin: 0;
}

.users {
  margin-top: 30px;
}

.users__item + .users__item {
  margin-top: 24px;
}

.users__item--disabled {
  opacity: 0.5;
}
</style>
