<template>
  <div>
    <router-view />
    <IdentityVerificationModal v-if="!ownerVerificationStepComplete" />
  </div>
</template>
<script>
import ownersComposables from '@/composables/owners';
import IdentityVerificationModal from '@/components/modals/ModalIdentityVerification';
import { useContextRoot } from '@/utils/context-root';

import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default {
  components: { IdentityVerificationModal },
  setup(props, context) {
    const { ownerVerificationStepComplete, redirectToCorrectOwnersPage } =
      ownersComposables(useContextRoot());

    redirectToCorrectOwnersPage();
    return { ownerVerificationStepComplete, redirectToCorrectOwnersPage };
  },
  watch: {
    $route() {
      if (this.$route.name === PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST) {
        this.redirectToCorrectOwnersPage();
      }
    },
  },
};
</script>
