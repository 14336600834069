<template>
  <div
    class="progress__wrapper"
    :style="{ 'margin-top': topup ? '10px' : '0' }"
  >
    <div ref="progressBar" class="progress">
      <div
        v-for="item in items"
        :key="item.type"
        class="progress__fill"
        :class="{
          [item.type]: true,
          hidden: !item.width,
        }"
        :style="{ width: `${item.width}%` }"
        @mouseover="openTooltip(item.type)"
        @mouseout="openTooltip('')"
        @focus="openTooltip(item.type)"
        @blur="openTooltip('')"
      ></div>
    </div>
    <div
      v-if="topup"
      class="progress__topup"
      :style="{ left: `${(topup / total) * 100}%` }"
    ></div>
    <progress-tooltip
      v-for="item in items"
      :key="item.type"
      :is-visible="tooltipVisible === item.type"
      :offset="item.offset"
      :title="item.hoverText"
      :amount="item.amount"
      :details="item.details"
      :currency-code="item.currencyCode"
      theme="dark"
      @openCashBackDetails="openCashBackDetails"
    />
  </div>
</template>

<script>
const components = {
  'progress-tooltip': require('@/components/progress/ProgressTooltip').default,
};

export default {
  components,
  props: {
    topup: Number,
    total: Number,
    cashback: Number,
    currencyCode: String,
    pending: Number,
    failed: Number,
    isCard: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tooltipVisible: '',
      labelVisible: false,
      items: [],
    };
  },
  computed: {
    minItemWidthPercent() {
      const { clientWidth } = this.$refs.progressBar;

      // see .progress__fill css class
      // min width is 20px
      return (20 / clientWidth) * 100;
    },
  },
  mounted() {
    this.items = [
      {
        type: 'success',
        width: this.calcWidth(this.success),
        offset: this.calcOffset('success'),
        hoverText: this.isCard
          ? this.$t('components.progressBar.totalSpent')
          : this.$t('components.progressBar.amountRepaid'),
        amount: this.success,
        currencyCode: this.currencyCode,
      },
      {
        type: 'pending',
        width: this.calcWidth(this.pending),
        offset: this.calcOffset('pending'),
        hoverText: this.$t('components.progressBar.pendingTransaction'),
        amount: this.pending,
        currencyCode: this.currencyCode,
      },
      {
        type: 'failed',
        width: this.calcWidth(this.failed),
        offset: this.calcOffset('failed'),
        hoverText: this.$t('components.progressBar.failedTransaction'),
        amount: this.failed,
        currencyCode: this.currencyCode,
      },
      {
        type: 'cashback',
        width: this.calcWidth(this.cashback),
        offset: 100 - this.calcWidth(this.cashback) / 2,
        hoverText: this.$t('components.progressBar.discountCredit'),
        amount: this.cashback,
        currencyCode: this.currencyCode,
        details: true,
      },
    ];
  },
  methods: {
    calcWidth(x) {
      return x ? Math.max((x / this.total) * 100, this.minItemWidthPercent) : 0;
    },
    openTooltip(id) {
      return (this.tooltipVisible = id);
    },
    openCashBackDetails() {
      this.$emit('openCashBackDetails');
    },
    calcOffset(type) {
      /* eslint-disable no-param-reassign */
      const hash = {
        success: ['success'],
        pending: ['success', 'pending'],
        failed: ['success', 'pending', 'failed'],
      };
      return hash[type].reduce(
        (result, val, idx) =>
          idx === hash[type].length - 1
            ? (result += this.calcWidth(this[val]) / 2)
            : (result += this.calcWidth(this[val])),
        0,
      );
    },
  },
};
</script>

<style lang="less">
@progress-height: 36px;
@progress-radius: @progress-height / 2;

.progress__wrapper {
  position: relative;

  .progress {
    position: relative;
    width: 100%;
    height: @progress-height;
    background: @color-grey-200;
    border-radius: @progress-radius;
    overflow: hidden;

    .progress__label {
      position: absolute;
      left: 0;
      right: 4px;
      text-align: right;
      line-height: 40px;
      font-weight: bold;
      z-index: 5;
    }

    .progress__fill {
      position: relative;
      display: inline-block;
      height: 100%;
      min-width: 20px;
      cursor: default;
      transition: all 300ms ease;

      &.hidden {
        display: none;
      }

      &.success {
        background: @progress-bar-success;
      }

      &.pending {
        background: @progress-bar-pending;
      }

      &.failed {
        background: @progress-bar-failed;
      }

      &.cashback {
        position: absolute;
        top: 0;
        right: 0;
        background: @blue-green;
        z-index: 1;
      }
    }
  }

  .progress__topup {
    position: absolute;
    top: -15px;
    height: calc(100% + 15px);
    width: 2px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;

    &::before {
      content: 'previous balance';
      position: absolute;
      top: -8px;
      left: 8px;
      width: 100px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>
